.opc-wrapper {
    .form-discount {
        max-width: 500px;

        @include min-screen($screen__m) {
            display: flex;
        }

        .label {
            @include min-screen($screen__m) {
                margin-top: 23px;
            }
        }
    }
}