.custom-touts--imageless {
    @include min-screen($screen__xs) {
        display: flex;
        flex-direction: column;
    }
}

.custom-tout--imageless {
    @include min-screen($screen__xs) {
        flex-basis: 50%;
        
        &:not(:last-child)::after {
            position: absolute;
            top: 20px;
            right: 0;
            content:"";
            border-right: 1px solid $c-border;
            height: 85px;
        }
    }
}