.crazy-image-grid {
    display: flex;
    flex-direction: column;
    margin-top: $indent__s !important; // override PageBuilder
    margin-bottom: 40px !important; // override PageBuilder

    &::before,
    &::after {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }

    @include min-screen($screen__m) {
        margin-top: $indent__l;
        margin-bottom: 55px;
    }

    [data-content-type="banner"] {
        width: 100%;

        > a {
            @include image-overlay();
            position: relative;
            display: block;
            padding-top: 69.791666666667%;

            &:hover::before {
                opacity: .5;
            }
        }

        .pagebuilder-banner-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }

    img {
        width: 100%;
    }

    .pagebuilder-poster-content {
        position: absolute;
        bottom: 50%;
        left: 0;
        right: 0;
        transform: translateY(50%);
        z-index: 2; // appear above image overlay
        padding: 15px;
        text-align: center;

        p {
            font-weight: $font-weight__regular;
            font-size: 14px;
            letter-spacing: 2px;
            color: $color-white;
            text-decoration: none;
            text-shadow: $bluefoot-banner__text-shadow;
            padding: 0;
            padding-bottom: 3px;
            border-bottom: 1px solid $color-white;
            text-transform: uppercase;
            display: inline-block;
        }
    }

    @include min-screen($screen__m) {
        display: block !important; // override PageBuilder

        [data-content-type="banner"] {
            padding: 0;
            float: left;

            &:nth-of-type(1),
            &:nth-of-type(2) {
                width: 25%;

                a {
                    padding-top: 69.791666666667%;
                }
            }

            &:nth-of-type(3) {
                width: 50%;
                clear: both;

                a {
                    padding-top: 69.921875%;
                }
            }

            &:nth-of-type(4) {
                width: 50%;
                display: inline-block;
                float: none;

                a {
                    padding-top: 79.05604719764%;
                }
            }
        }
    }

    .custom-buttons {
        margin-top: $indent__base !important; // Override PageBuilder styles
        margin-bottom: 0;

        @include min-screen($screen__m) {
            margin-top: 40px !important; // Override PageBuilder styles
        }

        a {
            margin-bottom: 0;
        }
    }

    [data-content-type="button-item"] a {
        @include lib-button();
        text-decoration: none;
        width: 100%;
    }
}
