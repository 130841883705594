$collection-swatch-breakpoint: 900px !default;
$collection-swatch-img-width: 150px !default; //in hovered state
$collection-swatch-img-height: 100px !default;
$collection-swatch-img-width-mobile: 200px !default;
$form-header-background-color: $c-grey !default;
$tooltip-swatch-width: 286px !default;
$tooltip-swatch-height: 200px !default;
$collection-swatch-active-border: 4px solid $c-houndstooth;
$collection-filter-hover-border: 1px solid $c-widget-border-grey;
$widget-padding-s: $indent__base $indent__m;
$widget-padding-m: $indent__base $indent__xl $indent__base;
$widget-padding-l: $indent__base 77px $indent__base;
$slick-pagination-width: 200px; // 200px is the width of the widest set of slick pagination 

.collection-filter-container,
.collection-filter-container__content {
    margin: 0 0 $indent__base;
    width: 100%;

    @include min-screen($screen__m) {
        margin: $indent__l 0;
    }

    &__title {
        button {
            @include lib-button-reset();
            border-radius: 6px;
            padding: 6px 6px 6px 12px;
            background-color: $c-gray-50;
            border: none;
            box-sizing: border-box;
    
            @include hover {
                background-color: $c-gray-50;
            }

            span {
                font-weight: $font-weight__semibold;
                font-size: $font-size__base--desktop;
                text-transform: none;
                color: $c-dark-border;
                line-height: 2;
                letter-spacing: 0.32px;

                &:last-of-type {
                    @include icon-svg(
                        $icon: grid-view,
                        $width: 32px,
                        $height: 32px,
                        $hideLabel: false,
                        $pseudo: after
                    );

                    &::after {
                        margin-left: 6px;
                    }
                }
            }
        }
    }

    &__content {
        @include max-screen($screen__m) {
            padding: 0 14px;
        }
    }

    .collection-filter-form {
        padding: $widget-padding-s;
        background-color: $form-header-background-color;

        @include min-screen($screen__m) {
            padding: $widget-padding-s;
        }

        label {
            @include lib-visually-hidden();
        }

        .control {
            position: relative;
            max-width: 100%;
        }

        &__input {
            background: $form-header-background-color;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $c-gray-200;
            padding: $indent__s 0 0;
            margin-left: $indent__xl;
            width: calc(100% - #{$indent__xl});
            max-width: 100%;
        }

        .collection-search {
            @include icon-svg(
                $icon: search,
                $fill: $header-icons-color,
                $fill-hover: $header-icons-color-hover,
                $width: $indent__m
            );

            @include lib-button-reset();
            @include vertically-center($position: absolute);
            display: inline-block;
            padding: $indent__xs 0;
            left: 0;
            z-index: 1;

            @include max-screen($mobile-nav__breakpoint) {
                width: auto;
            }

            &:focus::before {
                color: $color-gray20;
            }

            &[disabled] {
                opacity: 1;
            }
        }

        &__results {
            display: none;
            z-index: 1;
            background: white;
            border: 1px solid $c-vignette;
            border-top: 0;
            box-sizing: border-box;
            padding: 18px 15px;
            position: relative;
            margin-left: $indent__xl;

            &.no-results {
                min-height: 182px;
            }

            .close-btn {
                position: absolute;
                top: 14px;
                right: 15px;

                @include icon-svg($icon: close,
                    $fill: $c-scorpion,
                    $fill-hover: $color-black,
                    $width: 13px,
                    $height: auto,
                    $pseudo: before);
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-top: 0;
                    cursor: pointer;

                    &[data-active=true] {
                        img {
                            border: 1px solid transparent;
                            outline: $collection-swatch-active-border;
                        }
                    }

                    @include hover {
                        img {
                            outline: $collection-filter-hover-border;
                        }

                        span {
                            color: $color-black;
                        }
                    }

                    img {
                        width: $indent__xl;
                        border: 2px solid transparent;
                        outline: 1px solid transparent;
                    }

                    span {
                        vertical-align: middle;
                        color: $c-heavy-metal;
                    }

                    .qs-option-name {
                        margin-left: 12px;
                        font-family: $font-family__secondary;
                        font-style: italic;
                        text-transform: capitalize;
                        font-weight: $font-weight__regular;
                        font-size: $font-size__base--desktop;
                        color: $c-heavy-metal;
                    }
                }
            }

            .empty {
                font-family: $font-family__secondary;
                font-style: italic;
                font-weight: $font-weight__regular;
                font-size: $font-size__base--desktop;
                color: $c-heavy-metal;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                width: calc(100% - #{$indent__base * 2});
            }
        }
    }

    &__swatches {
        position: relative;
        padding: 0 $indent__m;

        .swatch-option-link-layered {
            padding: 6px;
            margin: 8px;
            box-sizing: border-box;
            border: none;
            vertical-align: top;
            border-radius: 8px;
            background: $c-white;

            @include hover {
                border: none;
            }

            &__inner {
                height: 100%;
            }

            .image-wrapper {
                max-height: $collection-swatch-img-height;
                margin: 0 auto;
                box-sizing: border-box;
                height: $collection-swatch-img-height;
                border-radius: 8px;
                border: 2px solid transparent;

                &.selected {
                    border: $collection-swatch-active-border;
                }

                @include hover {
                    border: 2px solid $c-houndstooth;
                }
            }
        }

        .slick-slide > div > .swatch-option-link-layered {
            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        .slick-slide > div {
            &:first-of-type > .swatch-option-link-layered {
                margin-top: 0;
            }
            &:last-of-type > .swatch-option-link-layered {
                margin-bottom: 0;
            }
        }

        .swatch-option.image {
            width: 100%;
            height: 100%;
            max-width: none;
            max-height: none;
            border: 0;
            outline: 3px solid transparent;
            float: none;
            margin: 0 auto;
            border-radius: 8px;

            @include hover {
                outline: 0;
                border: 0;
                box-shadow: none;
            }
        }

        .option-collection-info {
            display: block;
            font-family: $font-family__secondary;
            font-weight: $font-weight__regular;
            font-size: $font-size__base;
            color: $c-gray-400;
            text-transform: capitalize;
            margin: 6px 2px 2px;
            text-align: left;

            @include min-screen($screen__xl) {
                max-width: $collection-swatch-img-width;
            }
        }

        #swatch-tooltip {
            display: none;
            position: absolute;
            width: $tooltip-swatch-width;
            z-index: 2;
            box-sizing: border-box;
            background-color: $color-white;
            padding: 6px;
            box-shadow: 0 0 3px 1px rgba($color-black, 0.3);

            &.closed {
                display: none;
            }

            &.opened {
                display: block;
            }

            .swatch-title {
                font-size: $font-size__base--m;
                font-style: italic;
                text-transform: capitalize;
                margin: $indent__xs 0;
                font-family: $font-family__secondary;
                line-height: 1.2;
                padding: 0 $indent__s;

                .count {
                    margin-left: $indent__xs;
                }
            }

            .text {
                font-size: $font-size__base;
                margin: $indent__xs 0;
                font-size: $font-size__base;
                line-height: 1.2;
                padding: 0 $indent__s;
            }

            img {
                display: block;
                max-width: 275px;
                height: auto;
            }
        }
    }

    &__applied {
        padding: 0 $indent__base;

        @include min-screen($screen__m) {
            padding: 0 $indent__l;
            align-self: center;
        }

        @include max-screen($screen__xs) {
            padding: 0 16px;
        }

        .filters-active-collection {
            padding-bottom: 10px;
        }

        .item {
            display: inline-block;
            border-radius: 19px;
            border: 1px solid $c-gray-150;
            background-color: $c-white;
            font-family: $font-family__secondary;
            font-size: $font-size__base--desktop;
            color: $c-heavy-metal;
            margin: 0 $indent__xs $indent__xs 0;
            text-transform: capitalize;
            position: relative;
            padding: 4px 39px 3px 18px;

            .action.remove {
                float: left;
                position: absolute;
                top: 3px;
                right: 16px;

                @include icon-svg($icon: close,
                    $fill: $c-scorpion,
                    $fill-hover: $color-black,
                    $width: 12px,
                    $height: auto,
                    $pseudo: after);
            }

            .filter-label {
                display: none;
            }
        }
    }

    .filter-label {
        font-weight: $font-weight__regular;
    }

    .slick-slider .slick-arrow {
        @include max-screen($mobile-nav__breakpoint) {
            display: none;
        }

        &.slick-prev {
            left: calc(50% - #{$slick-pagination-width});
        }

        &.slick-next {
            right: calc(50% - #{$slick-pagination-width});
        }

        &.slick-prev,
        &.slick-next {
            top: 100%;

            &::before {
                top: 8px;
                height: $indent__s;
                width: $indent__s;
            }
        }
    }

    .collection-filter-container__swatches .slick-dots {
        li {
            transform: scale(1) !important; //prevent scaling down of dot text
            padding: $indent__s;
            margin: 0;
            border-radius: initial;
            border-bottom: 2px solid $c-gray-100;

            &.slick-active {
                border-bottom: 2px solid $c-bct-black;
            }

            button {
                text-indent: initial;
                background: none;
                border: none;
                font-size: $font-size__base--desktop;
                color: $c-bct-black;
                line-height: 1;
            }
        }
    }
}

.collection-filter-container {
    @include min-screen($screen__m) {
        margin: 0;
    }
}

.collection-filter-modal.modal-popup.modal-slide {
    .modal-footer {
        display: none;
    }

    .modal-inner-wrap {
        padding: 0;
        border-radius: $indent__s;
        background: $form-header-background-color;
        width: 100%;

        .modal-header {
            padding-top: 0;
        }
    }
    
    .collection-filter-container__content {
        margin-top: 0;
    }

    .collection-filter-modal__title {
        font-size: $font-size__base--l;
        font-weight: $font-weight__bold;
        text-transform: uppercase;
        padding: $indent__base $indent__m $indent__s;
        background-color: $color-white;
    }

    .action-close {
        padding: 0;
        width: 15px;
        height: 15px;
        top: $indent__base;
        right: $indent__base;

        @include icon-svg(
            $icon: close,
            $fill: $text__color,
            $fill-hover: $color-black,
            $hideLabel: true,
            $width: 15px,
            $height: auto,
            $pseudo: before);
    }

    .collection-filter-container__swatches {
        .slick-slide {
            > div {
                display: flex;

            }
        }
    }

    @include min-screen($mobile-nav__breakpoint) {
        .modal-inner-wrap {
            max-width: 95%;
            width: 760px;
            border-radius: $indent__s;
        }
    }

    @include max-screen($mobile-nav__breakpoint) {
        .modal-inner-wrap {
            background: $form-header-background-color;
        }

        .collection-filter-container__content {
            padding: 0;
        }

        .collection-filter-container__swatches {
            padding: 0 $indent__base;
        }
    }

    @include max-screen($screen__xs) {
        .collection-filter-container__swatches {
            padding: 0 16px;
        }
    }
}

.mini-collection-filter-form {
    label {
        font-size: $font-size__base--xs;
        color: $c-feng-shui;
        margin-bottom: 6px;
    }

    .control {
        position: relative;
    }

    .collection-search {
        display: none;
    }

    &__results {
        display: none;
        z-index: 3;
        background: white;
        border: 1px solid $c-vignette;
        border-top: 0;
        box-sizing: border-box;
        padding: 18px 15px;
        position: relative;

        &.no-results {
            min-height: 182px;
        }

        .close-btn {
            position: absolute;
            top: 14px;
            right: 15px;

            @include icon-svg(
                $icon: close,
                $fill: $c-scorpion,
                $fill-hover: $color-black,
                $width: 13px,
                $height: auto,
                $pseudo: before
            );
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin-top: 0;
                cursor: pointer;

                &[data-active=true] {
                    img {
                        border: 1px solid transparent;
                        outline: $collection-swatch-active-border;
                    }
                }

                @include hover {
                    img {
                        outline: $collection-filter-hover-border;
                    }

                    span {
                        color: $color-black;
                    }
                }

                img {
                    width: $indent__base;
                    border: 2px solid transparent;
                    outline: 1px solid transparent;
                }

                span {
                    vertical-align: middle;
                    color: $c-heavy-metal;
                }

                .qs-option-name {
                    margin-left: 12px;
                    font-family: $font-family__secondary;
                    font-style: italic;
                    text-transform: capitalize;
                    font-weight: $font-weight__regular;
                    font-size: $font-size__base--desktop;
                    color: $c-heavy-metal;
                }
            }
        }

        .empty {
            font-family: $font-family__secondary;
            font-style: italic;
            font-weight: $font-weight__regular;
            font-size: $font-size__base--desktop;
            color: $c-heavy-metal;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: calc(100% - #{$indent__base * 2});
        }
    }

    + .items {
        margin-top: 8px;
    }
}

.code-collection .filter-options-content {
    max-height: 425px;
}

.block.filter .filter-options-item.code-collection:nth-of-type(1) {
    display: block;
}

.catalog-category-view .swatch-option-tooltip {
    @include lib-visibility-hidden();
}

// prevent layout shift on page load
.collection-filter-container__content {
    display: none;
}

.collection-filter-modal .collection-filter-container__content {
    display: block;
}
