$nav-section-title__height: 60px;

//
//  Common
//  _____________________________________________
.nav-sections {
    background: $navigation__background;
}

.nav-sections-item-content {

    .header.links {
        display: none;
    }

    nav {
        &.navigation {
            display: none;
        }

        &:not(.navigation) {
            display: block;
        }
    }
}

.navigation {
    @include layout-container;

    .level0 > .level-top {
        text-transform: uppercase;
    }

    [data-content-type="row"] {
        display: none;
    }

    .ui-menu-item .level0.submenu {
        a {
            font-weight: $font-weight__bold;
            text-transform: uppercase;
        }

        .level1.submenu a {
            font-weight: $font-weight__regular;
            text-transform: none;
        }
    }
}

.nav-sections-item-switch {
    font-size: $font-size__base;
    color: $text__color;
    font-weight: $font-weight__bold;
    text-transform: uppercase;
    text-decoration: none;
    text-align: left;
    display: block;
    padding-left: $indent__m;
    padding-right: $indent__m;
    height: $flyout-tab-height;
    width: fit-content;

    &:active,
    &:visited {
        color: $text__color;
    }
}

.nav-toggle {
    @include lib-icon-text-hide();
    @include icon-svg(
        $icon       : menu,
        $fill       : $header-icons-color,
        $fill-hover : $header-icons-color-hover,
        $width      : 20px
    );
    display: flex;
    align-items: center;
    min-height: $header__icon-height;
    float: left;
    font-size: 0;
    cursor: pointer;
    z-index: 14;

    &::before {
        height: 16px;
        z-index: 15;
    }
}

.nav-sections-item-title[aria-controls="close.menu"] {
    text-align: right;
    position: relative;

    a {
        @include button--close();
        font-size: 0; // hiding actual text
        padding: 0;

        &::after {
            right: $indent__m;
        }
    }
}

.nav-sections-item-title.active[aria-controls="store.links"] ~ .nav-sections-item-title[aria-controls="close.menu"] {
    background-color: $color-white; // Tie close button background to state of account tab
}


//
//  Mobile
//  _____________________________________________

@include max-screen($mobile-nav__breakpoint) {
    .nav-sections-item-content {
        > .header.links {
            display: block;

            > li {
                display: none;

                &.authorization-link,
                &.authorization-link ~ li {
                    display: block;
                }
            }
            
            &:has(.customer-welcome) {
                .customer-welcome,
                .customer-welcome .header.links {
                    display: block;
                }

                > .authorization-link,
                > .authorization-link ~ li {
                    display: none
                }
            }
        }

        nav {
            &.navigation {
                display: block;
            }

            &:not(.navigation) {
                display: none;
            }
        }

        .shop-additional-links {
            background-color: $c-gray-250;
            margin-top: $indent__m;
            padding: $indent__m 16px;

            .header.links,
            .header.links:has(.customer-welcome) {
                display: block;

                li {
                    display: block;

                    &.authorization-link,
                    &.customer-welcome {
                        display: none;
                    }

                    &:not(:last-child) {
                        margin-bottom: $indent__s;
                    }
                    
                    > a {
                        padding: $indent__s 12px;
                        text-align: center;
                        border: 1px solid $c-dark-border;
                        font-weight: $font-weight__semibold;
                    }
                }
            }

            .header.links:has(.customer-welcome) li {
                &.authorization-link,
                &.customer-welcome {
                    ~ li {
                        display: none;
                    }
                }
            }
        }
    }

    .navigation {
        padding: 0;

        .parent {
            > a {
                @include icon-svg(
                    $icon     : expand,
                    $fill     : $navigation-level0-item__color,
                    $width    : 17px,
                    $height   : auto,
                    $hideLabel: false,
                    $pseudo   : after
                );
                position: relative;

                &::after {
                    @include vertically-center($position: absolute);
                    right: 15px;
                }

                &.ui-state-active {
                    @include icon-svg(
                        $icon     : collapse,
                        $fill     : $navigation-level0-item__color,
                        $hideLabel: false,
                        $pseudo   : after
                    );
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 12px;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content : $icon-down,
                    $_icon-font-size    : 42px,
                    $_icon-font-position: after,
                    $_icon-font-display : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content : $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();

            .customer-menu & {
                border-bottom: 0;
            }

            li {
                margin: 0;
                font-size: 12px;
                font-weight: $font-weight__bold;
            }

            .greet.welcome,
            li > a {
                padding: 9px $indent__xl 9px $indent__base;
                font-weight: $font-weight__light;
            }

            .greet.welcome {
                font-weight: $font-weight__bold;
            }

            a {
                text-decoration: $navigation-level0-item__text-decoration;
                display: block;

                @include hover {
                    text-decoration: $navigation-level0-item__text-decoration;
                    display: block;
                }
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            box-shadow: $flyout-box-shadow;
            background: $flyout-active-color;
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: $modal-overlay__background-color;
                content: '' !important; // override SVG
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 6; // above logo, z of 5
            }
        }

        .nav-sections-items {
            background: $flyout-background-color;
        }

        .page-header {
            z-index: initial;
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;

        .nourison-divisions.ui-menu-item {
            display: none;
        }
    }

    .nav-sections-item-title {
        float: left;
        height: $flyout-tab-height;
        margin-bottom: 0;
        margin-top: 0;
        background: darken($navigation__background, 5%);
        border: solid darken($navigation__background, 10%);
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        line-height: $nav-section-title__height;
        letter-spacing: 1.5px;
        text-align: center;

        @include min-screen($screen__m) {
            line-height: $nav-section-title__height;
        }

        &:last-child {
           display: none;
        }

        &.active {
            background: $flyout-active-color;

            &[aria-controls="store.links"] {
                width: calc(100% - 100px);
            }
        }

        &[aria-controls="close.menu"] {
            position: absolute;
            right: 0;
            width: 20%;
            background-color: transparent;
        }

        .nav-sections-item-switch {
            @include hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: $flyout-tab-height;
        width: 100%;
        padding: $indent__s 0 15px 0;
        background-color: $flyout-active-color;

        &.active {
            display: block;
        }

        .customer-menu .header.links {
            padding-bottom: 0;
            border-bottom: 0;
        }

    }

    @include lib-main-navigation(
      $_nav-level0-item-color: $text__color,
      $_nav-background-color: $flyout-active-color,
      $_nav-level0-font-size: 12px,
      $_nav-level0-item-border: 0,
      $_nav-level0-text-transform: uppercase,
      $_nav-level0-item-padding: 12px $indent__xl 12px $indent__base,
      $_nav__indent-side: 17px,
      $_nav-level0-item-line-height: 1.3,
      $_nav-level0-item__active__border-width: 0 0 0 0,
      $_nav-level0-item__active__border-color: $primary__color,

      $_submenu-font-size: $font-size__base,
      $_submenu-line-height: 1.8,
      $_submenu-item__active__border-width: 0 0 0 0,
      $_submenu-item__active__border: 0,
      $_submenu-item__active__border-color: $primary__color
    );

    .navigation {
        a,
        a span {
            @include hover {
                color: $link__hover__color;
            }
        }

        ul {
            border-bottom: 1px solid $c-border;
        }

        .level0  {
            .level-top {
                letter-spacing: 1.5px;
            }

            .level1 {
                font-weight: $font-weight__light;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($mobile-nav__breakpoint) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        flex-shrink: 0;
        flex-basis: auto;
        margin-top: $indent__s;

        .cms-index-index & {
            margin-bottom: 0;
        }
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop(
        $_nav-background-color: transparent,
        $_nav-font-size: 12px,
        $_nav-font-weight: $font-weight__bold,

        $_nav-level0-item-margin: 0 24px 0 0,
        $_nav-level0-item-padding: 0,
        $_nav-level0-item-line-height: 1.33,
        $_nav-level0-item-color: $text__color,
        $_nav-level0-item-color-hover: $link__hover__color,
        $_nav-level0-item-color-active: $link__hover__color,
        $_nav-level0-item__active__border-color: $link__hover__color,
        $_nav-level0-item__active__border-width: 0,

        $_submenu-item-padding: $indent__xs $indent__base,
        $_submenu-background-color: $page__background-color,
        $_submenu-border-color: $c-border,
        $_submenu-box-shadow: none,
        $_submenu-item-color: $link__color,
        $_submenu-item__hover__background-color: transparent,
        $_submenu-font-size: $font-size__base,
        $_submenu-font-weight: $font-weight__light
    );

    .navigation {
        .level0 > .level-top {
            font-size: 12px;
            letter-spacing: 1.5px;
            display: inline-block;
            padding-bottom: 19px;
        }

        .level0 .submenu {
            padding-top: $indent__base;
            padding-bottom: $indent__base;
        }
    }

    .submenu li {
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;        
    }
}
