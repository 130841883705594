.cms-about-us {
    p {
        color: $c-gray-350;
    }

    * {
        box-sizing: border-box;
    }
}

.about-us-history {
    background-color: $c-brown;
    color: $c-white;
    @extend .pt-big, .pb-big;

    [data-content-type='row'][data-appearance='contained'] {
        margin: 0 !important;
    }

    img {
        border-radius: $custom-border-radius-sm;
    }

    .history-container {
        border: none;

        @media (max-width: $screen__l2) {
            @include add-spacing;
        }
    }

    .left-column {
        opacity: 1;
        transition: opacity 0.5s ease;
        padding: 20px 20px 20px 0;
    }

    .right-column {
        //flex: 1;
        padding: 0;
        position: relative;
        background-color: $c-brown-500;
        border-radius: $custom-border-radius-sm;

        ul {
            list-style-type: none;
            box-sizing: border-box;
            padding: 10px;
            margin: 0;
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            overflow-x: auto;
            white-space: nowrap;
            background-color: $c-brown-500;
            border-radius: $button__border-radius;
            scroll-behavior: smooth;
            scrollbar-width: inherit;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: thin;
            scrollbar-color: lighten($color: $c-brown-500, $amount: 8%) $c-dark-border;
            border: 1px solid $c-dark-border;
            border-radius: 6px;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background-color: rgba(0, 0, 0, .5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
            }

            li {
                padding: 10px 20px;
                cursor: pointer;
                margin: 0;
                position: relative;
                z-index: 2;
                color: $c-white;
                background-color: $c-brown-500;

                &.selected-year,
                &:hover {
                    color: $c-brown;
                    background-color: $c-white;
                    border-radius: 6px;
                }
            }
        }
    }

    .content-heading {
        @include darkroom-headings-h2;
        margin-bottom: 0;
        font-family: $font-family__base;
        padding: 0 0 20px 0;

        @media (min-width: $screen__l) {
            padding: 0 20px;
        }
    }

    .content-paragraph {
        padding: 0 0 20px 0;

        @media (min-width: $screen__l) {
            padding: 0 20px;
        }
    }

    @media (min-width: $screen__l) {
        .left-column {
            display: grid !important;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(5, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            width: 92%;

            @media (min-width: $screen__l2) {
                padding: 20px 0;
            }

            @media (max-width: $screen__l2) {
                width: 96%;
            }
        }

        .content-img {
            grid-area: 1 / 1 / 6 / 2;
        }

        .content-heading {
            grid-area: 2 / 2 / 3 / 3;

            @media (max-width: $screen__l2) {
                grid-area: 1 / 2 / 2 / 2;
            }
        }

        .content-paragraph {
            grid-area: 3 / 2 / 5 / 3;

            @media (max-width: $screen__l2) {
                grid-area: 2 / 2 / 3 / 3;
            }
        }
    }

    [data-appearance="full-width"] {
        margin: 0 !important;
        padding: 0 !important;

        @media (min-width: $screen__xs) {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}


.page-hero-text {
    max-width: 580px;
    margin: 0 auto;
    padding: 32px 20px;
    text-align: center;

    @include min-screen($screen__l) {
        padding: 64px 20px;
    }

    h2 {
        margin-top: 0;
    }
}

.about-us-values {
    padding: 32px 20px;

    @include min-screen($screen__l2) {
        padding: 64px 0;
    }

    .pagebuilder-column-group {
        max-width: $screen__l2;
        margin: 0 auto;
        padding: 64px 0;
    }

    .pagebuilder-column-line {
        padding: 20px;
        box-sizing: border-box;

        @include min-screen($screen__l) {
            padding: 0 40px;
        }

        .pagebuilder-column:first-child {
            padding-right: 0;

            @include min-screen($screen__l) {
                padding-right: 20%;
            }

            h2 {
                margin-top: 0;
            }
        }

        .pagebuilder-column {
            [data-content-type='banner'] {
                border-bottom: 1px solid $c-gray-200;

                &:last-child {
                    border: none;
                }
            }
        }
    }

    .pagebuilder-banner-wrapper {
        min-height: 10px;

        .pagebuilder-overlay {
            padding: 12px 0;

        }
    }

    .pagebuilder-poster-content {
        box-sizing: border-box;
        padding-left: 92px;

        h4 {
            margin-bottom: 8px;
        }
    }
}


.about-us-history {
    .year-content {
        display: none;
    }
}

.about-us-products {
    @include add-spacing;

    a.pagebuilder-button-link,
    button.pagebuilder-button-link {
        @include button-with-arrow;
        padding-left: 0;
        margin: 0;
        font-family: $font-family__base;
        font-size: $font-size__pdp-name;
        font-weight: $font-weight__regular;
        text-transform: none;
        text-decoration: none;

        &::after {
            bottom: 4px;
        }
    }

    .pagebuilder-column-line {
        column-gap: 24px;
    }
}