.cms-nouri-guard .columns {
    [data-content-type="row"] {
        @include min-screen($screen__l) {
            max-width: 70%;
        }
    }

    .guard-features {
        @include min-screen($screen__l) {
            position: absolute;
            right: 8% !important; // override PageBuilder
        }

        @include min-screen($screen__xl) {
            right: 18% !important; // override PageBuilder
        }

        .pagebuilder-column-group {
            display: block !important; // override PageBuilder
            column-count: 2;
            margin: 0 auto;
            column-gap: $indent__xl;

            @include min-screen($screen__l) {
                display: flex !important; // override PageBuilder
                flex-direction: column;
            }
        }

        .pagebuilder-column {
            border-radius: 50% !important; // override PageBuilder
            width: 145px !important; // override PageBuilder
            height: 145px;
            margin-bottom: $indent__m;
            min-height: auto !important; // override PageBuilder
            justify-content: center;
            display: none !important; // override PageBuilder

            @include min-screen($screen__l) {
                display: flex !important; // override PageBuilder
                width: 220px !important; // override PageBuilder
                height: 220px;

                span {
                    font-size: $font-size__base--desktop !important; // override PageBuilder
                }
            }
        }
    }

    .spill-types {
        column-count: 2;
        display: block !important; // override PageBuilder
        margin: $indent__l auto;
        padding-left: 15px;

        @include min-screen($screen__l) {
            column-count: 4;
            padding-left: 0;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            margin-top: 0;
        }
    }

    h4 {
        font-weight: $font-weight__semibold;
    }

    .before-after img {
        width: 100%;
    }

    .protectant .pagebuilder-column-group {
        @include max-screen($screen__m) {
            flex-direction: column;

            .pagebuilder-column {
                width: 100% !important; // override PageBuilder
                padding-left: $indent__s;
                padding-right: $indent__s;
                align-items: center;

                @include min-screen($screen__m) {
                    padding: 0;

                    &:first-of-type {
                        padding-right: $indent__s;
                    }
                }

                .figure {
                    padding: 0;
                }
            }
        }
    }
}
