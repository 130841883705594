//
//  Common
//  _____________________________________________

html,
body {
    @include min-screen($mobile-nav__breakpoint) {
        height: 100%;
    }

    overflow-x: hidden;
}

body {
    background-color: $page__background-color;
    overflow-x: hidden;
    overflow: visible;
}

//
//  Top Message
//  ---------------------------------------------
.top-message {
    &__container {
        padding-top: $indent__xs;
        padding-bottom: $indent__xs;
        font-size: 9px;
        text-transform: uppercase;
        font-weight: $font-weight__bold;
        color: $c-vignette;
        text-align: center;
        background-color: $c-berber;

        @include min-screen($screen__m) {
            padding-top: $indent__s;
            padding-bottom: $indent__s;
            font-size: 11px;
        }

        p {
            margin: 0;
        }
    }
}


//
//  Header
//  ---------------------------------------------

.page-header {
    background-color: transparent;
    z-index: 100; // Ensures that the PDP shop similar button does not conflict with the mega menu
    position: relative;

    .panel.wrapper {
        z-index: 102; // Ensures that the account dropdown does not conflict with the search dropdown
    }

    .navigation ul {
        border: 0;
    }

    .navigation li.level0 {
        margin: 0 15px;
        border-top: 0;
        border-bottom: 1px solid $c-gray-200;

        &::first-child {
            border-top: 1px solid $c-gray-200;
        }

        > .level-top {
            font-size: $font-size__navigation;
            letter-spacing: $letter-spacing__navigation;
            font-weight: $font-weight__regular;
            color: $text__color;
            padding: 15px 15px $indent__s 0;

            @include hover() {
                color: $c-gray-300;
            }
        }

        &.active > .level-top {
            @include hover() {
                color: $c-gray-300;
            }
        }
    }
}


//
//  Sticky Navigation
//  _____________________________________________

.page-header--sticky {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 60px;
    background-color: $page__background-color;
    box-shadow: 0 8px 5px -9px rgba(0, 0, 0, .36);

    .panel.wrapper,
    .block-search,
    .social-links,
    .header.content .nourison-divisions {
        display: none;
    }

    .header.content {
        .logo {
            margin-top: 0;
        }
    }

    .navigation li.level0 {
        padding-top: 2px;
    }

    .nav-sections {
        flex-shrink: 1;
        order: 2;
        padding-bottom: 0;

        @include min-screen($mobile-nav__breakpoint) {
            text-align: right;
        }
    }

    .minicart-wrapper {
        order: 3;
        margin-top: indent__s;
    }

    .mega-menu .menu-item:not(.divider) {

        > .menu-level {
            .pagebuilder-column {
                text-align: left;
            }
        }

        @media only screen and (max-width: 1120px) {
            > .menu-item__link {
                font-size: 11px;
            }
        }

        @media only screen and (max-width: 1024px) {
            > .menu-item__link {
                font-size: 9px;
            }
        }

        figure[data-content-type=card] {
            text-align: center;
        }
    }

    @media only screen and (max-width: 1024px) {

        .mega-menu a,
        .mega-menu p {
            font-size: 10px;
            text-align: left;
        }

        .mega-menu .menu-item:not(.divider) > .menu-level {
            left: 45%;
            width: calc(100vw - #{$indent__m * 3});
            padding: 0 $indent__m * 2;
        }

    }
}

//
//  Account Navigation & Currency Switcher
//  ---------------------------------------------
.panel.header {
    background-color: $c-brown;
    display: none;

    @include min-screen($mobile-nav__breakpoint) {
        display: block;
    }
}

.panel.header > .links,
.panel.header .switcher {
    display: none;

    @include min-screen($mobile-nav__breakpoint) {
        display: inline-block;
    }
}

.header.panel > .links {
    @include lib-list-inline();
    font-size: 0;
    color: $c-white;
    letter-spacing: .5px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $screen__l2;
    display: block;
    text-align: right;
    padding: 10px 20px;
    line-height: 1;
    text-transform: uppercase;
    box-sizing: border-box;

    @include min-screen($screen__l2) {
        padding: 10px 5px;
    }

    a {
        color: $c-white;
        font-size: 12px;
        text-decoration: none;
        display: inline-block;
        border: 1px solid $c-white;
        padding: 10px 12px;
    }

    .greet {
        font-weight: $font-weight__bold;
    }

    .counter {
        &::before {
            content: '(';
        }

        &::after {
            content: ')';
        }
    }

    > li {
        font-size: $font-size__base;
        margin: 0 0 0 15px;

        &.welcome,
        a {
            line-height: 1;
        }

        &.welcome a {
            padding-left: $indent__xs;
        }
    }

    .customer-welcome ~ li {
        display: none;
    }

    a:visited {
        color: $c-white;

        @include hover {
            color: rgba($color: $c-white, $alpha: .9);
        }
    }

    > .customer-welcome ~ li.authorization-link {
        display: none;
    }

    @include min-screen($mobile-nav__breakpoint) {
        display: flex;

        li:nth-child(3) {
            flex-grow: 1;
        }
    }
}

.customer-menu .links {
    letter-spacing: .4px;
}

.header.panel .switcher {
    float: right;
    margin-left: 15px;
    margin-right: -6px;
}

.customer-name,
.customer-welcome + .authorization-link {
    @include max-screen($mobile-nav__breakpoint) {
        display: none;
    }
}

.customer-welcome {
    @include min-screen($mobile-nav__breakpoint) {
        @include lib-dropdown($_toggle-selector: '.action.switch',
            $_options-selector: 'ul.header.links',

            $_dropdown-actions-padding: 10px 0,
            $_dropdown-list-item-padding: 0 $indent__base,
            $_dropdown-list-shadow: none,
            $_dropdown-list-pointer: false,
            $_dropdown-list-position-right: 0,
            $_dropdown-list-item-hover: transparent,
            $_dropdown-list-border: 1px solid $c-border,

            $_dropdown-toggle-icon-content: '\e622',
            $_dropdown-toggle-active-icon-content: '\e621',

            $_icon-font-text-hide: true,
            $_icon-font-size: 22px,
            $_icon-font-line-height: 22px,
            $_icon-font-color: $c-white );

        text-decoration: underline;

        &.active {
            ul.header.links {
                z-index: 102; //overlay header desktop icons

                a {
                    color: $text__color;

                    @include hover {
                        color: $color-black
                    }
                }
            }

            .action.switch:before {
                content: '\e621';
            }
        }

        .customer-name {
            cursor: pointer;
        }

        .customer-menu {
            display: none;
        }

        .action.switch {
            @include lib-button-reset();
        }

        ul.header.links {
            min-width: 190px;
            right: 0;
            padding: 10px 0;

            a {
                font-size: 14px;
                text-decoration: none;
                padding: $indent__xs 0;

                &:not(.no-display) {
                    display: block;
                }
            }
        }

        .link.wishlist .counter {
            &::before {
                content: '('
            }

            &::after {
                content: ')'
            }
        }

        &.active .customer-menu {
            display: block;
        }

        .greet {
            display: none;
        }
    }
}

//
//  Main Header Area
//  ---------------------------------------------

.header.content {
    @include layout-container();
    @include lib-clearfix();
    width: 100%;
    padding: 6px 20px;

    .block-search .input-text {
        border-width: 0 0 1px 0;
        font-size: 13px;
        height: 42px;
        padding-left: 10px;
        margin-top: 6px;
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        background-color: transparent;

        @include min-screen($mobile-nav__breakpoint) {
            height: 20px;
            padding-left: 0;
            width: 95%;
        }

        &::placeholder {
            color: $c-gray-400;
        }
    }

    @include min-screen($screen__l2) {
        padding: 6px 5px 0 0;
    }

    @include min-screen($mobile-nav__breakpoint) {
        display: grid;
        grid-template-columns: auto auto 1fr auto;
        grid-template-rows: 1fr;
        grid-template-areas: ". logo nav search bag";

        .logo {
            margin-top: 13px;
            grid-area: logo;
        }

        .minicart-wrapper {
            grid-area: bag;
        }

        .block-search {
            margin-right: 5px;
            grid-area: search;
        }

        .nav-sections {
            grid-area: nav;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @include max-screen($mobile-nav__breakpoint) {
        .field.search .tooltip-content {
            display: none !important; // override tooltip JS
        }
    }

}

.logo {
    float: left;
    margin: 0 0 0 ($indent__s * 1.5);
    max-width: 50%;
    position: relative;
    z-index: 5;

    @include min-screen($mobile-nav__breakpoint) {
        margin: 0;
        margin-right: auto;
    }

    svg {
        width: 106px;
        height: 32px;

        @include min-screen($mobile-nav__breakpoint) {
            width: 132px;
            height: 40px;
        }

    }

    .page-print & {
        float: none;
    }

    img {
        display: block;

        @include min-screen($mobile-nav__breakpoint) {
            max-height: inherit;
        }
    }
}

.nourison-divisions-parent {
    max-width: 750px;
}

.nourison-divisions {
    position: relative;
    display: none;
    margin-top: $indent__xs;

    @include min-screen($mobile-nav__breakpoint) {
        display: inline-block;
    }

    .nav-sections & {
        display: block;

        @include min-screen($mobile-nav__breakpoint) {
            display: none;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        @include min-screen($mobile-nav__breakpoint) {
            padding-left: $indent__base;
            flex-direction: row;
        }
    }

    li {
        list-style: none;
        margin-right: $indent__s;

        @include min-screen($screen__l) {
            margin-right: $indent__base;
        }
    }

    a {
        font-family: $font-family__secondary;
        font-style: italic;
    }

    a {
        color: $text__color;
        font-size: 15px;
        line-height: 1.5;
        letter-spacing: .2px;
        text-decoration: none;

        @include min-screen($mobile-nav__breakpoint) {
            color: $c-ikat
        }

        @include hover {
            color: $link__hover__color;

            @include min-screen($mobile-nav__breakpoint) {
                color: $text__color;
            }
        }
    }

    &__list {
        list-style: none;
    }

    &__link {
        display: inline-block;

        // mobile menu display
        .section-item-content & {
            display: block;
            margin-bottom: $indent__s;
        }
    }
}

.page-main > .page-title-wrapper .page-title {
    @include min-screen($mobile-nav__breakpoint) {
        margin-top: 15px;
        display: inline-block;
    }

    + .action {
        margin-top: $indent__l;

        @include min-screen($mobile-nav__breakpoint) {
            float: right;
            margin-top: $indent__base;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @include lib-visually-hidden();
    }

    &:focus {
        background: $color-gray94;
        padding: $indent__s;
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @extend .pt-big, .pb-big;
    background-color: $c-brown;
    width: 100%;

    @include max-screen($screen__l) {
        display: flex;

    }

    a {
        text-decoration: none;
        color: $c-white;
    }
}

.footer {


    &__connect {
        flex-direction: column;
        max-width: $form-field__width__desktop;

        @include min-screen($screen__l) {
            display: flex;
        }
    }

    h6 {
        margin-top: 0;
        margin-bottom: 10px;
        color: $c-white;
        font-weight: $font-weight__semibold;
        text-transform: uppercase;
    }

    &.content {
        flex-wrap: wrap;
        flex-direction: row;
        @include layout-container;

        @include max-screen($screen__l) {
            display: flex;
        }

        .links {
            flex: 0 0 auto;
            width: 100%;
        }

        .social {
            flex: 1 0 auto;
            width: 100%;

            @include max-screen($screen__l2) {
                [data-content-type='row'][data-appearance='contained'] {
                    width: 100%;
                }
            }

            @include max-screen($screen__l) {
                display: flex;
                justify-content: space-between;
            }

            @include max-screen($screen__s) {
                flex-direction: column;
            }
        }

        .phone,
        .copyright,
        .secondary {
            flex: 0 0 100%;
        }

    }

    &.content .social-links {
        flex-wrap: wrap;
        max-width: 216px;
        margin-bottom: 0;

        li {
            flex: 1 0 9%;

            &:not(:last-child) {
                margin: 0 15px 16px 15px;
            }

            margin: 0 15px 16px 15px;

            &:first-child {
                margin: 0 15px 16px 0;
            }

            &:nth-of-type(4) {
                margin-right: 0;
            }
        }
    }

    .footer-logo {
        &.top-logo {
            display: none;
        }

        @include max-screen($screen__l) {
            display: none;

            &.top-logo {
                display: block;
                margin-bottom: 30px;
            }
        }

        @include icon-svg($icon: norison-logo-white,
            $fill: $c-white,
            $fill-hover: $c-white,
            $hideLabel: true,
            $width: 209px,
            $height: 62px,
            $pseudo: after);

        @include max-screen($screen__m) {
            &::after {
                width: 176px;
                height: 52px;
            }
        }
    }


    .footer-phone-icons-wrap {
        @include max-screen($screen__l) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__columns,
    &__column {
        margin: 0 !important; // overrides bluefoot
        padding: 0 !important; // overrides bluefoot
        padding-right: $indent__xs !important; // overrides bluefoot;
    }

    &__columns {
        display: flex;
        flex-wrap: wrap;
        margin-top: $indent__l !important; // overrides bluefoot;
        margin-bottom: $indent__base !important; // overrides bluefoot;

        @include min-screen($screen__s) {
            flex-wrap: unset;
            margin-bottom: $indent__l !important; // overrides bluefoot;
        }

        @include min-screen($screen__m) {
            margin-top: $indent__xs !important; // overrides bluefoot;
        }

        .pagebuilder-column-group {
            flex-wrap: wrap;

            @include min-screen($screen__m) {
                flex-wrap: nowrap;
            }
        }
    }

    &__column {
        width: calc(50% - #{$indent__s}) !important; // override PageBuilder
        margin-bottom: $indent__xs !important; // override PageBuilder
        width: 50% !important; // override PageBuilder

        @include min-screen($screen__s) {
            width: 25% !important; // override PageBuilder
        }

        @include max-screen($screen__m) {
            &.pagebuilder-column {
                flex-basis: 25%;
            }
        }

        @include max-screen($screen__s) {
            &.pagebuilder-column {
                flex-basis: 50%;
            }
        }

    }

    &__links ul {
        margin-bottom: $indent__xl;
        padding-left: 0;
        list-style: none;
        font-size: 16px;
        font-family: $font-family__secondary;
        line-height: 1.5;
        letter-spacing: .2px;

        @include min-screen($screen__s) {
            margin-bottom: 15px;
        }

        li {
            margin-bottom: $indent__s;
        }

        a {


            @include hover {
                color: $c-brown-100;
            }
        }
    }

    &__title {
        color: $c-brown-100;
        font-size: 12px;
        font-family: $font-family__secondary;
        font-weight: $font-weight__bold;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    &__actions {
        margin: 0;
        padding-left: 0;
        justify-content: center;

        ul {
            display: flex;
            padding-left: 0;
            margin-top: 0;
            justify-content: center;
        }

        li {
            position: relative;
            margin: 0;
            padding-right: $indent__s;
            list-style: none;

            &:not(:last-child)::after {
                content: "|";
                right: 2px;
                position: absolute;
                color: $c-white;
            }
        }

        a {
            color: $c-white;
            font-size: 12px;
            text-decoration: none;

            &:hover {
                color: $c-brown-100;
            }
        }
    }

    .phone {

        a,
        p {
            @include bryant-park-headings-h6();
            color: $c-vignette;
            margin-top: 0;
        }

        p {
            margin-bottom: $indent__xs;
        }
    }

    .block {
        float: right;
    }

    .switcher-store,
    .links {
        @include min-screen($mobile-nav__breakpoint) {
            display: inline-block;
            vertical-align: top;
            padding-right: 50px;
        }
    }

    .links {
        @include lib-list-reset-styles();

        @include min-screen($mobile-nav__breakpoint) {
            padding: 0;
        }

        > li {
            margin-bottom: 25px;
        }
    }

    .switcher-store {
        margin: 0 0 30px;
    }

    .copyright,
    .bugs {
        color: $text__color-alt;
        display: block;
        margin: 20px 0 0;
    }

    .copyright {
        margin: 0;
        color: $c-white;
        font-size: 12px;
        padding: 20px 0;
        cursor: default;
        text-align: center;

        @include min-screen($mobile-nav__breakpoint) {
            @include lib-clearfix();
            margin-right: $indent__s;
        }

        @include min-screen($screen__m) {
            text-align: left;
            padding: 0;
        }
    }
}

.phone-wrap {
    border-radius: 8px;
    background-color: $c-brown-500;
    padding: 16px 32px;
    color: $c-white;
    margin: 0;
    text-align: center;
    display: inline-block;

    @include min-screen($screen__l) {
        margin: 40px 0;
    }

    @include max-screen($screen__s) {
        margin-bottom: 30px;
        padding: 16px;
    }

    p {
        font-family: $font-family__base;
        font-size: $font-size__base--xl;
        margin: 0;
        line-height: 1;

        @include min-screen($screen__s) {
            font-size: $font-size__pdp-sku;
        }

    }

    .darkroom-eyebrow {
        color: $c-white;
    }
}

@include min-screen($screen__l) {
    .footer.content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .social {
            grid-area: 1 / 1 / 2 / 2;
        }

        .links {
            grid-area: 1 / 2 / 2 / 4;
        }

        .copyright {
            grid-area: 2 / 1 / 3 / 4;
        }

        .secondary {
            grid-area: 3 / 1 / 4 / 4;
        }
    }

    @include max-screen($mobile-nav__breakpoint) {
        .pagebuilder-column-line {
            flex-wrap: nowrap;
        }
    }
}


.page-header .switcher,
.page-footer .switcher {
    // hiding store switcher per client's request
    display: none;
    margin-right: 10px;

    .options {
        @include lib-dropdown($_dropdown-actions-padding : 0,
            $_dropdown-list-item-padding : 0,
            $_dropdown-toggle-icon-content : $icon-down,
            $_dropdown-toggle-active-icon-content: $icon-up,
            $_icon-font-text-hide : true,
            $_icon-font-size : 22px,
            $_icon-font-line-height : 22px,
            $_dropdown-list-min-width : 160px);

        ul.dropdown a {
            display: block;
            padding: 8px;

            @include hover {
                text-decoration: none;
            }
        }

        .action.toggle::before {
            float: right;
        }
    }

    li {
        font-size: $font-size__s;
        margin: 0;
    }

    .label {
        @include lib-visually-hidden();
    }

    strong {
        font-weight: $font-weight__regular;
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child) {
    @extend .abs-margin-for-blocks-and-widgets;
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header .widget.block,
.page-footer .widget.block {
    margin: $indent__base 0;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker td {
    padding: 0;
}

//
//  hide cookie notice
//  ---------------------------------------------
.cookie-status-message {
    display: none;
}

@font-face {
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Futura-Std/FuturaStdLight.otf');
}

@font-face {
    font-family: 'Futura Std';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/Futura-Std/FuturaStdLightOblique.otf');
}

@font-face {
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Futura-Std/FuturaStdMedium.otf');
}

@font-face {
    font-family: 'Futura Std';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Futura-Std/FuturaStdMediumOblique.otf');
}

@font-face {
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Futura-Std/FuturaStdHeavy.otf');
}

@font-face {
    font-family: 'Futura Std';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/Futura-Std/FuturaStdHeavyOblique.otf');
}

//New Darkroom Typography 
@font-face {
    font-family: 'TT-Hoves';
    src: url('../fonts/TT-Hoves/tt_hoves_pro_regular-webfont.woff2') format("woff2"), url("../fonts/TT-Hoves/tt_hoves_pro_regular-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'TT-Hoves';
    src: url('../fonts/TT-Hoves/tt_hoves_pro_medium-webfont.woff2') format("woff2"), url("../fonts/TT-Hoves/tt_hoves_pro_medium-webfont.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT-Hoves';
    src: url('../fonts/TT-Hoves/tt_hoves_pro_demibold-webfont.woff2') format("woff2"), url("../fonts/TT-Hoves/tt_hoves_pro_demibold-webfont.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Larken';
    src: url('../fonts/Larken/LarkenRegular.woff2') format("woff2"), url("../fonts/Larken/LarkenRegular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Larken';
    src: url('../fonts/Larken/LarkenLight.woff2') format("woff2"), url("../fonts/Larken/LarkenLight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Larken';
    src: url('../fonts/Larken/LarkenBold.woff2') format("woff2"), url("../fonts/Larken/LarkenBold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}