.heading-with-border {
    position: relative;
    height: 100%;
    margin-bottom: $indent__xs !important; // overrides bluefoot
    padding: $indent__base 0;
    background-color: $page__background-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include min-screen($screen__m) {
        margin-bottom: 15px !important; // overrides bluefoot
    }

    /*
    /* Removes margin-bottom from heading with border with a textarea below
    /* to account for line-height of text area
    */

    &.heading-with-textarea {
        margin-bottom: 0 !important; // overrides bluefoot

        @include min-screen($screen__m) {
            margin-bottom: $indent__s !important; // overrides bluefoot
        }
    }
}

.heading-with-border__wrapper,
.block.upsell .title {
    position: relative;
    text-align: center;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        width: 100%;
        border-bottom: 1px solid $c-border;
    }
}

.heading-with-border__row,
.block.upsell .title h3 {
    position: relative;
    display: inline-block;
    margin: 0 !important; // overrides bluefoot
    padding-left: $indent__base;
    padding-right: $indent__base;
    color: $c-vignette;
    text-align: center;
    background-color: $page__background-color;

    @include min-screen($screen__m) {
      padding-left: 40px;
      padding-right: 40px;
    }
}

h4.heading-with-border__row {
    font-size: 16px;
    
    @include min-screen($screen__l) {
        font-size: 20px;
    }
}