/**
 * Magento UI mixin overrides
 *
 * You can define a mixin with the exact same name to "override" it.
 * Since we generally don't want to directly edit the Magento UI
 * files, we can extend its mixins here.
 */

/**
 * Lot here, but this is just a direct copy of Snowdog's
 * lib-button-primary implementation. Only changes are the
 * @includes underneath lib-button
 *
 * This is done because the mixin itself does not support
 * several properties we're using, e.g text-decoration
 */

//
//  Buttons
//  _____________________________________________

@mixin lib-button($_button-font-family : $button__font-family,
    $_button-font-size : $font-size__base--m,
    $_button-font-weight : $font-weight__regular,
    $_button-line-height : normal,

    $_button-cursor : pointer,
    $_button-display : inline-block,
    $_button-disabled-opacity : 0.5,
    $_button-width : auto,
    $_button-margin : 0,
    $_button-padding : $button__padding,
    $_button-color : $button__color,
    $_button-background : $button__background,
    $_button-border : $button__border,
    $_button-border-radius : $button__border-radius,

    $_button-color-hover : $button__hover__color,
    $_button-background-hover : $button__hover__background,
    $_button-border-hover : $button__hover__border,

    $_button-color-active : $button__active__color,
    $_button-background-active : $_button-background-hover,
    $_button-border-active : $button__active__border,

    $_button-gradient : inherit,
    $_button-gradient-direction : inherit,
    $_button-gradient-color-start : inherit,
    $_button-gradient-color-end : inherit,
    $_button-gradient-color-start-hover : inherit,
    $_button-gradient-color-end-hover : inherit,
    $_button-gradient-color-start-active: inherit,
    $_button-gradient-color-end-active : inherit,

    $_button-icon-use : false,
    $_button-font-content : $icon-settings,
    $_button-icon-font : $button-icon__font,
    $_button-icon-font-size : 22px,
    $_button-icon-font-line-height : $_button-icon-font-size,
    $_button-icon-font-color : inherit,
    $_button-icon-font-color-hover : inherit,
    $_button-icon-font-color-active : inherit,
    $_button-icon-font-margin : 0,
    $_button-icon-font-vertical-align : top,
    $_button-icon-font-position : $icon__position,
    $_button-icon-font-text-hide : inherit) {
    background-image: none; // Reset unusual Firefox-on-Android default style
    background: $_button-background;
    @include lib-background-gradient($_button-gradient-color-start,
        $_button-gradient-color-end,
        $_button-gradient-direction,
        $_button-gradient );
    border: $_button-border;
    border-radius: $_button-border-radius;
    color: $_button-color;
    cursor: $_button-cursor;
    display: $_button-display;
    font-family: $_button-font-family;
    font-weight: $_button-font-weight;
    margin: $_button-margin;
    padding: $_button-padding;
    width: $_button-width;
    font-size: $_button-font-size;
    @include lib-line-height($_button-line-height);
    box-sizing: border-box;
    vertical-align: middle;
    position: relative;
    text-decoration: none;
    z-index: 1; // Ensure that button text appears below button hover effect

    > span {
        z-index: 1; // Ensure that button content appears above ::after
        position: relative;
    }

    @include _lib-button-icon ($_button-icon-use : $_button-icon-use,
        $_icon-font-content : $_button-font-content,
        $_icon-font : $_button-icon-font,
        $_icon-font-size : $_button-icon-font-size,
        $_icon-font-line-height : $_button-icon-font-line-height,
        $_icon-font-color : $_button-icon-font-color,
        $_icon-font-color-hover : $_button-icon-font-color-hover,
        $_icon-font-color-active : $_button-icon-font-color-active,
        $_icon-font-margin : $_button-icon-font-margin,
        $_icon-font-vertical-align: $_button-icon-font-vertical-align,
        $_icon-font-position : $_button-icon-font-position,
        $_icon-font-text-hide : $_button-icon-font-text-hide );

    @include hover {
        @include lib-background-gradient($_button-gradient-color-start-hover,
            $_button-gradient-color-end-hover,
            $_button-gradient-direction,
            $_button-gradient );
        border: $_button-border-hover;
        color: $_button-color-hover;
        text-decoration: none;
    }

    &:active {
        @include lib-background-gradient($_button-gradient-color-start-active,
            $_button-gradient-color-end-active,
            $_button-gradient-direction,
            $_button-gradient );
        border: $_button-border-active;
        color: $_button-color-active;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        opacity: $_button-disabled-opacity;
        cursor: default;
        pointer-events: none; // Disabling of clicks
    }
}

@mixin lib-button-primary($_button-line-height : normal,
    $_button-width : auto,
    $_button-margin : 0,
    $_button-padding : $button-primary__padding,

    $_button-color : $button-primary__color,
    $_button-border : $button-primary__border,
    $_button-background : $button-primary__background,

    $_button-color-hover : $button-primary__hover__color,
    $_button-border-hover : $button-primary__hover__border,
    $_button-background-hover : $button-primary__hover__background,

    $_button-color-active : $button-primary__active__color,
    $_button-border-active : $button-primary__active__border,
    $_button-background-active : $button-primary__active__background,

    $_button-gradient : inherit,
    $_button-gradient-direction : inherit,
    $_button-gradient-color-start : inherit,
    $_button-gradient-color-end : inherit,

    $_button-gradient-color-start-active: inherit,
    $_button-gradient-color-end-active : inherit,

    $_button-gradient-color-start-hover : inherit,
    $_button-gradient-color-end-hover : inherit) {
    @include lib-button($_button-line-height : $_button-line-height,
        $_button-width : $_button-width,
        $_button-height : $_button-height,
        $_button-margin : $_button-margin,
        $_button-padding : $_button-padding,
        $_button-color : $_button-color,
        $_button-background : $_button-background,
        $_button-border : $_button-border,
        $_button-color-hover : $_button-color-hover,
        $_button-background-hover : $_button-background-hover,
        $_button-border-hover : $_button-border-hover,
        $_button-color-active : $_button-color-active,
        $_button-background-active : $_button-background-active,
        $_button-border-active : $_button-border-active,
        $_button-gradient : $_button-gradient,
        $_button-gradient-direction : $_button-gradient-direction,
        $_button-gradient-color-start : $_button-gradient-color-start,
        $_button-gradient-color-end : $_button-gradient-color-end,
        $_button-gradient-color-start-hover : $_button-gradient-color-start-hover,
        $_button-gradient-color-end-hover : $_button-gradient-color-end-hover,
        $_button-gradient-color-start-active: $_button-gradient-color-start-active,
        $_button-gradient-color-end-active : $_button-gradient-color-end-active );

    @include hover {
        background-color: $c-light-brown;
    }

    @include lib-link-as-button();
    @include headingFont;
}

//
//  Button reset
//  ---------------------------------------------

@mixin lib-button-reset() {
    background-image: none; // Reset unusual Firefox-on-Android default style
    background: none;
    box-sizing: content-box; // Hack: fix Firefox button line-height problem
    border: 0;
    box-shadow: none;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: $font-weight__regular;

    &:focus,
    &:active {
        background: none;
    }

    &:hover {
        background: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        pointer-events: none; // Disabling of clicks
        opacity: 0.5;
    }

    &::after {
        content: none !important;
        background: none;
        border: 0;
    }
}

//
//  Button primary
//  ---------------------------------------------

@mixin lib-button-primary($_button-line-height : normal,
    $_button-width : auto,
    $_button-margin : 0,
    $_button-padding : $button-primary__padding,

    $_button-color : $button-primary__color,
    $_button-border : $button-primary__border,
    $_button-background : $button-primary__background,

    $_button-color-hover : $button-primary__hover__color,
    $_button-border-hover : $button-primary__hover__border,
    $_button-background-hover : $button-primary__hover__background,

    $_button-color-active : $button-primary__active__color,
    $_button-border-active : $button-primary__active__border,
    $_button-background-active : $button-primary__active__background,

    $_button-gradient : inherit,
    $_button-gradient-direction : inherit,
    $_button-gradient-color-start : inherit,
    $_button-gradient-color-end : inherit,

    $_button-gradient-color-start-active: inherit,
    $_button-gradient-color-end-active : inherit,

    $_button-gradient-color-start-hover : inherit,
    $_button-gradient-color-end-hover : inherit) {
    @include lib-button($_button-line-height : $_button-line-height,
        $_button-width : $_button-width,
        $_button-margin : $_button-margin,
        $_button-padding : $_button-padding,
        $_button-color : $_button-color,
        $_button-background : $_button-background,
        $_button-border : $_button-border,
        $_button-color-hover : $_button-color-hover,
        $_button-background-hover : $_button-background-hover,
        $_button-border-hover : $_button-border-hover,
        $_button-color-active : $_button-color-active,
        $_button-background-active : $_button-background-active,
        $_button-border-active : $_button-border-active,
        $_button-gradient : $_button-gradient,
        $_button-gradient-direction : $_button-gradient-direction,
        $_button-gradient-color-start : $_button-gradient-color-start,
        $_button-gradient-color-end : $_button-gradient-color-end,
        $_button-gradient-color-start-hover : $_button-gradient-color-start-hover,
        $_button-gradient-color-end-hover : $_button-gradient-color-end-hover,
        $_button-gradient-color-start-active: $_button-gradient-color-start-active,
        $_button-gradient-color-end-active : $_button-gradient-color-end-active );

    @include lib-link-as-button();
    @include headingFont;
}

@mixin lib-popup($_popup-width : 100%,
    $_popup-height : $popup__height,
    $_popup-padding : 22px,
    $_popup-background : $popup__background,
    $_popup-border : 1px solid $color-gray-light3,
    $_popup-shadow : 0 3px 3px rgba(0, 0, 0, .15),
    $_popup-fade : opacity .3s linear,
    $_popup-z-index : 1001,

    $_popup-position : fixed,
    $_popup-position-top : 0,
    $_popup-position-right : 0,
    $_popup-position-bottom : 0,
    $_popup-position-left : 0,

    $_popup-margin-top : inherit,
    $_popup-margin-right : inherit,
    $_popup-margin-bottom : inherit,
    $_popup-margin-left : inherit,

    $_popup-content-height : auto,
    $_popup-header-margin : 0 0 25px,
    $_popup-content-margin : inherit,
    $_popup-footer-margin : inherit,

    $_popup-title-headings : true, // [true|false]
    $_popup-title-headings-level : h3, // [h1|h2|h3|h4|h5|h6]

    // Icons parameters
    $_popup-button-close-icon : true,
    $_popup-button-close-reset : true,
    $_popup-button-close-position : absolute,
    $_popup-button-close-position-top : $indent__s,
    $_popup-button-close-position-right : $indent__s,
    $_popup-button-close-position-bottom : inherit,
    $_popup-button-close-position-left : inherit,
    $_popup-icon-font-content : $icon-remove,
    $_popup-icon-font : $icon-font,
    $_popup-icon-font-size : $popup-icon-font__size,
    $_popup-icon-font-line-height : $popup-icon-font__line-height,
    $_popup-icon-font-color : $popup-icon-font__color,
    $_popup-icon-font-color-hover : $popup-icon-font__color-hover,
    $_popup-icon-font-color-active : $popup-icon-font__color-active,
    $_popup-icon-font-margin : inherit,
    $_popup-icon-font-vertical-align : top,
    $_popup-icon-font-position : $button-icon__position,
    $_popup-icon-font-text-hide : true,

    // Actions toolbar parameters
    $_popup-actions-toolbar : inherit,
    $_popup-actions-toolbar-actions-position : $actions-toolbar-actions__position,
    $_popup-actions-toolbar-actions-reverse : $actions-toolbar-actions__reverse,
    $_popup-actions-toolbar-actions-margin : $actions-toolbar-actions__margin,
    $_popup-actions-toolbar-margin : $actions-toolbar__margin,
    $_popup-actions-toolbar-padding : $actions-toolbar__padding,
    $_popup-actions-toolbar-primary-actions-margin : $actions-toolbar-actions-primary__margin,
    $_popup-actions-toolbar-secondary-actions-margin : $actions-toolbar-actions-secondary__margin,
    $_popup-actions-toolbar-actions-links-margin-top : $actions-toolbar-actions-links__margin-top,
    $_popup-actions-toolbar-primary-actions-links-margin-top : $actions-toolbar-actions-links-primary__margin-top,
    $_popup-actions-toolbar-secondary-actions-links-margin-top: $actions-toolbar-actions-links-secondary__margin-top) {
    background: $_popup-background;
    border: $_popup-border;
    padding: $_popup-padding;
    width: $_popup-width;
    @include _lib-popup-height($_popup-height);
    @include _lib-popup-shadow($_popup-shadow);
    @include _lib-popup-action-toolbar($_popup-actions-toolbar,
        $_popup-actions-toolbar-actions-position,
        $_popup-actions-toolbar-actions-reverse,
        $_popup-actions-toolbar-margin,
        $_popup-actions-toolbar-padding,
        $_popup-actions-toolbar-actions-margin,
        $_popup-actions-toolbar-primary-actions-margin,
        $_popup-actions-toolbar-secondary-actions-margin,
        $_popup-actions-toolbar-actions-links-margin-top,
        $_popup-actions-toolbar-primary-actions-links-margin-top,
        $_popup-actions-toolbar-secondary-actions-links-margin-top );

    .popup-header {
        margin: $_popup-header-margin;

        .title {
            @include _lib-popup-headings($_popup-title-headings,
                $_popup-title-headings-level );
        }

        padding-right: $_popup-icon-font-size;
    }

    .popup-content {
        @include _lib-popup-height($_popup-content-height);
        margin: $_popup-content-margin;
    }

    .popup-footer {
        margin: $_popup-footer-margin;
    }

    .popup-actions {
        .action.close {
            position: $_popup-button-close-position;
            @include _lib-popup-button-close-icon($_popup-button-close-icon,
                $_popup-icon-font-content,
                $_popup-icon-font,
                $_popup-icon-font-size,
                $_popup-icon-font-line-height,
                $_popup-icon-font-color,
                $_popup-icon-font-color-hover,
                $_popup-icon-font-color-active,
                $_popup-icon-font-margin,
                $_popup-icon-font-vertical-align,
                $_popup-icon-font-position,
                $_popup-icon-font-text-hide );
            @include _lib-popup-button-close-reset($_popup-button-close-reset );
            width: auto;
            bottom: $_popup-button-close-position-bottom;
            left: $_popup-button-close-position-left;
            right: $_popup-button-close-position-right;
            top: $_popup-button-close-position-top;
        }
    }

    @include _lib-popup-fade($_popup-fade);
    bottom: $_popup-position-bottom;
    left: $_popup-position-left;
    margin: $_popup-margin-top $_popup-margin-right $_popup-margin-bottom $_popup-margin-left;
    position: $_popup-position;
    right: $_popup-position-right;
    top: $_popup-position-top;
    z-index: $_popup-z-index;
    display: none;
    opacity: 0;

    &.active {
        opacity: 1;
    }
}

//Drop Downs

//
//  Dropdowns
//  _____________________________________________

//
//  Simple dropdown
//  ---------------------------------------------

@mixin lib-dropdown($_toggle-selector : ".action.toggle",
    $_options-selector : "ul.dropdown",

    $_dropdown-actions-padding : inherit,
    $_dropdown-list-min-width : 100%,
    $_dropdown-list-width : inherit,
    $_dropdown-list-height : inherit,
    $_dropdown-list-margin-top : 4px,
    $_dropdown-list-position-top : 100%,
    $_dropdown-list-position-bottom : inherit,
    $_dropdown-list-position-left : inherit,
    $_dropdown-list-position-right : inherit,
    $_dropdown-list-background : $color-white,
    $_dropdown-list-border : 1px solid $color-gray-light2,

    $_dropdown-list-pointer : true,
    $_dropdown-list-pointer-border : $color-gray-light2,
    $_dropdown-list-pointer-position : left,
    $_dropdown-list-pointer-position-top : -12px,
    $_dropdown-list-pointer-position-left-right: 10px,

    $_dropdown-list-item-border : 0,
    $_dropdown-list-item-padding : 3px $indent__xs,
    $_dropdown-list-item-margin : 0,
    $_dropdown-list-item-hover : $color-gray91,
    $_dropdown-list-item-type-hover : $primary__color,
    $_dropdown-list-shadow : 0 3px 3px rgba(0, 0, 0, .15),
    $_dropdown-list-z-index : 100,

    $_dropdown-toggle-icon-content : '\e607',
    $_dropdown-toggle-active-icon-content : '\e621',

    $_icon-font : $icon-font,
    $_icon-font-size : 22px,
    $_icon-font-line-height : $_icon-font-size,
    $_icon-font-color : inherit,
    $_icon-font-color-hover : none,
    $_icon-font-color-active : inherit,
    $_icon-font-margin : 0,
    $_icon-font-position : $icon__position,
    $_icon-font-vertical-align : top,
    $_icon-font-text-hide : inherit) {
    @include lib-clearfix();
    position: relative;

    #{$_toggle-selector} {
        padding: $_dropdown-actions-padding;
        cursor: pointer;
        @include _lib-dropdown-icon($_dropdown-toggle-icon-content,
            $_dropdown-toggle-active-icon-content,
            $_icon-font,
            $_icon-font-size,
            $_icon-font-line-height,
            $_icon-font-color,
            $_icon-font-color-hover,
            $_icon-font-color-active,
            $_icon-font-margin,
            $_icon-font-vertical-align,
            $_icon-font-position,
            $_icon-font-text-hide );
    }

    @include _lib-dropdown-styles($_options-selector,
        $_dropdown-list-min-width,
        $_dropdown-list-width,
        $_dropdown-list-height,
        $_dropdown-list-margin-top,
        $_dropdown-list-background,
        $_dropdown-list-border,
        $_dropdown-list-z-index,
        $_dropdown-list-shadow,
        $_dropdown-list-item-padding,
        $_dropdown-list-item-margin,
        $_dropdown-list-item-border,
        $_dropdown-list-item-hover,
        $_dropdown-list-item-type-hover,
        $_dropdown-list-position-top,
        $_dropdown-list-position-bottom,
        $_dropdown-list-position-left,
        $_dropdown-list-position-right,
        $_dropdown-list-pointer,
        $_dropdown-list-pointer-border,
        $_dropdown-list-pointer-position,
        $_dropdown-list-pointer-position-top,
        $_dropdown-list-pointer-position-left-right );
}

//
//  Split dropdown
//  ---------------------------------------------

@mixin lib-dropdown-split($_toggle-selector : ".action.toggle",
    $_options-selector : "ul.dropdown",
    $_button-selector : ".action.split",

    $_dropdown-split-actions-padding : inherit,

    $_dropdown-split-list-min-width : 100%,
    $_dropdown-split-list-width : inherit,
    $_dropdown-split-list-height : inherit,
    $_dropdown-split-list-margin-top : 4px,
    $_dropdown-split-list-position-top : 100%,
    $_dropdown-split-list-position-bottom : inherit,
    $_dropdown-split-list-position-left : inherit,
    $_dropdown-split-list-position-right : inherit,
    $_dropdown-split-list-background : $color-white,
    $_dropdown-split-list-border : 1px solid $color-gray-light2,

    $_dropdown-split-list-pointer : true,
    $_dropdown-split-list-pointer-border : $color-gray-light2,
    $_dropdown-split-list-pointer-position : left,
    $_dropdown-split-list-pointer-position-top : -12px,
    $_dropdown-split-list-pointer-position-left-right: 10px,

    $_dropdown-split-list-item-border : 0,
    $_dropdown-split-list-item-padding : 3px $indent__xs,
    $_dropdown-split-list-item-margin : 0,
    $_dropdown-split-list-item-hover : $_dropdown-split-list-item-hover-background,
    $_dropdown-split-list-item-type-hover : $primary__color,
    $_dropdown-split-list-shadow : 0 3px 3px rgba(0, 0, 0, .15),
    $_dropdown-split-list-z-index : 100,

    $_dropdown-split-button-border-radius-fix : inherit,
    $_dropdown-split-button-actions-padding : inherit,

    $_dropdown-split-toggle-actions-padding : inherit,
    $_dropdown-split-toggle-position : right,
    $_dropdown-split-toggle-icon-content : '\e607',
    $_dropdown-split-toggle-active-icon-content : '\e621',

    $_icon-font : $icons__font-name,
    $_icon-font-size : 22px,
    $_icon-font-line-height : 22px,
    $_icon-font-color : inherit,
    $_icon-font-color-hover : inherit,
    $_icon-font-color-active : inherit,
    $_icon-font-margin : inherit,
    $_icon-font-vertical-align : top,
    $_icon-font-position : after,
    $_icon-font-text-hide : true) {
    @include lib-clearfix();
    display: inline-block;
    position: relative;
    @include _lib-dropdown-split-toggle-position($_button-selector,
        $_toggle-selector,
        $_dropdown-split-toggle-position,
        $_dropdown-split-button-border-radius-fix );

    #{$_button-selector} {
        padding: $_dropdown-split-button-actions-padding;
    }

    #{$_toggle-selector} {
        padding: $_dropdown-split-toggle-actions-padding;
        @include _lib-dropdown-split-icon($_dropdown-split-toggle-icon-content,
            $_dropdown-split-toggle-active-icon-content,
            $_icon-font,
            $_icon-font-size,
            $_icon-font-line-height,
            $_icon-font-color,
            $_icon-font-color-hover,
            $_icon-font-color-active,
            $_icon-font-margin,
            $_icon-font-vertical-align,
            $_icon-font-position,
            $_icon-font-text-hide );
    }

    @include _lib-dropdown-styles($_options-selector,
        $_dropdown-split-list-min-width,
        $_dropdown-split-list-width,
        $_dropdown-split-list-height,
        $_dropdown-split-list-margin-top,
        $_dropdown-split-list-background,
        $_dropdown-split-list-border,
        $_dropdown-split-list-z-index,
        $_dropdown-split-list-shadow,
        $_dropdown-split-list-item-padding,
        $_dropdown-split-list-item-margin,
        $_dropdown-split-list-item-border,
        $_dropdown-split-list-item-hover,
        $_dropdown-split-list-item-type-hover,
        $_dropdown-split-list-position-top,
        $_dropdown-split-list-position-bottom,
        $_dropdown-split-list-position-left,
        $_dropdown-split-list-position-right,
        $_dropdown-split-list-pointer,
        $_dropdown-split-list-pointer-border,
        $_dropdown-split-list-pointer-position,
        $_dropdown-split-list-pointer-position-top,
        $_dropdown-split-list-pointer-position-left-right );
}

//
//  Internal use mixins
//  ---------------------------------------------

@mixin _lib-dropdown-styles($_options-selector,
    $_dropdown-list-min-width,
    $_dropdown-list-width,
    $_dropdown-list-height,
    $_dropdown-list-margin-top,
    $_dropdown-list-background,
    $_dropdown-list-border,
    $_dropdown-list-z-index,
    $_dropdown-list-shadow,
    $_dropdown-list-item-padding,
    $_dropdown-list-item-margin,
    $_dropdown-list-item-border,
    $_dropdown-list-item-hover,
    $_dropdown-list-item-type-hover,
    $_dropdown-list-position-top,
    $_dropdown-list-position-bottom,
    $_dropdown-list-position-left,
    $_dropdown-list-position-right,
    $_dropdown-list-pointer,
    $_dropdown-list-pointer-border,
    $_dropdown-list-pointer-position,
    $_dropdown-list-pointer-position-top,
    $_dropdown-list-pointer-position-left-right) {
    #{$_options-selector} {
        background: $_dropdown-list-background;
        border: $_dropdown-list-border;
        z-index: $_dropdown-list-z-index;
        @include lib-list-reset-styles();
        box-sizing: border-box;
        position: absolute;

        @include _lib-dropdown-list-position($_dropdown-list-position-top,
            $_dropdown-list-position-bottom,
            $_dropdown-list-position-left,
            $_dropdown-list-position-right );

        @include _lib-dropdown-list-shadow($_dropdown-list-shadow);
        height: $_dropdown-list-height;
        margin-top: $_dropdown-list-margin-top;
        min-width: $_dropdown-list-min-width;
        width: $_dropdown-list-width;
        display: none;

        li {
            margin: $_dropdown-list-item-margin;
            padding: $_dropdown-list-item-padding;
            @include _lib-dropdown-list-item-border($_dropdown-list-item-border );

            @include hover {
                background: $_dropdown-list-item-hover;
                color: $_dropdown-list-item-type-hover;
                cursor: pointer;
            }
        }

        @include _lib-dropdown-list-pointer($_dropdown-list-background,
            $_dropdown-list-pointer,
            $_dropdown-list-pointer-border,
            $_dropdown-list-pointer-position,
            $_dropdown-list-pointer-position-top,
            $_dropdown-list-pointer-position-left-right );
    }

    &.active {
        overflow: visible;

        #{$_options-selector} {
            display: block;
        }
    }
}

//
//  Dropdown list position
//  ---------------------------------------------

@mixin _lib-dropdown-list-position($_dropdown-list-position-top,
    $_dropdown-list-position-bottom,
    $_dropdown-list-position-left,
    $_dropdown-list-position-right) {
    @if not $_dropdown-list-position-top and $_dropdown-list-position-top !=auto {
        top: $_dropdown-list-position-top;
    }

    @if not $_dropdown-list-position-bottom and $_dropdown-list-position-bottom !=auto {
        bottom: $_dropdown-list-position-bottom;
    }

    @if not $_dropdown-list-position-left and $_dropdown-list-position-left !=auto {
        left: $_dropdown-list-position-left;
    }

    @if not $_dropdown-list-position-right and $_dropdown-list-position-right !=auto {
        right: $_dropdown-list-position-right;
    }
}

//
//  Simple dropdown icon
//  ---------------------------------------------

@mixin _lib-dropdown-icon($_dropdown-toggle-icon-content,
    $_dropdown-toggle-active-icon-content,
    $_icon-font,
    $_icon-font-size,
    $_icon-font-line-height,
    $_icon-font-color,
    $_icon-font-color-hover,
    $_icon-font-color-active,
    $_icon-font-margin,
    $_icon-font-vertical-align,
    $_icon-font-position,
    $_icon-font-text-hide) {
    @include lib-icon-font($_icon-font-content: $_dropdown-toggle-icon-content,
        $_icon-font: $_icon-font,
        $_icon-font-size: $_icon-font-size,
        $_icon-font-line-height: $_icon-font-line-height,
        $_icon-font-color: $_icon-font-color,
        $_icon-font-color-hover: $_icon-font-color-hover,
        $_icon-font-color-active: $_icon-font-color-active,
        $_icon-font-margin: $_icon-font-margin,
        $_icon-font-vertical-align: $_icon-font-vertical-align,
        $_icon-font-position: $_icon-font-position,
        $_icon-font-text-hide: $_icon-font-text-hide );

    &.active {
        @include lib-icon-font($_icon-font-content: $_dropdown-toggle-active-icon-content,
            $_icon-font: $_icon-font,
            $_icon-font-size: $_icon-font-size,
            $_icon-font-line-height: $_icon-font-line-height,
            $_icon-font-color: $_icon-font-color,
            $_icon-font-color-hover: $_icon-font-color-hover,
            $_icon-font-color-active: $_icon-font-color-active,
            $_icon-font-margin: $_icon-font-margin,
            $_icon-font-vertical-align: $_icon-font-vertical-align,
            $_icon-font-position: $_icon-font-position,
            $_icon-font-text-hide: $_icon-font-text-hide );
    }
}

//
//  Split dropdowns
//  ---------------------------------------------

//  Split dropdown icon
@mixin _lib-dropdown-split-icon($_dropdown-toggle-icon-content,
    $_dropdown-toggle-active-icon-content,
    $_icon-font,
    $_icon-font-size,
    $_icon-font-line-height,
    $_icon-font-color,
    $_icon-font-color-hover,
    $_icon-font-color-active,
    $_icon-font-margin,
    $_icon-font-vertical-align,
    $_icon-font-position,
    $_icon-font-text-hide) {
    @include lib-button-icon($_icon-font-content: $_dropdown-toggle-icon-content,
        $_icon-font: $_icon-font,
        $_icon-font-size: $_icon-font-size,
        $_icon-font-line-height: $_icon-font-line-height,
        $_icon-font-color: $_icon-font-color,
        $_icon-font-color-hover: $_icon-font-color-hover,
        $_icon-font-color-active: $_icon-font-color-active,
        $_icon-font-margin: $_icon-font-margin,
        $_icon-font-vertical-align: $_icon-font-vertical-align,
        $_icon-font-position: $_icon-font-position,
        $_icon-font-text-hide: $_icon-font-text-hide );

    &.active {
        @include lib-button-icon($_icon-font-content: $_dropdown-toggle-active-icon-content,
            $_icon-font: $_icon-font,
            $_icon-font-size: $_icon-font-size,
            $_icon-font-line-height: $_icon-font-line-height,
            $_icon-font-color: $_icon-font-color,
            $_icon-font-color-hover: $_icon-font-color-hover,
            $_icon-font-color-active: $_icon-font-color-active,
            $_icon-font-margin: $_icon-font-margin,
            $_icon-font-vertical-align: $_icon-font-vertical-align,
            $_icon-font-position: $_icon-font-position,
            $_icon-font-text-hide: $_icon-font-text-hide );
    }
}

@mixin _lib-dropdown-split-toggle-position($_button-selector,
    $_toggle-selector,
    $_dropdown-split-toggle-position,
    $_dropdown-split-button-border-radius-fix) {
    @if $_dropdown-split-toggle-position ==left {
        #{$_button-selector} {
            float: right;
            margin: 0;
        }

        #{$_toggle-selector} {
            float: left;
            margin: 0;
        }

        @if $_dropdown-split-button-border-radius-fix {
            button#{$_button-selector} {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }

            button + #{$_toggle-selector} {
                border-bottom-right-radius: 0;
                border-right: 0;
                border-top-right-radius: 0;
            }
        }
    }

    @else if $_dropdown-split-toggle-position ==right {
        #{$_button-selector} {
            float: left;
            margin: 0;
        }

        #{$_toggle-selector} {
            float: right;
            margin: 0;
        }

        @if $_dropdown-split-button-border-radius-fix {
            button#{$_button-selector} {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }

            button + #{$_toggle-selector} {
                border-bottom-left-radius: 0;
                border-left: 0;
                border-top-left-radius: 0;
            }
        }
    }
}

//
//  Dropdown show pointer
//  ---------------------------------------------

@mixin _lib-dropdown-list-pointer($_dropdown-list-background,
    $_dropdown-list-pointer,
    $_dropdown-list-pointer-border,
    $_dropdown-list-pointer-position,
    $_dropdown-list-pointer-position-top,
    $_dropdown-list-pointer-position-left-right) {
    @if $_dropdown-list-pointer {
        @include _lib-dropdown-pointer($_dropdown-list-background,
            $_dropdown-list-pointer-border,
            $_dropdown-list-pointer-position,
            $_dropdown-list-pointer-position-top,
            $_dropdown-list-pointer-position-left-right );
    }
}

@mixin _lib-dropdown-pointer($_dropdown-list-background,
    $_dropdown-list-pointer-border,
    $_dropdown-list-pointer-position,
    $_dropdown-list-pointer-position-top,
    $_dropdown-list-pointer-position-left-right) {
    @if $_dropdown-list-pointer-position ==left {
        @include _lib-dropdown-pointer-default($_dropdown-list-background,
            $_dropdown-list-pointer-border );

        &:before {
            left: $_dropdown-list-pointer-position-left-right;
            top: $_dropdown-list-pointer-position-top;
        }

        &:after {
            left: $_dropdown-list-pointer-position-left-right - 1;
            top: $_dropdown-list-pointer-position-top - 2;
        }
    }

    @else if $_dropdown-list-pointer-position ==right {
        @include _lib-dropdown-pointer-default($_dropdown-list-background,
            $_dropdown-list-pointer-border );

        &:before {
            right: $_dropdown-list-pointer-position-left-right;
            top: $_dropdown-list-pointer-position-top;
        }

        &:after {
            right: $_dropdown-list-pointer-position-left-right - 1;
            top: $_dropdown-list-pointer-position-top - 2;
        }
    }
}

@mixin _lib-dropdown-pointer-default($_dropdown-list-background,
    $_dropdown-list-pointer-border) {

    &:before,
    &:after {
        border-bottom-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }

    &:before {
        border: 6px solid;
        border-color: transparent transparent $_dropdown-list-background transparent;
        z-index: 99;
    }

    &:after {
        border: 7px solid;
        border-color: transparent transparent $_dropdown-list-pointer-border transparent;
        z-index: 98;
    }
}

//  Dropdown list item border != 0
@mixin _lib-dropdown-list-item-border($_dropdown-list-item-border) {
    @if not $_dropdown-list-item-border and $_dropdown-list-item-border !=0 and $_dropdown-list-item-border !='' {
        border-top: $_dropdown-list-item-border;

        &:first-child {
            border: none;
        }
    }
}

//  Dropdown list shadow
@mixin _lib-dropdown-list-shadow($_dropdown-list-shadow) {
    box-shadow: $_dropdown-list-shadow;
}

//
//  Messages
//  _____________________________________________

// checking if sass map has adequate key:value, if not
@function messages($map-type, $key) {
    @if map-has-key($map-type, $key) {
        @return map-get($map-type, $key);
    }

    @else {
        @return map-get($messages-default, $key);
    }
}

;

@mixin lib-message($_message-type: info) {

    // info messages == default messages
    @if $_message-type ==info {
        $map-type: $messages-default;
        @include _lib-message();
        @include _lib-message-style($_message-color : map-get($map-type, color),
            $_message-background : map-get($map-type, background),
            $_message-link-color : map-get($map-type, link-color),
            $_message-link-color-hover : map-get($map-type, link-color-hover),
            $_message-link-color-active: map-get($map-type, link-color-active),
            $_message-border-color : $message__border-color );
    }

    @else {
        $msg-type: $_message-type;
        $map-type: $messages-default;

        // find maps adequate to message type
        @if $msg-type ==error {
            @if variable-exists(messages-error) {
                $map-type: $messages-error;
            }
        }

        @else if $msg-type ==success {
            @if variable-exists(messages-success) {
                $map-type: $messages-success;
            }
        }

        @else if $msg-type ==warning {
            @if variable-exists(messages-warning) {
                $map-type: $messages-warning;
            }
        }

        @else if $msg-type ==notice {
            @if variable-exists(messages-notice) {
                $map-type: $messages-notice;
            }
        }

        @else if $msg-type ==global-note {
            @if variable-exists(messages-global-note) {
                $map-type: $messages-global-note;
            }

        }

        @else if $msg-type ==global-caution {
            @if variable-exists(messages-global-caution) {
                $map-type: $messages-global-caution;
            }
        }

        @include _lib-message();
        @include _lib-message-style($_message-color : messages($map-type, color),
            $_message-background : messages($map-type, background),
            $_message-link-color : messages($map-type, link-color),
            $_message-link-color-hover : messages($map-type, link-color-hover),
            $_message-link-color-active: messages($map-type, link-color-active),
            $_message-border-color : $message__border-color );
    }
}

@mixin _lib-message($_message-padding : $indent__s $indent__base,
    $_message-margin : 0 0 $indent__s,
    $_message-border-radius: $message__border-radius) {
    border-radius: $_message-border-radius;
    margin: $_message-margin;
    padding: $_message-padding;
    display: block;
    @include lib-typography($_color : inherit,
        $_font-size : 16px,
        $_font-family: $font-family__secondary,
        $_font-weight: inherit,
        $_font-style : normal,
        $_line-height: 1.5);
    @include _lib-message-border($message__border-width, $message__border-style);
}

@mixin _lib-message-style($_message-background : $message-info__background,
    $_message-color : $message-info__color,
    $_message-link-color : $message-link__color,
    $_message-link-color-hover : $message-link__color-hover,
    $_message-link-color-active: $message-link__color-active,
    $_message-border-color : $message__border-color) {
    background: $_message-background;
    border-color: $_message-border-color;
    color: $_message-color;

    a {
        color: $_message-link-color;

        &:hover {
            color: $_message-link-color-hover;
        }

        &:active {
            color: $_message-link-color-active;
        }
    }
}

@mixin _lib-message-border($_message-border-width: $message__border-width,
    $_message-border-style: $message__border-style) {
    @if $_message-border-width !=0 and $_message-border-width !=inherit and $_message-border-style !=inherit {
        border: $_message-border-width $_message-border-style;
    }
}

//  Messages with lateral icon
@mixin lib-message-icon-lateral($_message-type: info, $_message-position: right) {

    @if $_message-type ==info {
        $map-type: $messages-default;
        @include lib-message($_message-type);
        @include _lib-message-icon-lateral($_message-icon-position : $_message-position,
            $_message-icon : map-get($map-type, icon),
            $_message-icon-color : map-get($map-type, icon-color-lateral),
            $_message-icon-background: map-get($map-type, icon-background),
            $_message-icon-top : map-get($map-type, icon-top),
            $_message-icon-left : map-get($map-type, icon-left),
            $_message-icon-bottom : map-get($map-type, icon-bottom),
            $_message-icon-right : map-get($map-type, icon-right));
    }

    @else {
        $msg-type: $_message-type;
        $map-type: $messages-default;

        @if $msg-type ==error {
            @if variable-exists(messages-error) {
                $map-type: $messages-error;
            }
        }

        @else if $msg-type ==success {
            @if variable-exists(messages-success) {
                $map-type: $messages-success;
            }
        }

        @else if $msg-type ==warning {
            @if variable-exists(messages-warning) {
                $map-type: $messages-warning;
            }
        }

        @else if $msg-type ==notice {
            @if variable-exists(messages-notice) {
                $map-type: $messages-notice;
            }
        }

        @include lib-message($_message-type);
        @include _lib-message-icon-lateral($_message-icon-position : $_message-position,
            $_message-icon : messages($map-type, icon),
            $_message-icon-color : messages($map-type, icon-color-lateral),
            $_message-icon-background: messages($map-type, icon-background),
            $_message-icon-top : messages($map-type, icon-top),
            $_message-icon-left : messages($map-type, icon-left),
            $_message-icon-bottom : messages($map-type, icon-bottom),
            $_message-icon-right : messages($map-type, icon-right));
    }
}

//  Messages with inner icon
@mixin lib-message-icon-inner($_message-type: info) {

    @if $_message-type ==info {
        $map-type: $messages-default;
        @include lib-message($_message-type);
        @include _lib-message-icon-inner($_message-icon : map-get($map-type, icon),
            $_message-icon-color : map-get($map-type, icon-color-inner),
            $_message-icon-background: map-get($map-type, icon-background),
            $_message-icon-top : map-get($map-type, icon-top),
            $_message-icon-left : map-get($map-type, icon-left),
            $_message-icon-bottom : map-get($map-type, icon-bottom),
            $_message-icon-right : map-get($map-type, icon-right));
    }

    @else {
        $msg-type: $_message-type;
        $map-type: $messages-default;

        @if $msg-type ==error {
            @if variable-exists(messages-error) {
                $map-type: $messages-error;
            }
        }

        @else if $msg-type ==success {
            @if variable-exists(messages-success) {
                $map-type: $messages-success;
            }
        }

        @else if $msg-type ==warning {
            @if variable-exists(messages-warning) {
                $map-type: $messages-warning;
            }
        }

        @else if $msg-type ==notice {
            @if variable-exists(messages-notice) {
                $map-type: $messages-notice;
            }
        }

        @include lib-message($_message-type);
        @include _lib-message-icon-inner($_message-icon : messages($map-type, icon),
            $_message-icon-color : messages($map-type, icon-color-inner),
            $_message-icon-background: messages($map-type, icon-background),
            $_message-icon-top : messages($map-type, icon-top),
            $_message-icon-left : messages($map-type, icon-left),
            $_message-icon-bottom : messages($map-type, icon-bottom),
            $_message-icon-right : messages($map-type, icon-right));
    }
}

//  Private mixins
@mixin _lib-message-icon-lateral($_message-icon-position,
    $_message-icon,
    $_message-icon-color,
    $_message-icon-background,
    $_message-icon-top,
    $_message-icon-left,
    $_message-icon-bottom,
    $_message-icon-right) {
    position: relative;

    &:before {
        background: $_message-icon-background;
        width: $message-icon__lateral-width;
        content: '';
        display: block;
        height: 100%;
        padding: 0;
        position: absolute;
        text-align: center;
        top: 0;
    }

    & > *:first-child {
        &:before {
            margin-top: -$message-icon__lateral-arrow-size;
            content: '';
            overflow: hidden;
            position: absolute;
            top: 50%;
        }
    }

    > *:first-child {
        &:after {
            @include _lib-icon-font($_icon-font-content : $_message-icon,
                $_icon-font : $icon-font,
                $_icon-font-size : $message-icon__font-size,
                $_icon-font-line-height : $message-icon__font-size + 2,
                $_icon-font-color : $_message-icon-color,
                $_icon-font-margin : -$message-icon__font-size / 2 0 0,
                $_icon-font-vertical-align: $icon-font__vertical-align );
            bottom: $_message-icon-bottom;
            left: $_message-icon-left;
            right: $_message-icon-right;
            top: $_message-icon-top;
            width: $message-icon__lateral-width;
            position: absolute;
            text-align: center;
        }
    }

    @include _lib-message-icon-lateral-position($_message-icon-position,
        $_message-icon-background );
}

@mixin _lib-message-icon-lateral-position($_message-icon-position,
    $_message-icon-background) {
    @if $_message-icon-position ==right {
        padding-right: $message-icon__lateral-width + $indent__s;

        &:before {
            right: 0;
            @include _lib-message-icon-lateral-border-radius($_message-icon-position);
        }

        & > *:first-child {
            &:before {
                @include lib-arrow(left, $message-icon__lateral-arrow-size, $_message-icon-background);
                right: $message-icon__lateral-width;
            }

            &:after {
                right: 0;
                left: initial;
            }
        }
    }

    @else if $_message-icon-position ==left {
        padding-left: $message-icon__lateral-width + $indent__s;

        &::before {
            left: 0;
            @include _lib-message-icon-lateral-border-radius($_message-icon-position);
        }

        & > *:first-child {
            &:before {
                @include lib-arrow(right, $message-icon__lateral-arrow-size, $_message-icon-background);
                left: $message-icon__lateral-width;
            }

            &:after {
                left: 0;
            }
        }
    }
}

@mixin _lib-message-icon-lateral-border-radius($_message-icon-position) {
    @if $message__border-radius !=false and $message__border-width !=false {
        @if $_message-icon-position ==left {
            border-radius: ($message__border-radius - $message__border-width) 0 0 ($message__border-radius - $message__border-width);
        }

        @if $_message-icon-position ==right {
            border-radius: 0 ($message__border-radius - $message__border-width) ($message__border-radius - $message__border-width) 0;
        }
    }
}

@mixin _lib-message-icon-inner($_message-icon,
    $_message-icon-color,
    $_message-icon-background,
    $_message-icon-top,
    $_message-icon-left,
    $_message-icon-bottom,
    $_message-icon-right,
    $_message-icon-padding-left: 40px) {
    padding-left: $_message-icon-padding-left;
    position: relative;

    & > *:first-child:before {
        @include _lib-icon-font($_icon-font-content : $_message-icon,
            $_icon-font : $icon-font,
            $_icon-font-size : $message-icon__font-size,
            $_icon-font-line-height : $message-icon__font-size,
            $_icon-font-color : $_message-icon-color,
            $_icon-font-margin : (-$message-icon__font-size / 2) 0 0,
            $_icon-font-vertical-align: false);
        bottom: $_message-icon-bottom;
        left: $_message-icon-left;
        right: $_message-icon-right;
        top: 50%;
        width: $_message-icon-padding-left;
        position: absolute;
        text-align: center;
        font-style: normal;
    }
}

@mixin slick-brown-arrow {
    .slick-arrow {
        background-color: $c-brown;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        opacity: 1;

        &.slick-next,
        &.slick-prev {
            z-index: 96;
            @include icon-svg($icon: arrow,
                $fill: $arrow-fill-color !important,
                $fill-hover: $arrow-fill-color,
                $width: 13px,
                $height: 13px,
                $pseudo: before);

            &::before {
                padding: 0;
                margin-bottom: 3px;
                position: relative;
                right: 6px;
                content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="13.4" height="24" style="enable-background:new 0 0 13.4 24"><path d="m0 1.4 10.6 10.7L0 22.6 1.4 24l12-12-12-12L0 1.4z" style="fill:%23fff"/></svg>') !important;
                transform: translate(40%, -30%);
            }

        }

        &.slick-next {
            right: 45px;

            &::before {
                left: -1px;
            }
        }

        &.slick-prev::before {
            transform: rotate(180deg) translate(10%, -50%);
        }

        &.slick-prev {
            left: 20px;

            &::before {
                left: 0;
            }
        }

        &[aria-disabled="true"],
        &.slick-prev:hover,
        &.slick-next:hover {
            background: $c-light-brown;
            opacity: .8;
        }

        &:focus {
            background: $c-light-brown;
        }
    }

}

@mixin slick-brown-arrow-top {
    @include slick-brown-arrow;
    .slick-arrow {
        &.slick-next {
            top: -60px;
            right: 0;
            left: auto;
        }

        &.slick-prev {
            left: auto;
            top: -60px;
            right: 65px;

            &::before {
                left: 0;
            }
        }
    }
}

@mixin darkroom-slick-dots {
    .slick-dots {
        bottom: 24px;
        margin: auto;
        display: inline-block;
        width: auto;
        left: 0;
        right: 0;

        li {
            padding: 0 5px;

            button {
                width: 24px;
                height: 8px;
                border: 0;
                background-color: $c-gray-150;
            }

            &.slick-active {
                button {
                    background-color: $c-brown;
                }
            }
        }
    }
}

@mixin darkroom-slick-dots-hero {
    @include darkroom-slick-dots;

    &.slick-active {
        button {
            background-color: $c-white;
        }
    }
}

@mixin add-spacing {
    padding-left: 20px;
    padding-right: 20px;

    @include min-screen($screen__l2) {
        padding-left: 0;
        padding-right: 0;
    }
}