// ----------------------------
// Utility Classes for Page Builder Editor
// ----------------------------
$padding-big: 60px;
$padding-small: 30px;

.pt-big {
    //padding-top
    padding-top: $padding-big;

    @include max-screen($screen__m) {
        padding-top: $padding-small;
    }
}

.pt-sm {
    padding-top: $padding-small;
}

.pb-big {
    //padding-bottom
    padding-bottom: $padding-big;

    @include max-screen($screen__m) {
        padding-bottom: $padding-small;
    }
}

.pb-sm {
    padding-bottom: $padding-small;
}

.darkroom-eyebrow {
    @include darkroom-eyebrow;
}

.darkroom-heading-sentence {
    @include darkroom-heading-sentence($c-light-brown);
}

.module-image-text {
    @include module-image-text;
}

.darkroom-headings-h2-secondary {
    @include darkroom-headings-h2-secondary;
}

.custom-border-radius {
    border-radius: $custom-border-radius;
}

.custom-border-radius-sm {
    border-radius: $custom-border-radius-sm;
}

.add-spacing {
    @include add-spacing;
}

.slick-brown-arrow-top {
    @include slick-brown-arrow-top;
}