//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
    .price-wrapper .price {
        text-decoration: line-through;
    }
}

.special-price {
    order: 2;

    .price-final_price.weee .price {
        color: $c-red;
    }
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                font-size: 11px;
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category {
    &-view {
        margin-top: $indent__base;
    }

    &-image {
        position: relative;
        height: inherit;
        margin-bottom: 15px;

        .image {
            display: block;
            height: auto;
            max-width: 100%;
        }
    }

    &-description {
        margin-bottom: $indent__base;

        @include min-screen($screen__l) {
            max-width: 900px;
        }
    }
}

.catalogsearch-result-index,
.content-search-index,
.catalog-category-view {
    .page-title h1 {
        margin-top: 0;
        margin-bottom: $indent__s;
    }

    &.page-layout-2columns-left {
        .column.main {
            width: 100%;
        }

        .collection-filter-container {
            display: inline-block;
            width: auto;
        }

        .sidebar-main {
            display: inline-block;
            float: none;
            width: auto;
            margin-right: 16px;
            padding-right: 0;

            @include max-screen($mobile-nav__breakpoint) {
                margin-bottom: 16px;
            }

            .layered-filter-modal {
                &__button {
                    @include lib-button-reset();
                    border-radius: 8px;
                    padding: 6px 6px 6px 12px;
                    background-color: $c-gray-50;
                    border: none;
                    box-sizing: border-box;

                    @include hover {
                        background-color: $c-gray-50;
                    }
                }

                &__button-text {
                    color: $c-dark-border;
                    font-family: $font-family__secondary;
                    font-size: $font-size__base--desktop;
                    font-weight: $font-weight__semibold;
                    text-transform: none;
                    line-height: 2;
                    letter-spacing: 0.32px;

                    @include icon-svg(
                        $icon : filter,
                        $fill : $c-dark-border,
                        $width : 32px,
                        $height : 32px,
                        $pseudo : after,
                        $hideLabel : false
                    );

                    &::after {
                        margin-left: 6px;
                    }
                }
            }

            .item {
                white-space: normal;

                .label__container {
                    white-space: normal;
                }
            }

            .filter-options-title {
                background-color: transparent;
                text-transform: uppercase;
                font-size: $font-size__base--l;
                padding: 10px 0;
                position: relative;

                @include icon-svg(
                    $icon : expand,
                    $fill : $c-brown,
                    $width : 15px,
                    $height : auto,
                    $pseudo : after,
                    $hideLabel : false
                );

                &[aria-expanded="true"] {
                    @include icon-svg(
                        $icon : minus-wide,
                        $fill : $c-brown,
                        $width : 15px,
                        $height : auto,
                        $pseudo : after,
                        $hideLabel : false
                    );
                }

                &:before {
                    display: none;
                }

                &:after {
                    position: absolute;
                    right: 0;
                    top: 30%;
                }

                @include hover {
                    border: none;
                }
            }
        }
    }

    .block-title {
        &.filter-title {
            margin-bottom: 0;
        }
    }

    .filter-item {
        display: inline-block;
    }

    .page-main {
        z-index: 0;
    }

    .product-hero .product-hero__info .product-info-main {
        margin-left: 55px;
    }
}

.catalog-product-view .breadcrumbs {
    margin-bottom: $indent__m;
}

:root .catalog-product-view--infinity .product-info-main .stock {
    display: none;
}

//
//  Product images general container
//  ---------------------------------------------

.product-image {
    &-container {
        display: inline-block;
        max-width: 100%;
    }

    &-wrapper {
        display: block;
        height: 0;
        overflow: hidden;
        position: relative;
    }

    &-photo {
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        object-fit: contain;
    }
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .gallery-placeholder {
        z-index: 1;
    }

    .pdp-images {
        z-index: 99;
        position: relative;
    }

    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        color: $text__color__muted;
        font-size: $font-size__s;
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;

    .additional-attributes {
        width: auto;
        @include lib-table-resize($_th-padding-left: 0,
            $_th-padding-right: $indent__l,
            $_th-padding-bottom: $indent__s,
            $_td-padding-bottom: $indent__s );
    }
}

.product-info-main .page-title-wrapper .page-title {
    margin-bottom: $indent__m;
    font-size: 29px;

    @include min-screen($screen__m) {
        font-size: 32px;
        letter-spacing: .9px;
    }

    .attributes-title,
    .collection-title {
        + br {
            display: none;
        }
    }

    .attributes-title {
        display: block;
    }
}

.product-info-main {
    .stock {
        margin-top: 0;
        font-size: 12px;

        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                font-size: 12px;
                color: $c-vignette;

                strong {
                    &::after {
                        content: '#:';
                        font-size: 12px;
                        font-weight: $font-weight__bold;
                    }
                }

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    font-weight: $font-weight__bold;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;

                .page-product-grouped & {
                    margin: 0;

                    [itemprop="description"] {
                        display: none;
                    }
                }
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;
    }

    .qty-text {
        text-transform: uppercase;
    }

    .product-reviews-summary .reviews-actions {
        font-size: $font-size__base;
    }

    .action.towishlist,
    .request-quote {
        display: none;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            font-weight: $font-weight__bold;
            margin: 0 0 $indent__xs;
            font-size: $font-size__base;
            border: none;
            display: inline-block;
            padding: 0;
        }

        &.required,
        &._required {

            .legend,
            .infinity-fieldset-hint {
                &:after {
                    content: '*';
                    @include lib-typography($_font-size : $form-field-label-asterisk__font-size,
                        $_color : $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style : $form-field-label-asterisk__font-style );
                    margin: $form-field-label-asterisk__margin;
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }

    .fieldset {
        margin-bottom: $indent__base;

        .field {
            margin-bottom: $indent__s;
        }
    }
}

.product-add-form:focus,
.product-add-form form:focus,
.product-options-wrapper:focus,
.product-options-wrapper > .fieldset:focus,
.infinity-inputs-wrapper:focus {
    outline: none;
}

.product-info-main,
.product-options-bottom {
    .price-box {

        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            @include bryant-park-headings-h5();
            margin-bottom: $indent__xs;

            .price {
                display: inline-block;
                margin: 0;
                @include bryant-park-headings-h5();
            }
        }

        .old-price span {
            text-decoration: line-through;
        }

        .price {
            white-space: nowrap;
        }

        .price-to {
            margin-left: $indent__s;
        }

        .price-from {
            .old-price {
                float: left;
                margin-right: $indent__s;
            }
        }
    }

    .special-price {
        order: 2;

        .price-container {
            font-size: 14px;

            &.price-final_price.weee .price {
                color: $c-red;
            }
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price {
        margin-right: $indent__s;
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart .action.tocart:not(.pdp-cta-box__button),
    .bundle-actions .action.customize {
        @include lib-button-l();
        min-width: $tocart-min-width;
        padding: 14px 17px;
        font-size: 16px;
    }

    .product-add-form {
        margin-bottom: $indent__base;
    }

    .order-a-custom-rug {
        border-top: 1px solid $c-vignette;
        margin-top: 24px;
        margin-bottom: 12px;
        padding-top: 24px;

        .order-a-custom-rug-bt-text {
            display: flex;
            top: 14px;
            padding-left: 24px;
            justify-content: center;
        }

        .box-tocart {
            padding: 0;
            margin: 0 0 $indent__s;
        }

        .custom-rug-style-info {
            font-size: 15px;
        }

        .action.primary::after {
            background: $c-gray-600;
        }

        .action.primary {
            background: $c-gray-400;
            height: 50px;
            padding: 2px 47px;
            width: 100%;
        }
    }

    .box-tocart {
        width: 100%;
        margin: 12px 0;
        padding: 0;

        .field.qty {
            margin-bottom: $indent__l;
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            $tocart-input-size: $form-element-input__height;
            height: $tocart-input-size + 2px;
            text-align: left;
            width: 70px;
        }

        .action.tocart {
            width: 100%;
        }

        > .fieldset > .actions:not(:first-child) {
            margin-top: 15px;
        }
    }

    .product-addto-links {
        margin: 0;
        margin-top: $indent__xs;

        .wishlist.split.button {
            display: inline-block;
            margin-right: 0;
            margin-bottom: $indent__s;
            z-index: 2; // Ensures that the other product card items do not conflict with the Add to Project dropdown

            @include hover {
                button {
                    color: $link__hover__color;
                }
            }

            .items {
                margin-top: 0;
                min-width: 250px;
                max-width: 300px;
            }

            .action.split {
                padding: 0;
                text-decoration: underline;
                border: none;
            }

            .action.toggle {
                padding: 0;
                border: none;
            }
        }

        .block-requisition-list {
            display: block;
        }

        .requisition-list-action {
            text-decoration: underline;

            @include min-screen($screen__m) {
                padding-left: 0;
            }
        }
    }

    .storelocator {
        flex-shrink: 0;
    }

    .action.tocompare {
        display: inline-block;
        margin-left: ($indent__base * .75);
        padding: 5px 12px;
        vertical-align: middle;
        text-decoration: none;
        border: $border-width__base solid $c-secondary__color;
    }
}

.product-options-bottom {
    margin-bottom: 15px;

    .actions button {
        font-size: 16px;
        height: 50px;
    }
}

.request-quote p {
    margin-bottom: 0;
}

.product-tooltip {
    @include lib-tooltip($_tooltip-position: bottom,
        $_tooltip-selector-toggle: '.tooltip-toggle',
        $_tooltip-selector-content: '.tooltip-content',
        $_tooltip-background: $color-white,
        $_tooltip-border-color: $secondary__color,
        $_tooltip-border-width: 1px,
        $_tooltip-border-radius: inherit,
        $_tooltip-color: $text__color,
        $_tooltip-cursor: help,
        $_tooltip-font-size: inherit,
        $_tooltip-font-family: inherit,
        $_tooltip-font-weight: inherit,
        $_tooltip-line-height: 1.4,
        $_tooltip-font-style: inherit,
        $_tooltip-margin: inherit,
        $_tooltip-padding: 12px 16px,
        $_tooltip-min-width: 210px,
        $_tooltip-max-width: 330px,
        $_tooltip-arrow-size: 5px,
        $_tooltip-arrow-offset: 31px,
        $_tooltip-z-index: 100);
    margin-left: 5px;

    @include icon-svg($icon: info,
        $fill: $text__color,
        $width: 15px);

    @include hover {
        @include icon-svg($icon: info,
            $fill: $primary__color,
            $width: 15px);
    }

    @include min-screen($screen__xxs) {
        @include lib-tooltip($_tooltip-position: right,
            $_tooltip-selector-toggle: '.tooltip-toggle',
            $_tooltip-selector-content: '.tooltip-content',
            $_tooltip-background: $color-white,
            $_tooltip-border-color: $secondary__color,
            $_tooltip-border-width: 1px,
            $_tooltip-border-radius: inherit,
            $_tooltip-color: $text__color,
            $_tooltip-cursor: help,
            $_tooltip-font-size: inherit,
            $_tooltip-font-family: inherit,
            $_tooltip-font-weight: inherit,
            $_tooltip-line-height: 1.4,
            $_tooltip-font-style: inherit,
            $_tooltip-margin: inherit,
            $_tooltip-padding: 12px 16px,
            $_tooltip-min-width: 210px,
            $_tooltip-max-width: 330px,
            $_tooltip-arrow-size: 5px,
            $_tooltip-arrow-offset: 23px,
            $_tooltip-z-index: 100);
    }

    .tooltip-content {
        min-height: 45px;
        box-sizing: border-box;
        transform: translateY(-5px) translateX(-27px);

        @include min-screen($screen__xxs) {
            transform: translateY(-15px) translateX($indent__xs);
        }

        &::before,
        &:after {
            @include min-screen($screen__xxs) {
                left: 0;
                transform: translateX(-6px) translateY(-5px);
            }
        }

        &::before {
            @include min-screen($screen__xxs) {
                border-right-color: $secondary__color;
            }
        }

        &::after {
            @include min-screen($screen__xxs) {
                top: 27px;
                border-width: 6px;
            }
        }
    }

    a span {
        @include lib-visually-hidden();
    }
}

.prices-tier {
    @extend .abs-reset-list;
    background: $sidebar__background-color;
    margin: $indent__s 0;
    padding: $indent__s (
        .75 * $indent__base
    );
padding-left: 0; // left align with column data

.price-container {
    display: inline-block;
}

.price-including-tax,
.price-excluding-tax,
.weee {
    display: inline-block;

    .price {
        font-size: 14px;
        font-weight: $font-weight__bold;
    }
}
}

.metric-switcher {
    button {
        @include lib-button-reset();
        @include button--inline-link();
        font-size: $font-size__base;
        line-height: 1.8;
        color: $c-gray-400;
        text-decoration: underline;

    }

    &--layered-nav {
        padding-bottom: $indent__base;
        padding-left: $indent__xs;
    }
}

.ui-dialog-titlebar-close {
    @include button--inline-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

.storelocator a {
    @include button--tertiary();
}

:root .product-addto-links__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: $indent__base;

    button,
    .requisition-list-action .action.toggle,
    .wishlist.split.button {
        &::after {
            content: none;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;

            &-info {
                position: relative;
                width: auto;

                .product-item-photo {
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }

            &-name {
                margin-top: 0;
            }

            &-details {
                margin: 0 0 0 85px;
            }

            &-actions {
                display: block;
                margin-top: $indent__s;
            }
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            color: $primary__color__lighter;
            font-size: 12px;
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        display: none;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @include lib-clearfix();
        margin-left: -$indent__xs;

        .product-item {
            float: left;
            padding-left: $indent__xs;
            box-sizing: border-box;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
// Infinity PDP
//  ---------------------------------------------
.catalog-product-view--infinity {
    .product-options-wrapper .fieldset {
        margin-bottom: 15px;
    }

    .roll-width {
        font-weight: $font-weight__bold;
        padding-bottom: $indent__s;
        font-size: $h5__font-size-desktop;
    }

    .box-tocart .action.tocart {
        padding: 0 17px;
        line-height: 1;
    }
}

.infinity-inputs-wrapper {
    div[data-role="title"] {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 50px;
        max-height: 64px;
        line-height: 1;
        box-sizing: border-box;
        background-color: $c-feng-shui;
        color: $color-white;
        padding-left: 40px;
        font-size: $h5__font-size-desktop;
        font-weight: $font-weight__semibold;
        cursor: pointer;

        &::before {
            position: absolute;
            left: 12.5px;
        }

        @include icon-svg($icon : expand,
            $fill : $color-white,
            $width : 15px,
            $height : auto,
            $hideLabel : false);

        &[aria-expanded="true"] {
            @include icon-svg($icon : minus-wide,
                $fill : $color-white,
                $width : 15px,
                $height : auto,
                $hideLabel : false);
        }

        div[data-element="inner"] {
            margin: 0 !important; // Override PageBuilder styles
            padding: 0 !important; // Override PageBuilder styles
        }

        .infinity-inputs-header p {
            margin: 0;
            line-height: 1.25;
        }
    }

    [role="tabpanel"] {
        @include max-screen($screen__m) {
            padding-left: 0 !important; // Override PageBuilder
        }
    }

    .control {
        max-width: none;
    }

    .infinity-fieldset-head {
        font-size: $h5__font-size-desktop;
        text-transform: none;
        letter-spacing: .46px;
        padding-left: 28px;
        margin-bottom: 12px;
        position: relative;

        &::before {
            display: inline-block;
            position: absolute;
            left: 0px;
            padding: 2px;
            background-color: $c-feng-shui;
            color: $color-white;
            font-size: $font-size__base--desktop;
            font-weight: $font-weight__bold;
            text-align: center;
            vertical-align: top;
            border-radius: 50px;
            line-height: 17px;
            height: 17px;
            width: 17px;
        }

        [data-element="inner"] {
            padding-top: 0 !important; // override PageBuilder

            p {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    div[data-role="content"] {
        display: none;
        padding-left: 20px;
        $infinity-counter-name: infinity;
        counter-reset: $infinity-counter-name;

        .infinity-fieldset-head {
            counter-increment: $infinity-counter-name;
        }

        .infinity-fieldset-head::before {
            content: counters($infinity-counter-name, '', decimal);
        }

        .product-options-bottom .box-tocart {
            margin-top: 0;

            .fieldset {
                margin: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    // Dimensions
    .size-inputs-block {
        width: auto;

        .field {
            display: inline-block;
            margin: 0;
        }
    }

    .dimension-wrapper {
        display: flex;

        .control:first-of-type {
            padding-right: 20px;
        }

        label {
            display: inline;
            font-size: $font-size__base;
        }

        input {
            width: 90px;
        }
    }

    .fieldset-product-options-inner {
        background-color: $c-berber;
        padding: 22px 22px 20px;
    }

    input.valid,
    input.mage-error {
        background-color: $color-white;
    }

    input.valid {
        border-color: $form-element-input__border-color;
    }
}

// Choose a shape
.infinity-shape--wrapper .shape_swatch,
.infinity-shape--wrapper .swatch-opt {
    margin: 0;
}

.infinity-shape--wrapper,
.infinity-size--wrapper,
.finishing-swatch-wrapper {
    flex-direction: column;
}

.shape_swatch > div {
    display: flex;
    flex-wrap: wrap;

    .swatch-option {
        @include button--selectable-swatch();

        margin: 0 10px 10px 0;
        padding: 5px;
        background-color: $color-white;
        border: 1px solid $c-vignette;
        border-radius: 4px;
        width: 240px;
        max-width: calc(50% - 32px);
        text-align: left;
        text-transform: uppercase;
        font-weight: $font-weight__bold;

        &.image {
            line-height: initial;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: row-reverse;
        }

        /* Image */
        div:not(.swatch-option-label) {
            background-size: contain !important;
            /* overrides inline style */
            height: 45px !important;
            /* overrides inline style */
            width: 45px !important;
            /* overrides inline style */
        }
    }
}

.infinity-fieldset-hint {
    display: block;
    margin-bottom: 15px;
    color: $text__color;
    font-weight: $font-weight__light;
}

// Finishing touches
.finishing-swatch-wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    left: -5px;

    @include min-screen($screen__m) {
        flex-direction: row;
    }

    &.swatch-opt {
        margin-bottom: 0;
    }

    .swatch-attribute-options {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .finishing {
        margin-bottom: 0;
    }

    .swatch-option.image {
        @include button--selectable-swatch();

        margin: 5px;
        max-width: none;
        cursor: pointer;
        background-color: $color-white;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid $c-vignette;
        border-radius: 4px;
        text-align: left;
        line-height: 1.2;
        font-size: 12px;
        float: right;
        height: 110px !important;
        width: 110px !important;
        background-size: 70px !important;

        .swatch-option-label {
            float: left;
            margin-right: 8px;
            width: calc(100% - 122px);
            /* 110px for image, 8+2+2px for margin */
        }

        /* Image */
        div:not(.swatch-option-label) {
            float: right;
            height: 70px !important;
            /* override inline styles */
            width: 70px !important;
            /* override inline styles */
            background-size: 70px !important;
            /* override inline styles */
        }
    }

    .finishing-swatch {
        @include button--selectable-swatch();
        margin: $indent__xs;
        width: 100%;
        cursor: pointer;
        background-color: $color-white;
        padding: $indent__s;
        box-sizing: border-box;
        border: 1px solid $c-vignette;
        border-radius: 4px;
        line-height: 1.2;
        font-size: 12px;

        span {
            float: left;
            margin-right: 8px;
            width: calc(100% - 122px);
            /* 110px for image, 8+2+2px for margin */
        }

        img {
            float: right;
            height: 110px;
            width: 110px;
        }

        [data-element="inner"] {
            padding-left: 0 !important; // override PageBuilder
        }
    }
}

// Protection
.infinity-protection--wrapper {
    .protection-option .protection-label {
        margin: 10px 0px;
        font-style: normal;
        font-weight: $font-weight__light;
        font-family: inherit;
        font-size: $font-size__base--desktop;
        color: inherit;
    }

    input[type="checkbox"] {
        display: none;
    }
}

.infinity-size--wrapper {
    position: relative;
    margin-top: $indent__l;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    left: -5px;

    &.swatch-opt {
        margin-bottom: 0;
    }

    .swatch-attribute-options {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .finishing {
        margin-bottom: 0;
    }

    .swatch-option.image {
        @include button--selectable-swatch();

        margin: 5px;
        max-width: none;
        cursor: pointer;
        background-color: $color-white;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid $c-vignette;
        border-radius: 4px;
        text-align: left;
        line-height: 1.2;
        font-size: 12px;
        float: right;
        height: 110px !important;
        width: 110px !important;
        background-size: 70px !important;

        .swatch-option-label {
            float: left;
            margin-right: 8px;
            width: calc(100% - 122px);
            /* 110px for image, 8+2+2px for margin */
        }

        /* Image */
        div:not(.swatch-option-label) {
            float: right;
            height: 70px !important;
            /* override inline styles */
            width: 70px !important;
            /* override inline styles */
            background-size: 70px !important;
            /* override inline styles */
        }
    }

    .finishing-swatch {
        @include button--selectable-swatch();

        margin: 5px;
        width: calc(50% - 10px);
        cursor: pointer;
        background-color: $color-white;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid $c-vignette;
        border-radius: 4px;
        line-height: 1.2;
        font-size: 12px;

        span {
            float: left;
            margin-right: 8px;
            width: calc(100% - 122px);
            /* 110px for image, 8+2+2px for margin */
        }

        img {
            float: right;
            height: 110px;
            width: 110px;
        }
    }

    .infinity-fieldset-hint {
        margin-bottom: 0;
    }

    .field {
        padding-right: $indent__base;

        &:last-child {
            padding-right: $indent__s;
        }

        .label {
            margin-top: 15px;
            font-size: $font-size__base;
        }
    }

    .product-tooltip {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.infinity-calculate {
    @include button--selectable-swatch();

    width: 100%;
    border-radius: 4px;
    border: 1px solid $c-houndstooth;

    span {
        font-size: 14px;
        letter-spacing: 1.5px;
    }

    @include hover {
        border-width: 1px;
        background-color: inherit;
    }

    &.selected {
        font-size: 14px;
        color: $c-houndstooth;
        letter-spacing: 1.5px;
        opacity: 1;
    }
}

.fifty-to-infinity-description-image {
    margin: 15px 0;
    width: 200px;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column.main {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .product.media {
            order: -1;
        }

        .product-info-main {
            width: 100%;
            max-width: none;

            .infinity-inputs-wrapper div[data-role="content"] {
                padding: 0;
            }

            .product-options-bottom {
                max-width: none;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }

    .infinity-inputs-wrapper {
        div[data-role="title"] {
            font-size: 14px;
        }

        .infinity-fieldset-head {
            font-size: $tab-control__font-size;
            letter-spacing: $letter-spacing__base;
        }

        .fieldset-product-options-inner {
            padding: 20px 10px;
        }
    }
}

@include max-screen($screen__s) {
    .finishing-swatch-wrapper .finishing-swatch {
        width: calc(100% - 10px);
    }
}

@include max-screen($screen__m) {
    .finishing-swatch-wrapper .finishing-swatch {
        width: calc(50% - 10px);
    }
}

@include max-screen($screen__l) {
    .finishing-swatch-wrapper .finishing-swatch {
        width: calc(100% - 10px);
    }
}

@include min-screen($screen__l) {
    .finishing-swatch-wrapper .finishing-swatch {
        width: calc(50% - 10px);
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__xs) {

    .product-info-main,
    .product-options-bottom {
        max-width: 460px;
    }

    .catalog-product-view--infinity .product-info-main,
    .catalog-product-view--infinity .product-options-bottom {
        max-width: none;
    }

    .product-options-bottom .box-tocart button {
        width: 100%;
    }
}

@include min-screen($screen__m) {

    .product-info-main,
    .product-options-bottom {
        width: 100%;

        .box-tocart {
            display: table;
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        float: left;
                        left: auto;
                        margin: 0 $indent__s $indent__s 0;
                        position: relative;
                        top: auto;
                    }
                }

                &-details {
                    margin: 0;
                }

                &-actions {
                    clear: left;
                }
            }
        }
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0;
                    }
                }

                &-details {
                    margin-left: 85px;
                }
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
    }

    .page-layout-1column {
        .product-info-main {
            width: 40%;
        }

        .product.media {
            width: 57%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }
}

.plp-images {
    position: relative;
    text-align: end;
    z-index: 99;
    display: none;
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @include lib-visually-hidden();
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            font-size: 13px;

            img {
                height: auto;
                max-width: 100%;
            }
        }

        &.remove {
            padding-bottom: 0;
            padding-top: 0;
            text-align: right;

            .action.delete {
                @extend .abs-remove-button-for-blocks;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    background: $page__background-color;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @include lib-button-revert-secondary-color();
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

@for $i from 1 through $products-per-page {
    .products.list .item:nth-child(#{$i}) .product-image-photo.ls-animate {
        transition-delay: (50ms * $i);
    }
}

// Broadloom products
.fieldset.broadloom--wrapper {
    background-color: $c-light-grey;
    margin-top: $indent__m;
    border-radius: 12px;
    border: 1px solid $pdp-login-border__color;
    background: $pdp-login-background__color;

    .broadloom-custom-size--container {
        padding: $indent__s 15px $indent__m 15px;

        &::before {
            display: none;
        }

        .roll-width span {
            font-weight: $font-weight__bold;
            padding-bottom: $indent__s;
            font-size: $h5__font-size-desktop;
        }

        .secondary a {
            padding-top: $indent__s;
            font-size: $font-size__base;

            &.infinity-cta {
                display: block;
                padding-top: $indent__base;
                font-size: $font-size__base--desktop;
                font-weight: $font-weight__semibold;
            }
        }

        .instruction {
            margin-top: $indent__s;
            margin-bottom: 0;

            label {
                font-size: $font-size__base;
            }
        }
    }

    .controls-wrapper {
        display: flex;

        .field {
            display: flex;
            align-items: baseline;

            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }

        .control {
            max-width: 85px;
        }

        .label {
            margin-left: $indent__xs;
        }
    }
}

$slick-dot__inactive__background-color: #ddd;
$slick-dot__active__background-color: $color-black;

.catland-slider {
    margin-bottom: $indent__xl;

    .pagebuilder-overlay,
    .pagebuilder-slide-wrapper {
        background-size: contain !important; // override PageBuilder
    }

    .pagebuilder-slider,
    .pagebuilder-slide-wrapper,
    .pagebuilder-overlay {
        min-height: 100px !important; // override PageBuilder

        @include min-screen($screen__s) {
            min-height: 140px !important; // override PageBuilder
        }

        @include min-screen($screen__m) {
            min-height: 170px !important; // override PageBuilder
        }

        @include min-screen($screen__xl) {
            min-height: 270px !important; // override PageBuilder
        }
    }
}

.slick-slider {
    .slick-dots {
        position: relative;

        li {
            border-radius: 50%;
            margin: 0 $indent__s;

            button::before {
                display: none;
            }
        }
    }

    .slick-arrow {
        @include lib-button-reset();
        height: 21px;

        @include icon-svg($icon: arrow,
            $width: 21px,
            $height: 21px,
            $fill: $c-scorpion,
            $fill-hover: $c-heavy-metal,
            $hideLabel: false,
            $pseudo: before,
        );

        &::before {
            position: relative;
        }

        &.slick-prev::before {
            transform: scaleX(-1);
        }
    }
}

// POPUP MODAL on PDP
.modal-popup.dealer-locator-popup .modal-inner-wrap {
    width: 80%;
    height: 80%;
}


@media only screen and (max-width: 880px) {
    .header {
        .minicart-wrapper {
            min-width: 50px;
        }
    }
}

//
//  PDP CTA and Text Content Box
//  ---------------------------------------------
.pdp-cta-box {
    &__wrapper {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        border-radius: 12px;
        border: 1px solid $pdp-login-border__color;
        background: $pdp-login-background__color;

        .actions {
            width: 100%;
        }
    }

    &__button {
        width: 100%;
        padding: 13px 23px;
        font-size: $font-size__base;
        line-height: $letter-spacing__pdp-description-button;

        > span {
            font-size: $font-size__base;
            font-weight: $font-weight__semibold;
            line-height: $letter-spacing__pdp-description-button;
            letter-spacing: .56px;
        }
    }

    &__title {
        color: $c-pdp-description-title;
        font-family: $font-family__base;
        font-size: $font-size__pdp-sku;
        font-weight: $font-weight__regular;
        line-height: $letter-spacing__pdp-description-title;
        letter-spacing: -.48px;
    }

    &__description {
        color: $c-gray-400;
        font-family: $font-family__secondary;
        font-size: $font-size__base;
        font-weight: $font-weight__regular;
        line-height: $letter-spacing__pdp-description;
        margin: 8px 0 0;
    }
}
