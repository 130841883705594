// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type='button-item'] {
    max-width: 100%;
    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }
    [data-element='empty_link'] {
        cursor: default;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        @include lib-button-as-link(
            $_padding: $button__padding__l
        );
        margin: 0 10px 10px 0;
        font-weight: $font-weight__semibold;
        box-shadow: none;
        box-sizing: border-box;
        display: inline-block;
    }

    &.pagebuilder-button-primary {
        @include lib-link-as-button();
        @include lib-button-primary($_button-margin: 0 10px 10px 0);
        border-radius: $button__border-radius;
        box-shadow: none;
    }

    &.pagebuilder-button-secondary {
        @include lib-link-as-button();
        @include lib-button($_button-margin: 0 10px 10px 0);
        border-radius: $button__border-radius;
        box-shadow: none;
    }
}
