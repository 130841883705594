$contact-info__background-color: #f3f5f6;
$contact-info-text__font-size: 16px;

.contact-us-form {
    box-sizing: border-box;
    width: 100%;
    float: left;

    @include min-screen($screen__xs) {
        max-height: 800px;
    } 

    @include min-screen($screen__m) {
        width: 50%;
    }
}

.contact-us-banner {
    .pagebuilder-overlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .pagebuilder-banner-wrapper {
        max-height: 335px;
    }

    h1 {
        font-size: $font-size__banner;

        @include min-screen($screen__m) {
            font-size: $font-size__banner--desktop;
        }
    }
}

.contact-us-info {
    width: 100%;
    box-sizing: border-box;

    @include min-screen($screen__m) {
        margin-right: 35px;
        width: calc(50% - 35px);
        float: left;
    }

    .pagebuilder-column-group {
        flex-direction: column;

        .pagebuilder-column {
            width: 100% !important; //override pagebuilder styles
            flex-direction: row !important; //override pagebuilder styles

            p {
                margin: 0;
            }
        }
    }

    .info-title {
        width: 100%;
        height: 15px;
        border-bottom: 1px solid $c-border;
        text-align: left;
        margin-right: $indent__base;

        p {
            font-size: $contact-info-text__font-size;
            background-color: $contact-info__background-color;
            padding: 0 $indent__base 0 0;
            z-index: 1; //keep title above background line element
            display: inline;
            text-transform: uppercase;

            strong {
                font-weight: $font-weight__semibold;
            }
        }
    }
}