$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background: $panel__background-color !default;
$toolbar-element-background--active: $toolbar-element-background !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }

    &.page-layout-2columns-left {
        .sidebar-main {
            width: calc(234px + 2%); // Design calls for 234px width sidebar + padding of 2%
        }

        .column.main {
            width: calc(100% - (234px + 2%)); // 100% - sidebar width and sidebar padding
        }
    }
}

.toolbar {
    @include lib-clearfix();

    &-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @include min-screen($screen__s) {
            justify-content: space-between;
        }
    }
}

.toolbar-amount {
    float: left;
    display: block;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    padding: 0;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.toolbar-products {
    @include lib-clearfix();
    margin-bottom: $indent__xl;
    padding: 0;
    padding-top: 15px;
    text-align: center;

    @include min-screen($screen__xs) {
        padding-right: $indent__s;
        padding-left: $indent__s;
    }

    @include max-screen($screen__m) {
        padding-top: $indent__s;
    }

    &__top {
        padding: 0;
        margin-top: 0;

        @include min-screen($screen__m) {
            margin-top: 16px;
        }
    }

    .pages {
        .products.wrapper ~ & {
            display: block;
        }
    }
}

// Hide "naked" limiters - generally only used on account pages...
.limiter {
    // ...But show limiters used elsewhere
    background-color: $c-gray-50;
    border: 1px solid $c-gray-150;
    border-radius: $custom-border-radius-sm;
    padding: 0 8px;
    font-weight: $font-weight__semibold;

    label {
        font-weight: $font-weight__semibold;
    }

    @include min-screen($screen__s) {
        &.field {
            padding-top: 0;
        }
    }

    .control {
        display: inline-block;
        margin-left: $indent__s;
        margin-right: $indent__s;
    }
}

.sorter {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    clear: both;
    border: 1px solid $c-gray-150;
    border-radius: $custom-border-radius-sm;
    font-weight: $font-weight__semibold;

    @include min-screen($screen__xs) {
        justify-content: flex-end;
        clear: none;
        padding-top: 0;
    }

    label {
        font-size: $font-size__base--desktop;
        padding-left: 12px;
        min-width: 65px;
        font-weight: $font-weight__semibold;

        &::after {
            content: ":";
            margin-right: $indent__xs;
        }
    }

    .toolbar--bottom & {
        display: none;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.sorter-options {
    margin: 0;
    border: transparent;
    padding: 0;
    height: 45px;
    max-width: unset;
    font-weight: $font-weight__semibold;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Cpath stroke='%230F0E0D' stroke-linecap='round' stroke-width='2' d='m10 13 6 6 6-6' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 32px 32px;
    -webkit-appearance: none;
    -moz-appearance: none;

    @include hover {
        border: transparent;
        outline: transparent;
    }
}

.sorter-action {
    display: flex;
    align-items: center;
    min-height: $form-element-input__height;
    vertical-align: top;
    min-height: 45px;

    @include icon-svg($icon : sort-ascend,
        $fill : $c-medium-grey,
        $fill-hover : $header-icons-color-hover,
        $width : 35px);

    &.sort-desc {
        @include icon-svg($icon : sort-desc,
            $fill : $c-medium-grey,
            $fill-hover : $header-icons-color-hover );
    }
}

.limiter-options {
    position: relative;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 60px;
    z-index: 10;
    border: transparent;
    font-weight: $font-weight__semibold;

    @include hover {
        border: transparent;
        outline: transparent;
    }
}

.limiter-label,
.limiter-text {
    font-weight: $font-weight__regular;
}

.modes {
    display: block;
    float: left;
    margin-right: $indent__base;
    margin-top: $indent__xs;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.modes-label {
    @include lib-visually-hidden;
}

.modes-mode {
    @include icon-svg($icon : grid,
        $fill : $secondary__color,
        $width : 19px);
    color: $text__color__muted;
    border: 1px solid $secondary__color;
    border-right: 0;
    float: left;
    font-weight: $font-weight__regular;
    line-height: 1;
    padding: 7px 10px;
    text-align: center;
    background-color: $toolbar-element-background;

    &:last-child {
        border: 1px solid $secondary__color;
    }

    &:not(.active) {
        @include hover {
            color: $text__color__muted;
            background: $toolbar-element-background--active;
        }
    }

    &.active {
        color: $primary__color__light;
        background-color: $color-white;
    }

    .toolbar--bottom & {
        display: none;
    }
}

.mode-list {
    @include icon-svg($icon : list,
        $fill : $text__color__muted );
}

.toolbar--top {

    .pages,
    .limiter {
        display: none;
    }
}

.toolbar--bottom {
    .pages {
        float: right;
        margin-left: 20px;
    }
}

.toolbar-wrapper .pages-items {
    white-space: normal;
}

.pagination-wrapper {
    display: flex;
}

.first-page__container {
    display: none;

    a {
        @include icon-svg($icon: double-arrow,
            $width : 15px,
            $height : 18px,
            $fill : $link__color,
            $fill-hover : $link__hover__color,
            $pseudo : before,
            $hideLabel : true,
            $lineHeight : 1);
        display: inline-block;
        margin-top: 30px;
        margin-right: $indent__l;
    }
}


//
//  Desktop
//  _____________________________________________
@include min-screen($screen__xs) {
    .limiter {
        width: 100%;
    }
}

@include min-screen($screen__s) {
    .pagination-wrapper {
        order: 2;
    }

    .limiter {
        order: 1;
        width: auto;
        padding-top: $indent__m;
    }
}

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            float: left;
            color: $c-gray-300;
        }

        .pages-items {
            white-space: nowrap;
        }
    }

    .toolbar-wrapper {
        justify-content: space-between;
    }

    .toolbar-amount {
        float: left;
        color: $c-gray-300;
    }

    .sorter {
        float: right;
        margin: 0;

        .sorter-options {
            min-width: 210px;
        }
    }

    .limiter {
        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }
}
