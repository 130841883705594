//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin             : $indent__base !default;
$checkout-wrapper__width              : 59.66666667% !default;
$checkout-sidebar__width              : 40.3333333% !default;

$checkout-step-title__border          : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size       : 26px !default;
$checkout-step-title__font-weight     : $font-weight__light !default;
$checkout-step-title__padding         : 18px !default;

$checkout-step-title-mobile__font-size: $font-size__base--desktop !default;

//
//  Common
//  _____________________________________________

.checkout-index-index,
.checkout-onepage-success {
    .page-title-wrapper {
        @include lib-visually-hidden();
    }

    .modal-footer .action-save-address {
        margin-top: $indent__base;
    }
}

.payment-method {
    &-braintree {
        .field-tooltip {
            .field-tooltip-content {
                right: 0;
                transform: translateX(10px);

                @include max-screen($screen__m) {
                    top: 45px;
                    left: auto;
                }

                &:before,
                &:after {
                    @include max-screen($screen__xs) {
                        @include lib-tooltip(top);
                        position: absolute;
                    }
                }
            }
        }
    }
}

.checkout-container {
    @include lib-clearfix();
    margin: 0 0 $checkout-wrapper__margin;
}

.opc-wrapper {
    margin: 0 0 $checkout-wrapper__margin;

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }

    /**
     * The use of .control on the checkout addresses container is a
     * misuse of it – it's typically for form fields. We have a max-width
     * set on the class, and here we're removing it so the addresses aren't
     * scrunched up.
     */
    .addresses > .control {
        max-width: none;
    }
}

.checkout-index-index {
    .header.content {
        padding-top: 15px;
        padding-bottom: $indent__xs;
    }

    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.checkout-payment-method .payment-group .step-title:after {
    content: ':';
}

.checkout-onepage-success .actions-toolbar {
    margin-top: $indent__m;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            border-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__width);
        padding-right: $indent__l;
    }
}

@include min-screen($screen__m) {
    .opc-sidebar {
        @include lib-layout-column(2, 2, $checkout-sidebar__width);
    }
}


