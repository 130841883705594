.crazy-image-grid {
    display: block !important; // override PageBuilder
    margin: $indent__s auto $indent__xl auto;
    position: relative;

    @include min-screen($screen__m) {
        max-height: 1000px !important; // override PageBuilder
        overflow: hidden;
    }

    img {
        width: 100%;
    }

    [data-content-type="banner"] {
        width: 100% !important; // override PageBuilder
    }

    [data-content-type="buttons"] {
        @include max-screen($screen__m) {
            [data-content-type="button-item"] {
                width: 100%;
            }
        }

        @include min-screen($screen__m) {
            margin-top: 0;
            position: absolute;
            left: 75%;
            transform: translateX(-50%);
            bottom: 7%;
        }
    }

    .pagebuilder-column-group {
        display: block !important; // override PageBuilder
    }

    .pagebuilder-column {
        margin: 0 !important; // override PageBuilder
        float: left;

        @include max-screen($screen__m) {
            margin: 0 !important; // override PageBuilder
            padding: 0 !important; // override PageBuilder
            width: 100% !important; // override PageBuilder
        }

        .pagebuilder-poster-overlay span {
            font-weight: $font-weight__semibold;
            font-size: $font-size__base--desktop;
            letter-spacing: 2px;
            color: $color-white;
            padding: 0;
        }

        > a {
            @include image-overlay();

            @include hover {
                &::before {
                    opacity: .5;
                }
            }
        }
    }

    @include min-screen($screen__m) {
        display: block;

        .pagebuilder-column {
            padding: 0 !important; //override PageBuilder

            &:first-of-type,
            &:nth-of-type(2) {
                width: 25% !important; // override PageBuilder
                align-self: flex-start !important; // override PageBuilder
            }

            &:nth-of-type(3) {
                width: 50% !important; // override PageBuilder
                clear: both;
            }

            &:last-of-type {
                width: 50% !important; // override PageBuilder
                display: inline-block !important; // override PageBuilder
                float: none;
                align-self: flex-start !important; // override PageBuilder

                @include min-screen($screen__m) {
                    .pagebuilder-banner-wrapper,
                    [data-content-type="banner"] a::before {
                        min-height: 500px !important; // override PageBuilder
                    }
                }
            }
        }
    }
}
