/*doc
---
title: Typography
name: Typography
category: Style-Guide - Typography
---

###Body Text
```html_example
  <p>I am a paragraph and sometimes I have <a href="#">links inside</a>. Let me know what you think about my style and any and all feedback is greatly appreciated like this amazing run-on setence that I am making you read at this very moment.</p>
  <p>I am a paragraph and sometimes I have <a href="#">links inside</a>. Let me know what you think about my style and any and all feedback is greatly appreciated like this amazing run-on setence that I am making you read at this very moment.</p>
```

##Headings

```html_example
  <h1>This is an H1</h1>
  <span class="product-info-main"><span class="page-title-wrapper"><h1 class="page-title">This is an PDP H1</h1></span></span>
  <h2>This is an H2</h2>
  <h3>This is an H3</h3>
  <h4>This is an H4</h4>
  <h5>This is an H5</h5>
  <h6>This is an H6</h6>
```
#### H2

```html_example
  <h2> I am a Heading 2 </h2>
```
#### H3

```html_example
  <h3> I am a Heading 3 </h3>
```
#### H4

```html_example
  <h4> I am a Heading 4 </h4>
```
#### H5

```html_example
  <h5> I am a Heading 5 </h5>
```

#### H6

```html_example
  <h6> I am a Heading 6 </h6>
```

*/

@include bryant-park-typography-all();

body {
    font-weight: $font-weight__base;
    line-height: $line-height__base;
    letter-spacing: $letter-spacing__base;

    @include min-screen($screen__m) {
        line-height: $line-height__base--desktop;
        letter-spacing: $letter-spacing__base--desktop;
    }
}

p {
    margin-bottom: 15px;
}

h1,
.h1 {
    @include darkroom-headings-h1;
}

h2,
.h2 {
    @include darkroom-headings-h2;
}

h3,
.h3 {
    @include darkroom-headings-h3;
}

h4,
.h4 {
    @include darkroom-headings-h4;
}

h5,
.h5 {
   @include darkroom-headings-h5;
}

h6,
.h6 {
   @include darkroom-headings-h6;
}

// Adjusts the PDP h1 wrapper to fit in with the rest of the headings
.exampleOutput .product-info-main {
    float: none;
}
