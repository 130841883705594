/**
 * Styles for a hide/show password in plain text
 * toggle checkbox. Currently used on the
 * new account registration page
 */

// Brittle. but necessary
$password-toggle-width: 150px;

.password-input .control {
  position: relative;
}

.password-input .js-toggle-type {
  /**
   * Making room for our password toggle, which will be displayed
   * on top of this input
   */
  padding-right: $password-toggle-width;
}

.password-toggle {
  @include button--inline-link;

  width: $password-toggle-width;
  position: absolute;
  right: $indent__s;
  padding: $form-element-input__padding;
  top: ($form-element-input__height/2) - $button__font-size + 2;
  text-align: center;
  box-sizing: border-box;
}

.password-toggle__text {
  border-bottom: solid 2px;
  width: auto;
  display: inline-block;
  color: $link__color;

  @include hover {
    color: $link__hover__color;
  }
}

.password-toggle__text--hide {
  display: none;
}

.password-toggle[aria-pressed="true"] {
  .password-toggle__text--show {
    display: none;
  }

  .password-toggle__text--hide {
    display: inline-block;
  }
}
