@include min-screen($mobile-nav__breakpoint) {
    .mobile-nav-bar {
        display: none !important;
    }
}

.mega-menu {
    z-index: 100;

    ul {
        padding: 0;
    }

    p {
        margin: 0;
    }

    img {
        border-radius: 8px;
    }

    .menu-item:not(.divider) {
        [data-content-type="text"] {
            padding-top: 50px;
        }

        a {
            text-decoration: none;
            padding: 6px 12px;
            border-radius: $custom-border-radius-sm;
        }

        strong > a,
        a > strong {
            text-transform: uppercase;
        }

        figure[data-content-type="image"] {
            min-height: 100% !important; // Overrides inline styles
            width: auto !important; // Overrides inline styles

            > img {
                width: auto !important; // Overrides inline styles
            }
        }

        figure[data-content-type="image"] > img {
            object-fit: cover;
        }

        figure[data-content-type="card"] {
            margin-top: 50px;

            img {
                height: auto; // Overrides inline styles
                width: 225px; // Overrides inline styles
            }

            .pagebuilder-card-overlay button {
                @include lib-button-reset();
                font-size: 14px;
                font-weight: $font-weight__bold;
                text-transform: none;
            }

            + figure[data-content-type="card"] {
                margin-top: 0;
            }
        }

        [data-content-type="text"]:last-child {
            margin-bottom: 50px;
        }

        > .menu-level {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 98%;
            width: 100vw;
            left: 0;
            right: 0;
            transform: none;
            margin-top: 0;
            background-color: $color-white;
            border-top: 1px solid $c-gray-100;
            box-shadow: 0 8px 5px -9px rgba($color: $c-brown, $alpha: .36);

            .pagebuilder-column:last-child {
                align-items: end;
            }
        }

        &.open-submenu > .menu-level {
            opacity: 1;
            visibility: visible;
        }

        > .menu-item__link {
            font-size: 13px;
            text-decoration: none;
            padding: 12px;
        }
    }

    .menu-item.divider {
        margin: 0;
        margin-right: $indent__m;

        p {
            margin: 0;
        }

        ~ .menu-item > .menu-item__link {
            text-transform: none;
        }
    }

    > .menu-level {
        display: flex;
        margin: 0;

        > .menu-item:not(.divider) {
            margin: 0;
            border-bottom: 2px solid transparent;

            @include hover() {
                border-color: $color-black;
            }

            a {
                display: block;

                @include hover() {
                    color: $color-black;
                }
            }
        }
    }

    .brands-flyout {
        [data-content-type="card"] {
            margin: 0 $indent__m $indent__m;
            border-radius: 5px;
            border: 1px solid $c-gray-100 !important; // Overrides inline styles
            overflow: hidden;
            aspect-ratio: 1/0.48;
            display: flex;
            justify-content: center;
            align-items: center;

            @include hover() {
                border-color: $c-gray-600 !important; // Overrides inline styles
            }

            a,
            img {
                display: block;
            }

            .pagebuilder-card-overlay {
                display: none;
            }
        }
    }

    .footer__links {
        display: none;
    }

    @include max-screen($screen__l) {

        p,
        a {
            font-size: 12px;
        }

        .menu-item:not(.divider) {
            [data-content-type="text"] {
                padding-top: 30px;
            }

            > .menu-item__link {
                font-size: 12px;
            }
        }

        > .menu-level > .menu-item:not(.divider) {
            margin-right: 15px;
        }
    }
}