.homepage-gallery {
    --homepage-gallery-image-height: 100px;
    --homepage-gallery-image-width: var(--homepage-gallery-image-height);
    max-width: calc(100vw - 20px);
    padding: 0 5px !important; // Overrides inline styles

    p {
        font-size: $font-size__base--desktop;
    }

    #crl8-homepage-carousel {
        a,
        button {
            @include lib-button();
            min-width: calc(100% + 66px);
            margin-left: -33px;
            margin-right: -33px;
            width: 100%;
            box-sizing: border-box;
            padding: 13px;
            font-weight: 500px;
            letter-spacing: $button__letter-spacing;
        }

        button + a {
            margin-top: 10px;
        }

        div {
            height: auto;
        }

        [aria-labelledby="crl8-homepage-carousel-carousel-heading"] {
            a,
            button {
                background: 0;
                padding: 0;
                border: 0;
                margin: 0;
                min-width: 0;

                &::after {
                    content: none;
                }
            }

            > div > div:first-child,
            > div > div:last-child {
                position: absolute;
                top: calc(100% + #{$indent__base});
                right: 25px;
                left: auto;

                button {
                    height: 37px;
                    width: 37px;
                    border-radius: 50%;
                    border: 1px solid $c-gray-600;
                    display: flex;
                    align-items: center;
                    margin: 0;
                    min-width: 0;

                    > div svg {
                        transform: scale(.66);
                        fill: $c-gray-600;
                    }
                }
            }

            > div {
                overflow: visible;
                padding: 0;
                white-space: normal !important; // Overrides inline styles

                > div:first-child {
                    right: 85px;
                }
            }
        }

        .crl8-homepage-carousel-item {
            height: var(--homepage-gallery-image-height);
            width: var(--homepage-gallery-image-width);

            button {
                height: var(--homepage-gallery-image-height);
                width: var(--homepage-gallery-image-width);

                img {
                    height: var(--homepage-gallery-image-height);
                    width: var(--homepage-gallery-image-width);
                }

                div {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    @media (min-width: $screen__xs) {
        #crl8-homepage-carousel {
            a,
            button {
                margin-left: -40px;
                margin-bottom: 0;
                margin-right: 55px;
                width: auto;
                min-width: 0;
                padding-right: 35px;
                padding-left: 35px;
            }

            button + a {
                margin-top: 0;
            }

            div {
                flex-direction: row;
                justify-content: flex-start;
            }
        }
    }

    @media (min-width: $screen__m) {
        --homepage-gallery-image-height: 250px;

        h1 {
            text-align: left !important; // Overrides inline styles
            margin-bottom: 25px;
        }

        .homepage-gallery-text[data-content-type="text"] p {
            margin-bottom: 25px;
            max-width: 50%;
        }

        #crl8-homepage-carousel {
            [aria-labelledby="crl8-homepage-carousel-carousel-heading"] {
                margin-top: 15px;

                > div > div:first-child {
                    right: 44px;
                }

                > div > div:last-child {
                    right: -6px;
                }
            }
        }
    }
}
