.cms-nourison-home,
.cms-hp-prototype {
    background-color: $c-gray-50;
    position: relative;

    .messages {
        @include min-screen($screen__m) {
            margin-bottom: 0;
        }
    }

    .column.main {
        padding-bottom: 0;

        @include max-screen($screen__m) {
            width: 100%;
        }
    }

    .homepage-featured-categories .pagebuilder-column-group {
        @include max-screen($screen__m) {
            flex-direction: column !important; // override PageBuilder
            margin-left: auto;
            margin-right: auto;
        }

        img {
            max-width: 115px !important; // override PageBuilder
        }

        .pagebuilder-column {
            @include max-screen($screen__m) {
                width: auto !important; // override PageBuilder
                margin-bottom: $indent__l !important; // override PageBuilder
                text-align: center;
                max-width: 350px;
            }

            @include min-screen($screen__m) {
                &:not(:last-of-type) {
                    margin-right: $indent__m !important;
                }

                p {
                    max-width: 350px;
                    margin-left: auto;
                    margin-right: auto;
                    padding-left: 35px;
                    padding-right: 35px;
                }
            }

            [data-content-type="buttons"] {
                @include button-with-arrow();
                margin-top: $indent__s;

                a,
                a span {
                    text-decoration: none;
                    color: $button-tertiary__color;
                }

                @include hover {

                    a,
                    a span {
                        text-decoration: none;
                        color: $button-tertiary__hover__color;
                    }
                }
            }
        }
    }

    .homepage-gallery-text {
        margin: $indent__s auto $indent__m auto !important; // override PageBuilder

        p {
            margin: 0;
        }
    }

    .featured-products {
        .tocart {
            display: none;
        }

        a {
            text-decoration: none;
        }

        .product-items {
            display: flex;
            flex-wrap: wrap;

            @include min-screen($screen__l) {
                flex-wrap: nowrap;
            }
        }
    }

    @include max-screen($screen__m) {
        .home-top-banner.jarallax[data-background-type="video"] {
            min-height: 30vh !important;
        }
    }

    .page-main {
        max-width: 100%;

        * {
            box-sizing: border-box;
        }
    }

    @include darkroom-slick-dots;

    .slick-dots {

        li {
            button {
                background-color: rgba($color: $c-white, $alpha: 0.3);
                width: 24px;
                height: 8px;
                border: none;
            }

            &.slick-active button {
                background-color: $c-white;
            }
        }
    }

    .pt-big > h2 {
        @include max-screen($screen__m) {
            margin-top: 0;
        }
    }
}

.row-heading {
    @include max-screen($screen__l) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.hp-hero-slider {
    p {
        color: $c-gray-350;
    }

    .pagebuilder-wrapper {
        padding: 0;
    }

    .pagebuilder-slide-wrapper {

        [data-element='content'] {
            min-height: 0;
        }
    }

    .pagebuilder-overlay {
        padding: 0;
        border-radius: $custom-border-radius;
        max-width: 100%;
    }

    .pagebuilder-collage-content {
        max-width: 100%;

        @include min-screen($screen__m) {
            max-width: 320px;
        }

        h2 {
            margin-bottom: 0;
        }
    }

    .pagebuilder-banner-button {
        margin: 0;
    }

    .layout_wrapper {
        margin-left: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $c-white;

        @include min-screen($screen__m) {
            margin-left: 50px;
            position: static;
            background-color: transparent;
        }
    }

    .pagebuilder-slider {
        &.slick-slider {

            @include max-screen($screen__m) {
                display: flex;
                flex-direction: column;
            }

            @include slick-brown-arrow;

            .slick-arrow {
                width: 55px;
                height: 55px;
                background-color: $c-brown;

                &.slick-next,
                &.slick-prev {
                    top: 50%;
                    display: none !important; //overwrite inline styles
                }

                &.slick-prev {
                    left: 35px;
                }

                &.slick-next {
                    right: 35px;
                }
            }

        }
    }

    .pagebuilder-overlay.pagebuilder-poster-overlay {
        padding: 0 !important; //override page builder
    }

    .slick-slider {
        .slick-dots {
            position: absolute;
            bottom: auto;
            right: auto;
            left: 29%;
            top: 360px;
            display: inline-block;
            text-align: right;
            background-color: rgba($color: $color-black, $alpha: 0.3);
            border-radius: 21px;
            border: 1px solid $c-gray-300;
            padding: 7px 19px 3px 0;

            @include min-screen($screen__m) {
                bottom: 30px;
                right: 30px;
                top: auto;
                left: auto;
            }
        }
    }

    .pagebuilder-banner-wrapper {
        @include max-screen($screen__m) {
            position: relative;
        }
    }

    @include add-spacing;

}

.who-we-are {

    * {
        box-sizing: border-box;
    }

    background-color: $c-gray-100;
    padding: 32px 16px;

    .who-we-are-wrap-top,
    .who-we-are-wrap-bottom {
        background-color: $c-white;
        border-radius: 12px 12px 0 0;
    }

    .who-we-are-wrap-bottom {
        border-radius: 0 0 12px 12px;
    }

    @include min-screen($screen__m) {
        padding: 64px 32px;
    }

    h2 {
        @include darkroom-eyebrow;
    }

    .who-we-are-copy {
        @include darkroom-heading-sentence($c-light-brown);

        p {
            margin: 15px 0;
        }
    }

    figure[data-content-type="image"] {
        img {
            @include cathedral-images;
            display: block;
        }
    }

    .pagebuilder-column-group {
        @include max-screen($screen__m) {

            .pagebuilder-column:nth-child(2),
            .pagebuilder-column:nth-child(3) {
                figure[data-content-type="image"] {
                    img {
                        border-radius: 12px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .pagebuilder-column-line {
        padding: 24px;

        .pagebuilder-column {
            position: relative;

            a {
                display: inline-block;
                background-color: $c-white;
                border: $c-white;

                &.pagebuilder-button-secondary {
                    @include hover {
                        background-color: rgba($color: $color-black,
                                $alpha: .15);
                    }
                }
            }

            [data-content-type="buttons"] {
                max-width: 100%;
                position: absolute;
                text-align: center;
                left: 0;
                right: 0;
                bottom: 34px;
            }
        }

        @include min-screen($screen__s) {
            padding: 48px 24px;
            display: flex;

            .pagebuilder-column {
                padding: 0 24px;
            }
        }
    }

    .pagebuilder-button-secondary {
        background-color: $c-white;

        @include hover {
            background-color: $c-gray-300;
            color: $c-white;
        }
    }
}

.hp-press-reviews {
    @extend .pb-big;

    .pagebuilder-poster-content {
        text-align: center;
    }

    figure[data-content-type=image] {
        margin-bottom: 30px;
    }

    div[data-content-type=text] {
        font-size: 20px;
        font-family: $font-family__base;
        font-weight: $font-weight__regular;
        padding-top: 30px;
        width: 80%;
        max-width: 820px;
        margin: auto;

        @include min-screen($screen__m) {
            font-size: 32px;
        }
    }

    .pagebuilder-slide-wrapper {
        min-height: 10px !important; //overwrite pagebuilder inline css
        width: 100%;
        max-width: $screen__l2;
        padding-left: 20px;
        padding-right: 20px;

        @include min-screen($screen__l2) {
            padding: 0;
        }
    }

    .pagebuilder-slider.slick-slider {
        @include slick-brown-arrow;
        @include darkroom-slick-dots;

        .slick-dots {
            width: 100%;
        }

        img {
            display: inline-block;
        }

        .slick-arrow {

            &.slick-next,
            &.slick-prev {
                top: 50%;
            }

            &.slick-prev {
                left: 3px;
            }

            &.slick-next {
                right: 3px;
            }
        }

        @include min-screen($screen__m) {

            &.slick-prev,
            &.slick-next {
                top: 50%;

                &::before {
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }

    @include max-screen($screen__l) {
        .slick-arrow {
            display: none !important;
        }
    }
}


.hp-featured-products-slider {
    @extend .pb-big;
    max-width: calc(#{$screen__l2} + 24px);
    margin: auto;
    width: 100%;

    .slick-slider {
        @include slick-brown-arrow;
        @include slick-brown-arrow-top;
    }

    @include darkroom-slick-dots;

    .slick-slider .slick-dots {
        position: absolute;
        bottom: -52px;
    }

    .product-item a {
        display: block;
        position: relative;
    }

    .product-image-wrapper {
        position: static;
        padding: 0 !important; //overwriting inline pagebuilder css
        height: auto;
        overflow: hidden;
        max-height: 327px;
    }

    .product-image-container {
        width: 100% !important; //overwriting inline pagebuilder css
    }

    .product-image-photo {
        position: static;
        width: 100%;
        height: auto;
        display: block;
    }

    .product-item-info {
        padding: 0 12px;
    }

    .price-box,
    .product-item-actions .actions-primary {
        display: none;
    }

    .slick-slide {
        float: left;
        height: auto;
        min-height: 1px;
    }

    .slick-slide {
        height: 0px !important; //adaptive height bug on slick plugin
    }

    .slick-slide.slick-active {
        height: 100% !important; //adaptive height bug on slick plugin
    }

    .actions-secondary {
        a {
            @include icon-svg($icon: wishlist-empty,
                $fill: $color-black,
                $width: 16px,
                $height: 14px,
                $pseudo: before);
        }

    }
}