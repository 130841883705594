$layered-nav__toggle-content-title_color: $color-white;
$layered-nav__border: 1px solid $c-border;
$layered-nav__filter-item-spacing: $indent__base;
$layered-nav__filter-item-color: $text__color;

$layered-nav__svg-size: 16px;
$layered-nav__line-height: 1.4;
//  Common
//  _____________________________________________

.items {
    @extend .abs-reset-list;
}

.filter {
    &.block {
        margin-bottom: 0;
    }

    &-title {
        background-color: $secondary__color;
        color: $layered-nav__toggle-content-title_color;

        @include min-screen($screen__m) {
            background-color: transparent;
            color: $text__color;
        }

        h5 {
            margin: 0;
            text-transform: uppercase;
            font-size: $font-size__base--l;

        }

        strong {
            $_shadow: inset 0 1px 0 0 $color-white, inset 0 -1px 0 0 fade($border-color__base, 30);

            padding: 0;
            font-weight: $font-weight__regular;
            line-height: 16px;
            text-align: center;
            background-color: $toolbar-element-background;
            border: none;
            border-radius: 3px;


            &[data-count]:after {
                color: $color-white;
                background: $color-orange-red1;
                border-radius: 2px;
                content: attr(data-count);
                display: inline-block;
                font-size: .8em;
                line-height: 1;
                margin: 0 $indent__xs;
                min-width: 1em;
                padding: 2px;
            }

            &.disabled {
                opacity: .5;
            }
        }
    }

    .block-subtitle {
        display: none;
        border-bottom: $border-width__base solid $border-color__base;
        font-size: $font-size__base;
        font-weight: $font-weight__semibold;
        line-height: 1em;
        padding: $indent__s $indent__s $indent__base 0;

        &.filter-current-subtitle {
            font-size: 18px;
            font-weight: 700;

            &::before {
                font-size: 16px;
                margin-right: 5px;
            }

        }
    }

    .block-title button,
    &-options-title {
        position: relative;
        width: 100%;
        padding: 8px 16px 8px 0;
        background-color: transparent;
        cursor: pointer;
        text-align: left;
        overflow: visible;

        &::before {
            @include vertically-center($position: absolute);
            left: $indent__base;
        }

        &[aria-expanded="true"]::before {
            @extend .abs-icon-collapse-large;
        }
    }

    .block-title {
        button {
            padding: 8px 16px;
            border: none;

            @include icon-svg($icon : expand,
                $fill : $layered-nav__toggle-content-title_color,
                $width : $toggling-icon-width,
                $height : auto,
                $pseudo : after,
                $hideLabel : false);

            @include min-screen($screen__m) {
                border-top: 0;

                @include hover {
                    border-top: 0;
                }
            }

            &::before {
                @include vertically-center($position: absolute);
                left: 10px;
            }

            &[aria-expanded="true"] {
                border-bottom: 0;

                @include icon-svg($icon : collapse,
                    $fill : $layered-nav__toggle-content-title_color,
                    $width : $toggling-icon-width,
                    $height : auto,
                    $pseudo : before,
                    $hideLabel : false);
            }
        }

        h4 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    &.active .block-title {
        @include max-screen($screen__m) {
            border-bottom: 0;
        }
    }

    &-subtitle {
        display: none;
    }

    &-content {
        .filter-current {
            margin: 0;

            .items {
                padding: 3px 0;
                margin-bottom: 0;
            }

            .item {
                position: relative;
                z-index: 1;
                padding-left: $indent__l;
                margin-bottom: 20px;
                line-height: 1.5;
                letter-spacing: .2px;
                color: $layered-nav__filter-item-color;
                font-family: $font-family__secondary;
                font-style: normal;
                list-style-type: none;
                text-transform: capitalize;
                padding: 4px 31px 4px 8px;
                border: 1px solid $c-gray-150;
                border-radius: 100px;
                background-color: $c-white;
            }

            .filter &-subtitle {
                display: none;
                border: none;
                padding-bottom: $indent__s;
            }

            .action.remove {
                @include lib-icon-font($icon-remove,
                    $_icon-font-size : 35px,
                    $_icon-font-line-height : 15px,
                    $_icon-font-text-hide : true,
                    $_icon-font-color : $link__color,
                    $_icon-font-color-hover : $link__hover__color,
                    $_icon-font-color-active: $link__hover__color );
                position: absolute;
                top: 3px;
                right: 10px;
                padding-left: 0;
            }
        }
    }

    &-actions {
        margin-bottom: $indent__m;
        padding: 0 $layered-nav__filter-item-spacing;
        padding-top: $indent__xs;

        .filter-clear {
            text-decoration: underline;
        }
    }

    &-item-checkbox[type="checkbox"] + label {
        padding-left: 40px !important; // Overrides base styles for custom checkboxes
        max-width: 155px;

        @include hover {
            color: $link__hover__color;
        }
    }

    &-label {
        font-weight: $font-weight__bold;

        &:after {
            content: ': ';
        }
    }

    &-value {
        color: $color-gray52;
    }

    &-options {
        margin: 0;
        border-top: $layered-nav__border;

        &-item {
            border-bottom: $layered-nav__border
        }

        &-title {
            &.layered-nav-toggle {
                @include lib-button-reset;
                position: relative;
                margin: 0;
                padding: 0 16px 0 0 !important;
                border-radius: 0;
                font-weight: $font-weight__bold;
                line-height: 2.8;
                text-transform: uppercase;
                display: block;
                word-break: break-word;
                z-index: 1;
                cursor: pointer;
                text-transform: uppercase !important;
                box-sizing: border-box;

                @include icon-svg($icon : plus,
                    $width: 12px,
                    $fill: $color-black,
                    $pseudo: after,
                    $height: auto,
                    $hideLabel : false);

                &::after {
                    margin-right: $indent__s;
                    margin-bottom: 3px;
                    position: absolute;
                    right: 0;
                    top: 38%;
                }

                &[aria-expanded="true"] {
                    @include icon-svg($icon : minus,
                        $width: 12px,
                        $fill: $color-black,
                        $pseudo: after,
                        $height: auto,
                        $hideLabel : false);

                    @include min-screen($screen__m) {
                        margin-bottom: 2px;
                    }

                }

                &::before {
                    display: none;
                }

                &:hover {
                    border: 0;
                }
            }
        }

        &-content {
            margin: 0;
            margin-left: $indent__s;
            padding-bottom: $indent__s;
            padding-left: 15px;

            .items {
                padding-left: 0;

                &:after {
                    position: absolute;
                    left: -5px;
                    top: 4px;
                }

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    background: none;
                    border: none;
                    color: $secondary__color;
                }

                &[aria-expanded="true"] {
                    @include lib-icon-font-symbol($_icon-font-content: $_dropdown-toggle-active-icon-content,
                        $_icon-font-position: after);
                }
            }

            .item {
                margin: 0;
                margin-bottom: $indent__s;
                list-style-type: none;

                @include max-screen($screen__m) {
                    &:first-child {
                        margin-top: 0;
                    }
                }

                &:last-child {
                    margin-bottom: $indent__l;
                }

                label {
                    font-style: normal !important; //To override default input checkbox font-style
                }
            }

            a {
                display: block;
                padding-left: 5px;
                padding-right: 7px;
                margin-left: -5px;
                margin-right: -5px;
                text-decoration: none;

                @include hover {
                    text-decoration: underline;
                }
            }

            .count {
                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }

            .filter-count-label {
                @include lib-visually-hidden();
            }
        }
    }

    .filter-title button {
        @include min-screen($screen__m) {
            padding-left: 0;
            pointer-events: none;

            &:before {
                display: none !important; // overwrite [aria-expanded] check
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .filter {
        & &-subtitle {
            font-size: 20px;
            font-weight: $font-weight__regular;
        }

        &.block {
            border-left: $layered-nav__border;
            border-right: $layered-nav__border;
        }

        &-options-content {
            padding: $indent__s $indent__s 0 15px;

            .items {
                margin-bottom: 3px;
            }
        }

        &-options-item {
            padding-left: $indent__base;
        }
    }

    .filter .filter-current {
        .items {
            padding-top: $indent__l;
            padding-left: $indent__s;
        }

        &-subtitle {
            position: relative;
            padding-left: 7px;
            z-index: 1;

            @include lib-icon-font($_icon-font-content: $icon-collapse,
                $_icon-font-size: 13px,
                $_icon-font-position: before,
                $_icon-font-display: block);
        }

        .item {
            padding-left: 40px;
        }

        .action.remove {
            left: -2px;
        }

        &.active {
            .items {
                display: block;
            }

            & + .block-actions {
                display: block;
            }
        }
    }

    .filter-no-options {
        .filter-title {
            &:before {
                background: rgba(255, 255, 255, .5);
                content: '';
                display: block;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: $indent__base;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                order: 0;
            }
        }
    }
}

@include min-screen($screen__m) {
    .filter {
        &.block {
            margin-bottom: $indent__xl;
        }

        .block-title button {
            padding: 8px 16px;
        }

        &-content {
            .item {
                &:last-child {
                    margin-bottom: 14px;
                }

                a {
                    padding-left: 16px;
                }
            }

            .filter-current {
                margin-top: $indent__s;
            }
        }

        &-options-content {
            padding-top: $indent__s;
            max-height: 325px;
            overflow-y: auto;
            overflow-x: hidden;

            .swatch-attribute {
                padding-left: 10px;
            }
        }

        &-actions {
            padding-left: 0;
            padding-top: 5px;
        }

        &.active &-options,
        &-options {
            background: transparent;
            clear: both;
            display: block;
            overflow: initial;
            position: static;
        }

        &-subtitle {
            display: block;
            position: static;
        }
    }

    .page-layout-1column {
        .toolbar-products {
            position: absolute;
            top: 0;
            width: 100%;
        }

        .products ~ .toolbar-products {
            position: static;
        }

        &.page-with-filter .column.main {
            padding-top: 45px;
            position: relative;
            z-index: 1;
        }

        .filter {
            &.block {
                border-top: 1px solid $border-color__base;
            }

            &-content {
                margin-top: $indent__s;
            }

            &-subtitle {
                display: none;
            }

            &-options {
                &-item {
                    border: 0;
                    display: inline-block;
                    margin-right: $indent__m;
                    position: relative;

                    &.active {
                        z-index: 2;

                        @include hover {
                            z-index: 3;
                        }

                        .filter-options-content {
                            visibility: visible;
                        }

                        &:after,
                        &:before {
                            @include lib-arrow(up, 8px, $color-black);
                            bottom: -1px;
                            content: '';
                            display: block;
                            left: $indent__xs;
                            position: absolute;
                            z-index: 3;
                        }

                        &:after {
                            border-bottom-color: $color-white;
                            margin-top: 2px;
                            z-index: 4;
                        }
                    }
                }

                &-title {
                    padding: 0 $indent__base 0 0;
                }

                &-content {
                    $_shadow: 0 3px 5px 0 rgba(50, 50, 50, .75);

                    background-color: $color-white;
                    box-shadow: $_shadow;
                    border: 1px solid $border-color__base;
                    padding: $indent__xs 0;
                    position: absolute;
                    top: 100%;
                    visibility: hidden;
                    width: 180px;
                    z-index: 2;

                    .item {
                        margin: 0;
                        padding: $indent__xs;

                        a {
                            margin-left: 0;
                        }

                        @include hover {
                            background-color: $color-gray91;
                        }
                    }
                }
            }

            &-current {
                display: inline;
                line-height: 35px;

                &-subtitle {
                    color: $text__color__muted;
                    display: inline;
                    font-size: $font-size__base;
                    font-weight: normal;
                    padding: 0;

                    &:after {
                        content: ':';
                    }
                }

                .item,
                .items {
                    display: inline;
                }

                .item {
                    margin-right: $indent__m;
                    white-space: nowrap;
                }

                .action.remove {
                    line-height: normal;
                }
            }

            &-actions {
                display: inline;
                white-space: nowrap;

                & ~ .filter-options {
                    margin-top: $indent__m;
                }
            }
        }
    }
}

@media (max-width: $screen__m) {
    .desktop-only {
        display: none;
    }
}

@media (min-width: $screen__m) {
    .mobile-only {
        display: none;
    }
}

// Filters Modal Styling
.layered-filter-modal__content {
    display: none; // prevent layout shift when page is loading
}

.modal-slide.layered-filter-modal {
    right: 0;
    left: 0;

    @include min-screen($screen__m) {
        max-width: 450px;
    }

    &._show .modal-inner-wrap {
        height: 90vh;
        transform: translate(0, 10vh);

        @include min-screen($screen__m) {
            height: 100%;
            transform: translate(0, 0);
        }
    }

    .layered-filter-modal__content {
        display: block;
    }

    .page-main-actions {
        display: none;
    }

    .modal-header {
        z-index: 3; // layer over filters title and modal content
    }

    .modal-inner-wrap {
        padding: 0;
        background-color: $c-gray-50;
        transform: translate(0, 100vh);

        @include min-screen($screen__m) {
            transform: translate(-450px, 0);
        }
    }

    .action-close {
        @include icon-svg(
            $icon: filter-modal-close,
            $fill: $color-black,
            $width: 40px,
            $height: 40px,
            $pseudo: before,
            $hideLabel: true
        );

        &::before {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    .block-content.filter-content {
        padding: 16px;
    }

    .filter-title {
        background-color: $color-white;
        padding: 8px 16px;
        position: sticky;
        top: 0;
        z-index: 2; // layer over modal content
        color: $color-black;
        
        [data-role="title"] {
            line-height: 2.22;
        }
    }

    .filter-current {
        margin-top: 0;

        ol.items {
            padding: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            > .item {
                width: fit-content;
                padding: 4px 8px;
                border-radius: 100px;
                border: 1px solid $pdp-login-border__color;
                background: $color-white;
                margin-bottom: 8px;
                margin-right: 8px;
            }
        }

        .filter-label,
        .filter-value {
            color: $color-black;
            font-size: $font-size__base--desktop;
            font-weight: $font-weight__regular;
            text-transform: capitalize;
            line-height: $layered-nav__line-height;
        }

        .action.remove {
            display: inline-block;
            vertical-align: top;
            position: static;

            @include icon-svg(
                $icon: filter-remove,
                $fill: $color-black,
                $width: $layered-nav__svg-size,
                $height: $layered-nav__svg-size,
                $pseudo: before,
                $hideLabel: true
            );

            &::before {
                line-height: $layered-nav__line-height;
            }
        }
    }

    .filter-actions {
        padding: 0;
        margin-bottom: 8px;
    }

    .filter-options-item {
        padding: 12px 0;

        &.code-collection .filter-options-content,
        &.code-collections .filter-options-content {
            max-height: 370px;

            .swatch-attribute.swatch-layered {
                padding: 0;
            }

            .swatch-attribute-options {
                display: flex;
                flex-direction: column;
            }

            .swatch-option-link-layered {
                @include lib-button-reset();
            }

            .swatch-option-link-layered__inner {
                line-height: 1;
                font-size: 0;
                margin: 0;
                padding: 6px 16px;

                &:hover,
                .image-wrapper.selected {
                    .option-collection-info::after,
                    + .option-collection-info::after {
                        content: "";
                        position: absolute;
                        left: 4px;
                        top: 7px;
                        width: 10px;
                        height: 10px;
                        border: 0;
                        background-color: $color-black;
                        border-radius: 4px;
                    }
                }

                .count::before,
                .count::after {
                    content: '';
                }
            }

            .swatch-option.image {
                display: none;
            }

            .option-collection-info {
                color: $color-black;
                font-size: $font-size__base--desktop;
                line-height: $layered-nav__line-height;
                text-align: left;
                text-transform: capitalize;
                position: relative;
                padding-left: $indent__l;

                &::before {
                    content: "";
                    background-color: transparent;
                    width: $layered-nav__svg-size;
                    height: $layered-nav__svg-size;
                    border-radius: 4px;
                    border: 1px solid $color-black;
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
            }
        }

        .filter-options-content {
            max-height: 285px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .filter-options-title.layered-nav-toggle {
        @include button--transparent;
        color: $color-black;
        font-size: $font-size__base--desktop;
        text-transform: uppercase;
        font-weight: $font-weight__semibold;
        line-height: $layered-nav__line-height;
        letter-spacing: .32px;
        padding: 6px 0;
        width: 100%;

        &::before {
            display: none !important; // override inline styling
        }

        &::after {
            position: absolute;
            right: 0;
            top: 9px;
        }

        @include icon-svg(
            $icon: expand,
            $fill: $color-black,
            $width: $layered-nav__svg-size,
            $height: $layered-nav__svg-size,
            $pseudo: after,
            $hideLabel: true
        );

        &[aria-expanded="true"] {
            @include icon-svg(
                $icon: minus-wide,
                $fill: $color-black,
                $width: $layered-nav__svg-size,
                $height: $layered-nav__svg-size,
                $pseudo: after,
                $hideLabel: true
            );
        }
    }

    .filter-options-content {
        margin: 0;
        padding: 0;

        ol.items > li.item {
            line-height: 1;
            font-size: 0;
            margin: 0;
            padding: 6px 16px 0;

            > input.filter-item-checkbox[type="checkbox"] {
                + label {
                    max-width: 380px;
                }

                + label::before,
                &:checked + label::before,
                + label:hover::before {
                    background-color: transparent;
                    width: $layered-nav__svg-size;
                    height: $layered-nav__svg-size;
                    border-radius: 4px;
                    border: 1px solid $color-black;
                    position: absolute;
                    left: 0;
                    top: 3px;
                }

                + label::after,
                &:checked + label::after,
                + label:hover::after {
                    position: absolute;
                    left: 4px;
                    top: 7px;
                    width: 10px;
                    height: 10px;
                    border: 0;
                    background-color: $color-black;
                    border-radius: 4px;
                }
            }
        }

        .label__container,
        .count {
            color: $color-black;
            font-size: $font-size__base--desktop;
            line-height: $layered-nav__line-height;
        }
    }
}
