//
//  Variables
//  _____________________________________________

$block-auth__dropdown__padding         : $indent__m !default;
$block-auth__dropdown__background-color: $color-white !default;
$block-auth__or-label__size            : 36px !default;
$block-auth__width                     : 0 !default;
$block-auth__border                    : 1px solid $color-gray-light3 !default;

//
//  Common
//  _____________________________________________

.authentication-dropdown {
    box-sizing: border-box;

    .modal-inner-wrap {
        padding: $block-auth__dropdown__padding;
    }
}
.authentication-wrapper {
    float: right;
    margin-top: -1.5 * $indent__xl;
    max-width: 50%;
    position: relative;
    z-index: 1;

    ._has-auth-shown & {
        z-index: $modal__z-index;
    }
}

.action-auth-toggle {
    @include button--inline-link();
}

.block-authentication {
    .block-title {
        font-size: $h3__font-size;
        border-bottom: 0;
        margin-top: $indent__s;
        margin-bottom: $indent__m;

        strong {
            @include bryant-park-headings-h2();
        }
    }

    .field {
        .label {
            font-weight: $font-weight__regular;
        }
    }

    .actions-toolbar {
        margin-bottom: $indent__xs;
        padding-top: $indent__xs;

        & > .secondary {
            padding-top: 15px;
            text-align: center;

            @include min-screen($screen__xs) {
              text-align: left;
            }

            .action {
              @include min-screen($screen__m) {
                color: $link__color;
                font-weight: $font-weight__light;

                @include hover {
                  color: $link__hover__color
                }
              }
            }
        }
    }

    .action.action-register,
    .action.action-login {
        @include lib-button-l();
    }

    .block[class] {
        margin: 0;

        ul {
            list-style: none;
            padding-left: $indent__s;
        }

        .field {
            .control,
            .label {
                float: none;
                width: auto;
            }
        }

        & + .block {
            border-top: 1px solid $color-gray-light5;
            margin-top: $indent__xl;
            padding-top: $indent__xl;
            position: relative;

            &::before {
                height: $block-auth__or-label__size;
                line-height: $block-auth__or-label__size - 2px;
                margin: (-$block-auth__or-label__size / 2 + 1px) 0 0 (-$block-auth__or-label__size / 2);
                min-width: $block-auth__or-label__size;
                background: $color-white;
                border: 1px solid $color-gray-light5;
                border-radius: 50%;
                box-sizing: border-box;
                color: $color-gray-light5;
                content: attr(data-label);
                display: inline-block;
                left: 50%;
                letter-spacing: normal;
                padding: 0 0.2rem;
                position: absolute;
                text-align: center;
                text-transform: uppercase;
                top: 0;
            }
        }
    }

    .checkout-flyout-message {
        margin-top: $indent__base;
        padding-top: $indent__s;
        padding-left: $indent__xs;
        padding-right: $indent__xs;
        text-align: center;
        border-top: 1px solid $c-checkout-msg-border;

        [data-element="inner"] {
            padding: 0 !important;  // override pagebuilder
            margin-bottom: 0 !important;  // override pagebuilder

            p {
                margin-bottom: $indent__s;

                @include min-screen($screen__m) {
                    display: inline-block;
                }

                a {
                    color: $c-dhurrie;

                    @include icon-svg(
                        $icon: hp-arrow,
                        $width: 12px,
                        $fill: $c-dhurrie,
                        $pseudo: after,
                        $height: auto
                    );

                    &::after {
                        margin-left: $indent__xs;
                    }

                    @include hover {
                        color: $c-houndstooth;

                        @include icon-svg(
                            $icon: hp-arrow,
                            $width: 12px,
                            $fill: $c-houndstooth,
                            $pseudo: after,
                            $height: auto
                        );
                    }
                }

                &:last-child {
                    margin-bottom: $indent__xs;
                    font-size: $font-size__base--desktop;
                    font-weight: $font-weight__regular;
                    letter-spacing: $letter-spacing__base--desktop;
                    line-height: 1.5;
                    color: $c-feng-shui;

                    @include min-screen($screen__m) {
                        display: block;
                    }
                }
            }
        }
    }
}

.popup-authentication.modal-popup._inner-scroll .modal-content {
    margin-bottom: 0;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .authentication-dropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: $block-auth__dropdown__background-color;
        border: $block-auth__border;
        text-align: left;
        opacity: 0;
        transition: opacity ease 0.15s, visibility 0s linear 0.15s;
        visibility: hidden;

        &._show {
            z-index: $dropdown-list__z-index;
            opacity: 1;
            transition: opacity ease-out 0.15s, visibility 0s linear 0s;
            visibility: visible;
        }
    }

    .authentication-wrapper {
        @include lib-column-width($checkout-sidebar__columns);
        text-align: right;
    }

    .block-authentication {
        .block-title {
            font-size: headings($heading-h2, font-size);
            border-bottom: 0;
            margin-bottom: $indent__m;
        }

        .actions-toolbar {
            & > .primary {
                float: left;
                margin-right: 0;

                .action {
                    margin-right: 0;
                }
            }

            & > .secondary {
                float: left;
                clear: left;
                margin-right: 2rem;
                padding-top: 1rem;

                .action {
                  margin-left: 0;
                }
            }
        }
    }

    .popup-authentication {
        .block-authentication {
            display: flex;
            flex-direction: row;
        }

        .block[class],
        .form-login,
        .fieldset,
        .block-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .block[class] {
            box-sizing: border-box;
            float: left;
            padding-top: $indent__s;
            width: 50%;

            & + .block {
                border-left: 1px solid $color-gray-light5;
                border-top: 0;
                margin: 0;
                padding: $indent__s 0 0 $indent__xl;

                &::before {
                    left: 0;
                    top: 50%;
                }
            }
        }

        .actions-toolbar {
            margin-bottom: 0;
            margin-top: auto;
        }
    }
}
