$back-to-top__z-index: 2;

.back-to-top {
    position: fixed;
    left: 0;
    bottom: 0;
    display: block;
    margin-bottom: 10px;
    padding: 10px;
    background-color: $link__color;
    z-index: $back-to-top__z-index;

    @include hover {
        background-color: $link__hover__color;
    }

    svg {
        display: block;
        width: 35px;
        height: 35px;
        fill: $color-white;
    }
}

@media print {
    .back-to-top {
        display: none !important;
    }
}
