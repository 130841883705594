// reset button styles
@mixin button--transparent {
    padding: 0;
    text-align: inherit;
    color: inherit;
    background: transparent;

   @include hover {
        color: inherit;
        background: transparent;
        border: 0;
    }
}

@mixin button--font-reset {
    letter-spacing: normal;
    text-transform: none;
    -webkit-font-smoothing: auto; // reset from button styling
}

@mixin button--inline-link {
    @include button--transparent;
    @include button--font-reset;
    @include lib-link();
    display: inline;
    margin: 0;
    width: auto;
    font-family: $font-family__secondary;
    font-weight: $font-weight__regular;
}

@mixin button--split {
    .action.split {
        padding: 7px 12px;
        text-decoration: none;
        border: $border-width__base solid $c-secondary__color;
        border-right: 0;

        @include hover {
            border-color: $primary__color;

            ~ .action.toggle {
                border-color: $primary__color;
            }
        }
    }

    .action.toggle {
        padding:  4px 2px;
        border: $border-width__base solid $c-secondary__color;


        &:after {
            font-size: 29px;
            color: inherit;
        }
    }

    .items {
        margin-top: 35px;
        min-width: 160px;
        border: $border-width__base solid $c-secondary__color;
    }
}

/*doc
---
title: Tertiary Button Link
name: links_tertiary
category: Blocks - Links
---

```html_example
<a href="#" class="abs-action-link-button">View and Edit Cart</a>

<a href="#" class="action">View and Edit Cart</a>
```
*/
@mixin button--tertiary {
    padding: 0;
    padding-bottom: $indent__xs;
    font-family: $heading__font-family__base;
    font-weight: $font-weight__regular;
    font-size: 15px;
    line-height: 2.66;
    letter-spacing: .9px;
    text-transform: uppercase;
    text-decoration: none;
    color: $button-tertiary__color;
    border: 0;
    position: relative;

    &:visited {
        color: $button-tertiary__color;
    }

    @include hover {
        text-decoration: none;
        color: $button-tertiary__hover__color;
        background-color: transparent;
    }
}

@mixin button--close {
    @include icon-svg(
        $icon       : remove,
        $fill       : $link__color,
        $fill-hover : $link__hover__color,
        $width      : $toggling-icon-width,
        $pseudo     : after
    );

    &::after {
        @include vertically-center($position: absolute);
    }
}

@mixin button--inline-link {
    @include button--transparent;
    @include button--font-reset;
    @include lib-link(
        $_link-color: $text__color
    );
    display: inline;
    margin: 0;
    width: auto;
    font-size: $font-size__base;
    font-weight: $font-weight__base;
    letter-spacing: $letter-spacing__base;
    line-height: 1.8;

    @include min-screen($screen__m) {
        font-size: $font-size__base--desktop;
        letter-spacing: $letter-spacing__base--desktop;
        line-height: 1.7;
    }
}

@mixin button-with-arrow {
    font-family: $font-family__secondary;
    font-style: normal;
    font-size: 18px;
    letter-spacing: .15px;
    text-transform: uppercase;
    border-bottom: none;

    @include icon-svg(
        $icon       : hp-arrow,
        $fill       : $c-brown,
        $width      : 13px,
        $height     : auto,
        $pseudo     : after,
        $hideLabel  : false
    );

    @include hover {
        border-bottom: none;
        color: $secondary__color;

        @include icon-svg(
            $icon       : hp-arrow,
            $fill       : $secondary__color,
            $width      : 13px,
            $height     : auto,
            $pseudo     : after,
            $hideLabel  : false
        );
        a::after,
        &::after {
            vertical-align: unset !important;
            transform: translateY(10%);
        }

        &::after {
            margin-left: $indent__xs;
        }
    }

    a::after,
    &::after {
        vertical-align: unset !important;
        transform: translateY(10%);
    }
    
    &::after {
        margin-left: $indent__xs;
        position: relative;
        bottom: 2px;
    }
}

@mixin button--selectable-swatch {
    transition: box-shadow 0.1s ease;

    &.selected,
    &:not(.disabled):hover,
    &:not(.disabled):focus {
        border-color: $c-ikat;
        box-shadow: inset 0 0 0 1px $c-ikat;
        outline: none;
    }

    :root &.selected {
        box-shadow: inset 0 0 0 2px $c-ikat;
    }
}
@mixin pagebuilder-button-long {
    border-radius: $custom-border-radius-sm;
    background-color: $c-white;
    padding: 8px 8px 8px 16px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: $font-family__secondary;
    @include hover{
        background-color: $c-gray-200;
    }
    &::after {
        background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.33203 6H10.6654" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 1.33325L10.6667 5.99992L6 10.6666" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        content: "";
        display: inline-block;
        background-position: center center;
        background-size: 55%;
        background-color: $c-brown;
        background-repeat: no-repeat;
        border-radius: 6px;
        width: 32px;
        height: 32px;
        margin-left: 20px;
    }
}