.our-products-block {
    max-width: 100vw;
    margin-left: -10px;
    margin-bottom: 65px;

    h1 {
        margin-top: 80px;
        margin-bottom: 0;
    }

    .slick-list {
        overflow: visible;
        width: 90%;
    }

    .pagebuilder-overlay.pagebuilder-poster-overlay {
        padding: 15px !important; //Overrides inline styles
    }

    .pagebuilder-slide-wrapper [data-element="content"] {
        min-height: 0;
    }

    .pagebuilder-slider.slick-slider .slick-arrow {
        position: absolute;
        top: calc(100% + #{$indent__base});
        right: 0;
        left: auto;
        border: 1px solid $c-gray-600;
        height: 37px;
        width: 37px;

        &.slick-prev::before,
        &.slick-next::before {
            width: 10px;
            height: 20px;
            padding: 0;
        }

        &.slick-prev {
            right: 85px;
            left: auto;

            &::before {
                transform: rotate(180deg);
                margin-left: 3px;
                margin-bottom: 2px;
            }
        }

        &.slick-next {

            &::before {
                transform: none;
                margin-left: 7px;
                margin-top: 4px;
            }
        }

        &::after {
            content: none;
        }
    }

    @media (min-width: $screen__m) {
        max-width: none;

        .slick-list {
            width: 75%;
        }

        .pagebuilder-poster-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            grid-template-areas: "hero hero"
            "text cta";

            figure {
                grid-area: hero;
            }

            [data-content-type="text"] {
                grid-area: text;
            }

            [data-content-type="buttons"] {
                grid-area: cta;
                justify-self: end;
                padding-top: 20px;
            }
        }

        .pagebuilder-overlay.pagebuilder-poster-overlay {
            padding: $indent__l !important; //Overrides inline styles
        }

        .pagebuilder-button-primary {
            margin-right: 0;
        }

        .pagebuilder-slider.slick-slider .slick-arrow {
            &.slick-prev {
                right: 50px;
            }

            &.slick-next {
                right: 0;
            }
        }
    }
}
