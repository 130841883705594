.block.newsletter {
    margin-bottom: $indent__xs;

    h3 {
        margin-top: 0;
        text-align: center;

         @include min-screen($screen__xs) {
            text-align: left;
         }
    }

    .form.subscribe {
        display: table;
        width: 100%;

        @include min-screen($screen__xs) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .fieldset {
        display: table-cell;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;
        margin-bottom: $indent__s;

        @include min-screen($screen__xs) {
            flex-grow: 1;
        }
    }

    .label {
        display: table-row;

        @include min-screen($screen__xs) {
            flex-basis: 100%;
            display: block;
        }
    }

    .actions {
        @include min-screen($screen__xs) {
            display: table-cell;
            vertical-align: top;
        }
    }
}
