$menu-margin: 35px;
$nav-bar-padding: 14px;

.hospitality-sticky-nav {
    padding-top: $nav-bar-padding;
    padding-bottom: $nav-bar-padding;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11; // keep visible above carousel arrow buttons
    background-color: #fff;
}

.hospitality-sticky-nav .hospitality-ul {
    margin-top: 10px;
    text-align: center;
}

.hospitality-sticky-nav-bar-li {
    display: inline;
    margin-right: 80px;
}

.hospitality-link {
    font-size: 11px;
    font-family: $font-family__secondary;
    font-weight: $font-weight__heavier;
    transition: color .15s ease;
    text-decoration: none;
    letter-spacing: 1.3px;
}

.hospitality-link-nav-bar {
    display: inline;
}

.hospitality-sticky-nav .sticky-nav-logo {
    float: left;
    position: relative;
    right: -$menu-margin;
    width: 175px; 
    height: 37.7px;
}

.hospitality-sticky-nav .hospitality-hamburger-menu-button {
    background: #fff;
    padding: 0px;
    margin-right: $menu-margin;
    width: 40px;
    border: none;
    float: right;
    border: none;
    display: none;
}

.hospitality-hamburger-navigation.toggled .hospitality-hamburger-menu-container {
    background-color: #fff;
    float: right;
    display: block;
    margin-top: 65px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 50px;
    transform: translateX(0%);
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 15px;
    padding-top: 30px;
}

.hospitality-hamburger-navigation .hospitality-hamburger-menu-container {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: .2s;
}

.hospitality-hamburger-menu-container .hospitality-hamburger-menu-list .hospitality-link-hamburger-menu {
    display: block;
    padding-bottom: 35px;
}

.hospitality-hamburger-menu-container .hospitality-hamburger-menu-list {
    margin-top: 0px;
    padding-left: 0px;
    text-align: center;
}

@media screen and (max-width:880px) {
    .hospitality-sticky-nav  .hospitality-hamburger-menu-button {
        display: block;
    }
    .hospitality-sticky-nav .hospitality-ul {
        display: none;
    }
    .hospitality-sticky-nav .sticky-nav-logo {
        width: 130px;
    }
}

.hospitality-hamburger-menu-close {
    display: none;
}

.cms-page-view.page-layout-sticky-nav-layout[class*="hospitality"] {
    .page.messages {
        margin-bottom: 0;
    }

    .promobar {
        display: none !important; // override promobar default to appear, to remove gap between nav and main page content
    }
}
