//new homepage
.layout_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagebuilder-wrapper {
    @include layout-container;

    a {
        text-decoration: none;
        width: 100%;
        display: block;
    }
}

.pagebuilder-banner-wrapper {
    overflow: hidden;
    display: block;
    align-items: center;
    position: static;
    min-height: 450px;

    @include min-screen($screen__m) {
        min-height: 620px;
        display: flex;
    }
}

.pagebuilder-collage-content {
    background-color: transparent;
    padding: 24px;

    p {
        color: $c-gray-350;
    }
}

.col-4 {
    //add class in page builder || homepage 'Shop Top Categories'
    padding-bottom: 80px;
    position: relative;

    .pagebuilder-slide-wrapper {
        padding: 0 !important; //overwrite page builder inline styles

        .pagebuilder-overlay {
            padding: 0 12px;
        }
    }

    &[data-content-type="row"][data-appearance="contained"] {
        max-width: calc(#{$screen__l2} + 24px);
    }

    .pagebuilder-collage-content {
        padding: 0 0 24px 0;
        border-radius: $custom-border-radius;
        background-color: $c-white;

        p {
            margin-bottom: 15px;
        }
    }

    .pagebuilder-slider.slick-slider {
        @include slick-brown-arrow;
        @include slick-brown-arrow-top;
    }

    div[data-content-type='text'] {
        padding: 0 20px 20px 20px;
    }

    [data-content-type='buttons'] {
        padding: 0 20px;

        [data-content-type='button-item'] {
            width: 100%;

        }
    }

    .pagebuilder-button-secondary {
        display: block;
        margin: 0;
    }

    h3 {
        margin-bottom: 15px;
    }

    figure[data-content-type="image"] img {
        border-radius: 12px 12px 0 0;
    }

    .pagebuilder-slide-wrapper [data-element=content] {
        min-height: 0px;
    }

    @include darkroom-slick-dots;

    .slick-slider .slick-dots {
        position: absolute;
        bottom: -52px;
    }
}

.col-2 {
    .pagebuilder-column-line {
        @extend .pt-big;
        display: flex;

        .pagebuilder-column {
            &:first-child {
                padding-bottom: 12px;
                padding-right: 0;
            }
        }

        @include min-screen($screen__m) {
            .pagebuilder-column {
                width: 50%;
            }

            .pagebuilder-column {
                &:first-child {
                    padding-right: 12px;
                }

                &:last-child {
                    padding-left: 12px;
                }
            }
        }

        padding-left: 20px;
        padding-right: 20px;

        @include min-screen($screen__l2) {
            padding: 0;
        }
    }

    figure[data-content-type="image"] > [data-element="link"],
    figure[data-content-type="image"] > [data-element="link"] img {
        @include image-settings(12px);
    }
}