.featured-new-collection-block {
    padding: 0 15px;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;

    p {
        color: $color-white;
        font-size: 18px;
        line-height: 1.44;
        margin-bottom: 0;

        @media (min-width: $screen__m) {
            max-width: 50%;
        }
    }

    &.featured-new-collection-block.featured-new-collection-block { // Repeated classes needed for specificity
        p,
        a {
            text-decoration: none;
        }
    }

    .pagebuilder-banner-wrapper .pagebuilder-overlay {
        padding: 20px;
    }

    .pagebuilder-wrapper.fullbleed {
        margin: 0 !important; // Overrides inline styles;
    }
}
