.content-search-index {
    ol.products-list {
        counter-reset: item;
        list-style: none;
        padding-left: 25px;
        margin-top: 32px;

        h2 {
            font-size: $font-size__pdp-name;
            font-weight: $font-weight__light;
            margin: 0;

            a {
                text-decoration: none;
            }
        }

        li {
            position: relative;
            margin: 0 0 32px;
            padding-left: 16px;
            counter-increment: item;
    
            &::before {
                position: absolute;
                content: counter(item);
                display: block;
                width: 25px;
                height: 25px;
                left: -25px;
                color: $color-white;
                background: $color-black;
                text-align: center;
                font-size: $font-size__base--desktop;
            }
        }
    }

    .desc.std {
        color: $c-gray-350;
    }
}

.catalogsearch-result-index,
.content-search-index {
    h1.page-title {
        font-size: 48px;
        line-height: 1;
        letter-spacing: -.96px;
        margin: 24px 0 16px;

        @include min-screen($mobile-nav__breakpoint) {
            font-size: 64px;
            letter-spacing: -1.28px;
        }
    }
}
