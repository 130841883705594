$block-element_margin: 24px !default;
$banner-offset: 165px !default;
$banner-offset--large: 180px !default;
.banner {
  position: relative;

  .bluefoot-image {
    @include image-overlay();

    + * {
      z-index: 2; // make text after image appear above image overlay
    }
  }

  .button {
    box-shadow: 2px 4px 8px rgba($secondary__color, .25);
  }

  &.homepage-hero {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.wide-heading {
    .banner--content {
      @include min-screen($screen__s) {
        max-width: 675px;
      }
    }

    .banner--btn-group {
      @include min-screen($screen__s) {
        max-width: 650px;
      }
    }

    .banner--heading {
      @media screen and (min-width: $screen__m) {
        max-width: 100%;
      }
    }
  }

  &--offset {
    margin-bottom: $banner-offset + 15px !important; // overrides bluefoot

    .bottom-offset,
    .pagebuilder-poster-content {
      @include bottom-offset(77%);
      top: auto;
      bottom: 0;
      width: 92%;
      color: $text__color;
      background-color: $color-white;

      .banner--btn-group .action:only-child {
        margin-left: 0 !important; // Override Bluefoot
        margin-right: 0 !important; // Override Bluefoot
      }

      .button {
        box-shadow: none;
      }
    }

    &--large {
      .bottom-offset,
      .pagebuilder-poster-content {
        @include min-screen($screen__m) {
          @include bottom-offset(65%);
          width: 80%;
          max-width: 100%;
        }
      }

      .button-with-arrow {
        box-shadow: none;
      }

      .banner--heading {
        @media screen and (min-width: $screen__m) {
          margin-top: 25px;
        }
      }

      .banner--subtitle {
        @include min-screen($screen__m) {
          max-width: 100%;
          margin-top: $indent__base !important; // Override Bluefoot
          padding-left: $indent__base;
          padding-right: $indent__base;
        }
      }

      .banner--btn-group .action {
        max-width: 240px;
        width: 100%;

        @include min-screen($screen__m) {
          margin-top: $indent__m;
          margin-bottom: 55px;
          width: 240px;
        }
      }
    }
  }

  &--content {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 650px;
    text-align: center;
    color: $color-white;
    text-align: left;
  }

  &--logo {
    display: block;
    max-width: 160px;
    margin: 0 auto;

    @include min-screen($screen__xs) {
       margin-top: 0;
    }

    @include min-screen($screen__s) {
       max-width: 235px;
    }
  }

  &--heading {
    margin-top: $block-element_margin;
    margin-bottom: 0;
    text-shadow: $bluefoot-banner__text-shadow;

    .bottom-offset & {
      margin-top: $indent__base;

      @include screen($screen__s, $screen__l) {
        margin-top: $indent__l;
      }

      @include min-screen($screen__l) {
        margin-top: $indent__base * 2;
      }
    }

    // For logos that are taller (i.e. Fifty to Infinity)
    .logo--tall & {
      margin-top: 0;
    }
  }

  &--subtitle {
    margin-top: $block-element_margin - 4px; // Subtract 4px from margin to account for line-height

    .bottom-offset & {
      margin-top: 15px;
    }

    p {
      margin: 0;
    }
  }

  &--btn-group {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0 !important; // overrides bluefoot
    margin-left: $indent__s;
    margin-right: $indent__s;

    @include min-screen($screen__s) {
      justify-content: center;
    }

    .action {
      flex-basis: 50%;
      margin-top: $block-element_margin;
      margin-right: $indent__s;
      padding-left: 15px;
      padding-right: 15px;

      @include min-screen($screen__m) {
        margin-top: 20px;
      }

      .cms-page-view .page-main & {
        text-decoration: none;
      }

      &:last-child {
        margin-right: 0;
      }

      &:only-child {
        padding-left: 36px;
        padding-right: 35px;

        @include min-screen($screen__s) {
          flex-basis: auto;
          margin-left: $indent__s;
          margin-right: $indent__s;
        }

        &.button-with-arrow {
          padding: 0;
        }
      }

      &.secondary {
        font-size: $button__font-size;
        line-height: $button__line-height;
        letter-spacing: $button__letter-spacing;
        color: $color-white;
        border-color: $color-white;

        @include hover {
          color: $button__color;
          background-color: $color-white;
        }

        .bottom-offset & {
          color: $button__color;
          border-color: $button__color;
          box-shadow: none;

          @include hover {
            background-color: $button__color;
            color: $color-white;
          }
        }
      }

      &.hide-on-mobile {
        display: none;
      }

      @include min-screen($screen__s) {
        &.hide-on-desktop {
          display: none;
        }

        &.hide-on-mobile {
          display: block;
        }
      }
    }
  }

  &--heading,
  &--subtitle,
  &--btn-group {
    .bottom-offset & {
      padding: 0 $indent__s;

      @include screen($screen__s, $screen__l) {
        padding-left: $indent__base;
        padding-right: $indent__base;
      }

      @include min-screen($screen__l) {
        padding-left: $indent__base * 2;
        padding-right: $indent__base * 2;
      }
    }
  }
}

.cms-index-index {
    .bottom-offset .banner--heading {
        text-shadow: none;
    }
}

// cms h1
h1.banner--heading {
  margin-top: $indent__s;
  font-size: 28px;

  @include min-screen($screen__xxs) {
    max-width: 260px;
  }

  @include min-screen($screen__xs) {
    max-width: 330px;
  }

  @include min-screen($screen__s) {
    max-width: 560px;
    font-size: 46px;
  }

  @include min-screen($screen__m) {
    margin-top: $indent__base;
  }

  @include min-screen($screen__l) {
    font-size: 52px;
  }
}

h2.banner--heading {
  font-size: 28px;

  @include min-screen($screen__l) {
    font-size: 46px;
  }
}

h4.banner--heading {
  font-size: 20px;

  @include min-screen($screen__m) {
    font-size: 28px;
  }
}

.offset-banners .pagebuilder {
    &-column-group {
        @include max-screen($screen__m) {
            flex-direction: column;
        }

        .pagebuilder-column {
            @include max-screen($screen__m) {
                width: auto !important; // override PageBuilder
            }

            &:first-of-type {
                @include min-screen($screen__m) {
                    margin-right: $indent__m !important; // override PageBuilder
                }
            }
        }
    }

    &-poster-overlay {
        min-height: 270px !important; // override PageBuilder
        position: relative;

        @include min-screen($screen__m) {
            min-height: 460px !important; // override PageBuilder
        }
    }

    &-poster-content {
        background-color: $page__background-color;
        text-align: left;
        margin-bottom: 30px;
        padding: 1px;
    }

    &-banner-button {
        margin-top: 0;

        @include max-screen($screen__m) {
            max-width: 200px;
        }
    }
}
