// Container div for: main product info, gallery, & related products
/*
    <div class="product-hero">
        <div class="product-hero__info">...</div>
        <div class="block related">...</div>
    </div>
*/
$product-select-options-hover: #f1f1f1 !default;

.product-hero {
    display: flex;
    flex-wrap: wrap;

    @include max-screen($screen__m) {
        flex-direction: column;
    }

    &__info {
        display: flex;
        align-content: start;
        flex-wrap: wrap;
        width: calc(100% - #{$width__product-related} - #{$indent__base});

        @include max-screen($screen__l) {
            width: 100%;
        }

        .swatch-option.text {
            color: $text__color;
        }
    }

    .bv-content-list-container button:hover::after {
        content: none;
    }

    .block.related {
        order: 4;
        float: none;
        width: 100%;

        @include min-screen($screen__l) {
            width: $width__product-related;
            order: 2;
        }
    }

    .product.media,
    .product-info-main {
        float: none;
        width: auto;
    }

    .product.media {
        order: 1;
        width: 100%;

        @include min-screen($screen__m) {
            width: calc(100% - #{$width__product-info} - #{$indent__base});
        }
    }

    .product-info-price {
        .price-box .price {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .configurable-variation-qty {
        display: none;
    }

    .product-info-main {
        order: 2;

        @include max-screen($screen__m) {
            margin-bottom: $indent__m;
        }

        @include min-screen($screen__m) {
            width: $width__product-info;
            margin-left: $indent__base;

            .page-title {
                margin-bottom: 15px;
            }
        }

        .bv-content-list-container,
        .bv_main_container {
            button::after {
                content: none;
            }
        }

        .bv_main_container {
            margin: 0 !important; //override BazaarVoice

            .bv_button_buttonMinimalist {
                background-color: $c-grey !important; //override BazaarVoice
                margin: $indent__s 0 !important;
                padding: 7px 13px !important;
                font-size: $font-size__base--xs !important;
                color: $c-heavy-metal !important;
                border-radius: 15px !important;
                text-decoration: none !important;

                @include hover {
                    font-size: $font-size__base--xs !important;
                    text-decoration: none !important;
                }
            }
        }

        .product-options-wrapper .box-tocart,
        .product-options-bottom .box-tocart {
            margin-top: $indent__xs;
            margin-bottom: 0;
        }

        .product-add-form {
            margin-top: 15px;
        }
    }

    .product.info.detailed {
        order: 3;
        width: 100%;
        margin-top: 45px;
        margin-bottom: $indent__l;

        .item.title {
            @include max-screen($screen__m) {
                border-top: 1px solid $c-border;
            }
        }

        .inventory-table__container {
            @include min-screen($screen__m) {
                margin-top: $indent__xl;

                &:last-of-type {
                    border-bottom: 0;
                }
            }
        }

        .inventory-table {
            th {
                font-weight: $font-weight__bold;
            }

            .inventory-table__inventory-type {
                font-size: $font-size__l;
            }

            @include max-screen($screen__m) {
                margin-bottom: $indent__xl;

                thead {
                    display: none;
                }

                tbody {
                    tr {
                        display: flex;
                        flex-direction: column;
                        border-bottom: 1px solid $c-border;

                        &:last-of-type {
                            border-bottom: 0;
                        }
                    }

                    td {
                        display: flex;
                        flex-direction: row;

                        &:first-of-type {
                            padding-top: $indent__m;
                        }

                        &:last-of-type {
                            padding-bottom: $indent__m;
                        }
                    }

                    .tablesaw-cell-content {
                        margin-left: $indent__s;
                    }
                }
            }

            @include min-screen($screen__m) {
                tbody .tablesaw-cell-label {
                    display: none;
                }

                td {
                    max-width: 130px;
                }
            }
        }

        .inventory-table__inventory-type {
            font-size: $h5__font-size-desktop;

            @include min-screen($screen__m) {
                font-size: $font-size__l;
            }
        }
    }

    .product__zoom-button {
        text-align: center;
        width: 100%;

        @include min-screen($screen__l) {
            width: calc(100% - #{$width__product-related} - #{$indent__base});
        }

        @include min-screen($screen__m) {
            span {
                width: calc(100% - #{$width__product-info} - #{$indent__base});
            }
        }

        span {
            display: block;
        }

        svg {
            position: absolute;
            height: 24px;
            width: 24px;
        }
    }

    .product-add-form .table-wrapper.grouped {

        .product-item-name,
        .control.qty {
            display: inline-block;
            margin-bottom: $indent__s;
        }

        .price-box {
            margin-top: 0;

            .price {
                margin: 0;
            }
        }

        td {
            padding: $indent__xs 0;
        }

        tbody .col {
            &.item {
                padding-top: $indent__s;
            }

            &.qty {
                padding-bottom: 15px;
            }
        }

        th {
            padding-left: 0;

            .qty {
                text-transform: uppercase;
            }
        }

        .col.shape span {
            text-transform: lowercase;
            display: inline-block;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        @include min-screen($screen__m) {
            .col {
                &.shape {
                    padding-right: 15px;
                }

                .product-item-name {
                    padding-right: $indent__m;
                }

                &.inventory {
                    padding-top: 15px;
                }
            }
        }

        @include min-screen($screen__l) {
            tbody .col {
                &.shape {
                    padding-top: 15px;
                }

                &.item {
                    padding-bottom: $indent__m;
                }
            }
        }
    }

    .request-quote [data-element="inner"] {
        padding-left: 0 !important; //override pagebuilder
        padding-right: 0 !important; //override pagebuilder
    }

    .block.upsell .product.photo {
        text-align: center;
    }
}

.product.attribute.description,
.additional-attributes-wrapper {
    color: $c-gray-400;
    font-size: $font-size__base;

    .col.label {
        color: $c-brown-500;
    }
}

.infinity-pdp .product-hero {
    &__info {
        width: 100%;
    }

    .product.media {
        @include min-screen($screen__m) {
            max-width: $infinity-media__width-l;
        }

        @include screen($screen__l, $screen__l2) {
            max-width: $infinity-media__width-s;
        }
    }

    .product-info-main {
        @include min-screen($screen__l) {
            width: calc(100% - #{$infinity-media__width-l} - #{$indent__base});
            max-width: none;
        }

        @include screen($screen__l, $screen__l2) {
            width: calc(100% - #{$infinity-media__width-s} - #{$indent__base});
        }
    }

    .related {
        width: 100%;

        .block-actions {
            border: none;
        }

        .product-items {
            flex-direction: row;

            li {
                padding: 0 $indent__s;
                max-width: 50%;
                border: none;

                @include min-screen($screen__m) {
                    width: percentage(1/3);
                }

                @include min-screen($screen__l) {
                    width: 20%;
                }
            }
        }
    }
}

.grouped-dropdown,
.dropdown-prices {
    @include lib-visibility-hidden();
    padding: 0;
    position: absolute;
    width: auto;
}

.page-product-grouped,
.catalog-product-view {
    .product-add-form {
        .products-qty-hidden {
            .mage-error {
                display: none !important;
            }

            & > div:first-child .mage-error {
                display: block !important;
            }
        }
    }

    .product-hero__info {
        width: 100%;
    }

    .product-hero .product-info-main {
        @include min-screen($screen__m) {
            flex: 1;
            margin-left: 35px;
        }

        @include min-screen($screen__l) {
            margin-left: 55px;
        }
    }

    .product-info-main .box-tocart:last-child {
        margin-top: 15px;

        .dealer-locator {
            margin-top: 0;

            .dealer-locator-svg,
            .dealer-locator-text {
                vertical-align: middle;
            }
        }
    }

    .product-info-main,
    .product-options-bottom {
        @include min-screen($screen__m) {
            max-width: 498px;
        }
    }

    .product-hero {
        .product.media {
            max-width: 625px;
        }

        .block.related {
            @include min-screen($screen__m) {
                width: 100%;
            }
        }

        .block.upsell {
            margin-left: 0;
            margin-top: $indent__l;
            order: 6;

            .product-items {
                display: flex;
                flex-wrap: wrap;
                margin-left: 0;

                .product-item {
                    padding-left: $indent__s;
                }
            }
        }
    }

    .products-related .products {
        @include min-screen($screen__l) {
            flex-direction: row;
        }

        .product-item {
            @include min-screen($screen__l) {
                width: 20%;
            }

            &:not(:last-child) {
                @include min-screen($screen__l) {
                    border-bottom: 0;
                }
            }
        }
    }

    .block.related {
        border-top: 1px solid $c-mercury;
        border-bottom: 1px solid $c-mercury;

        @include max-screen($screen__m) {
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
            width: 100vw;
            padding: 0 10px;
        }

        @include min-screen($screen__m) {
            order: 2;
        }

        @include min-screen($screen__l) {
            margin-left: 0;
        }

        .block-title.title h3 {
            margin-top: $indent__l;
            padding-left: $indent__s;
            font-family: $font-family__base;
            font-size: $font-size__base--l;
            letter-spacing: 0;
            font-weight: $font-weight__bold;
            color: $c-feng-shui;

            @include min-screen($screen__m) {
                padding-left: $indent__base;
                font-size: $font-size__base--xl;
            }
        }

        .block-content.content {
            @include min-screen($screen__m) {
                padding-left: $indent__base;
            }
        }
    }

    .product-info-main .page-title-wrapper .page-title {
        margin-bottom: 0;
        font-weight: $font-weight__bold;
        letter-spacing: $letter-spacing__pdp-name;
        color: $c-pdp-grouped-name;

        @include min-screen($screen__m) {
            font-size: $font-size__pdp-name;
            letter-spacing: $letter-spacing__pdp-name--desktop;
        }
    }

    .product-info-main .product.attribute.sku,
    .product-info-main .product.attribute.style,
    .product-info-main .product.attribute.color {
        .type {
            display: none;
        }

        > .value {
            font-size: $font-size__base--desktop;
            font-weight: $font-weight__regular;
            color: $c-gray-400;
        }
    }

    .product-info-style-color {

        .product.attribute.style,
        .product.attribute.color {
            display: inline-block;
            text-transform: uppercase;
        }

        .product.attribute.style {
            margin-right: $indent__xs;
        }
    }
}

.page-product-grouped .product-hero .products-related .products.product-items,
.product-hero .block.upsell .products.product-items {
    @include min-screen($screen__m) {
        margin-left: -$indent__s;
        margin-right: -$indent__s;
    }

    .product-item {
        @include min-screen($screen__m) {
            padding-left: $indent__s;
            padding-right: $indent__s;
        }
    }
}

.dropdown-wrapper.grouped {
    overflow: hidden;

    .field.select-wrapper {
        width: calc(100% - 65px);
        float: left;

        .label-wrapper {
            display: flex;
            font-size: $font-size__base;
            justify-content: space-between;
        }
    }

    .field.qty-wrapper {
        width: 50px;
        float: right;
        clear: right;

        input {
            text-align: center;
            margin-top: 0;
            max-width: 100%;
        }
    }

    .products-qty-hidden {
        clear: both;
        padding-top: 1px;
    }

    label {
        color: $c-gray-400;
        font-weight: $font-weight__light;
        font-size: $font-size__base--desktop;
    }
}

.select2-container {
    display: block;
    width: 100% !important;

    .select2-search--hide {
        display: none;
    }

    .select2-results {
        display: block;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            max-height: 400px;
            overflow-y: scroll;

            li {
                margin: 0;
                padding: $indent__xs 0;
                line-height: 1.2;
                cursor: pointer;

                @include hover {
                    background-color: $product-select-options-hover;
                }

                & > span {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    min-height: 28px;
                    padding: $indent__xs 0;

                    > span {
                        padding: 0 12px;
                        box-sizing: border-box;
                    }
                }

                .name {
                    width: 50%;
                    text-transform: capitalize;
                }

                .stock,
                .price {
                    width: 25%;
                    text-align: center;
                }

                .price .prices-tier {
                    margin-top: 3px;
                    font-size: 11px;
                    overflow: visible;

                    .price-wrapper,
                    .price {
                        width: 100%;
                        padding: 0;
                        font-size: 11px;
                    }
                }

                &:nth-child(odd) {
                    background-color: $c-mercury;

                    @include hover {
                        background-color: $product-select-options-hover;
                    }
                }
            }
        }
    }

    .select2-dropdown {
        background-color: white;
        border: 1px solid $c-dusty-grey;
        box-sizing: border-box;
        display: block;
        position: absolute;
        z-index: 3;
    }

    .select2-selection {
        @include lib-form-element-input($_type: select);
        display: flex;
        position: relative;
        align-items: center;
        padding: 0 15px;
        padding-right: $indent__l;
        line-height: 1.2;
        box-sizing: border-box;
        font-family: $font-family__secondary;
        color: $c-gray-400;

    }

    .select2-selection__arrow {
        position: absolute;
        right: $indent__s;

        @include icon-svg($icon: down,
            $width: 34px,
            $fill: $color-black,
            $pseudo: before,
            $height: auto);
    }

    &--open {
        .select2-selection__arrow {
            @include icon-svg($icon: up,
                $width: 34px,
                $fill: $color-black,
                $pseudo: before,
                $height: auto);
        }
    }
}

//BazaarVoice Reviews Modal
.bv-radio-field label::before {
    display: none;
}

.bv-checkbox-container .bv-fieldset-label-checkbox {

    &::before,
    &::after {
        top: $indent__s !important;
        left: $indent__s !important;
    }
}

.product-info-accordion {
    margin-top: $indent__l;
    margin-bottom: $indent__base;

    [data-role="collapsible"],
    {
    border-bottom: 1px solid $c-mercury;
    cursor: pointer;
    position: relative;

    &:first-child {
        border-top: 1px solid $c-mercury;
    }

    &.active {
        border-bottom: 0;

        span {
            @include icon-svg($icon: minus,
                $width: 12px,
                $fill: $color-black,
                $pseudo: after,
                $height: auto);
        }

        + [data-role="content"] {
            border-bottom: 1px solid $c-mercury;
        }
    }

    span,
    .collapsible {
        font-weight: $font-weight__bold;
        line-height: 3.9;
        text-transform: uppercase;
        display: block;
        width: 100%;


        @include min-screen($screen__m) {
            font-size: $font-size__base--l;
            line-height: 2.8;
            letter-spacing: $letter-spacing__pdp-tab;
        }

        @include icon-svg($icon: plus,
            $width: 12px,
            $fill: $color-black,
            $pseudo: after,
            $height: auto);

        &::after {
            margin-right: $indent__s;
            margin-bottom: 3px;
            position: absolute;
            right: 0;
            top: 38%;

            @include min-screen($screen__m) {
                margin-bottom: 2px;
            }
        }
    }
}

[data-role="content"] {
    padding-bottom: $indent__base;

    table,
    .table-wrapper {
        margin-bottom: 0;
    }
}
}

[data-bv-show="reviews"],
.reviews-collapsible {
    order: 5;

    @include min-screen($screen__m) {
        order: 4;
    }
}

[data-bv-show="questions"],
.questions-collaspsible {
    order: 6;

    @include min-screen($screen__m) {
        order: 5;
    }
}

[data-bv-show="reviews"],
[data-bv-show="questions"],
.reviews-collapsible,
.questions-collaspsible {
    display: block;
    width: 100%;
}

[data-bv-show="questions"] + .product.info.detailed {
    display: none;
}

.broadloom-upsell {
    order: 4;
}

.product-brand-image {

    [data-element="inner"],
    figure {
        padding: 0 !important; // override pagebuilder
        margin: 0 !important; // override pagebuilder
    }
}

/* Product review flyout */
.catalog-product-view {

    .bv-cv2-cleanslate .bv-core-container-75 .bv-submission .bv-submission-rating-cancel .bv-rating-link,
    .bv-cv2-cleanslate .bv-core-container-75 .bv-submission .bv-submission-star-rating .bv-rating-link {
        opacity: 1 !important; // override bazaarvoice
        height: auto !important; // override bazaarvoice
        width: auto !important; // override bazaarvoice
    }

    .questions-collaspsible,
    .reviews-collapsible,
    .inventory-collapsible {
        margin-right: 0;
    }
}