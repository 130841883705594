
.braintree-paypal-logo {
    margin-top: $indent__s;
    text-align: center;
}

.action-braintree-paypal-logo {
    @include lib-button-reset();
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: transparent;
    border: 2px solid $c-border;
    box-sizing: border-box;

    &:hover {
      background-color: transparent;
      border: 2px solid $secondary__color;
    }

    img {
        width: 115px;
        margin: 0;
        margin-top: $indent__xs;
    }
}
