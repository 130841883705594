.darkroom-banner {
    .pagebuilder-banner-wrapper {
        display: flex;
        align-items: center;
    }

    .pagebuilder-banner-button,
    button.pagebuilder-banner-button::after,
    button.pagebuilder-banner-button:hover::after,
    button.pagebuilder-banner-button:focus::after,
    .pagebuilder-overlay {
        border-radius: $custom-border-radius;
    }

    a[data-element="link"] {
        text-decoration: none;
    }

    h2,
    p {
        margin: 0;
    }

    @include max-screen($screen__m) {
        .pagebuilder-banner-wrapper {
            padding: $indent__s !important; // override pagebuilder
        }

        .pagebuilder-overlay {
            padding: $indent__base !important; // override pagebuilder
            background-color: rgba(255, 255, 255, .584) !important; // override pagebuilder
        }

        .layout_wrapper {
            width: 100%;
        }
    }
}

.darkroom-category-carousel {

    a.pagebuilder-button-primary,
    a.pagebuilder-button-primary::after,
    a.pagebuilder-button-primary:hover::after,
    a.pagebuilder-button-primary:focus::after {
        border-radius: $custom-border-radius;
        text-decoration: none;
    }

    p,
    p > span {
        margin: 0 !important; // override pagebuilder
        font-weight: $font-weight__regular;
    }

    [data-element="content"] {
        min-height: 0;
    }

    figure[data-content-type="image"] img {
        border-radius: $custom-border-radius $custom-border-radius 0 0;
        margin: 0 auto;
        width: 100%;
    }

    @include max-screen($screen__xs) {

        a.pagebuilder-button-primary,
        a.pagebuilder-button-primary::after,
        a.pagebuilder-button-primary:hover::after,
        a.pagebuilder-button-primary:focus::after,
        [data-content-type="button-item"] {
            width: 100%;
        }
    }
}

.darkroom-product-carousel {

    button.action.tocart.primary,
    button.action.tocart.primary::after,
    button.action.tocart.primary:hover::after,
    button.action.tocart.primary:focus::after {
        border-radius: $custom-border-radius;
    }

    @include max-screen($screen__m) {
        .product-item-actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}


.darkroom-category-carousel.pagebuilder-slider.slick-slider,
.darkroom-product-carousel[data-content-type="products"] {
    @include slick-brown-arrow;

    @include max-screen($screen__m) {

        .product-item-info,
        .product-item-details,
        [data-content-type="text"],
        [data-content-type="buttons"] {
            text-align: center;
        }
    }

}