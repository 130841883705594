.pages {

    @include lib-pager($_pager-icon-font-margin: 0,
        $_pager-action-border: none,
        $_pager-action-border-hover: none,
        $_pager-icon-use: false,
        $_pager-icon-font-text-hide: false);

    .item {
        @include bryant-park-headings-h5();
        margin: $indent__base 0;
        padding: 0;
        margin: 0;

        &:first-child,
        &:last-child {
            @include icon-svg($icon: arrow-small,
                $pseudo: before);
            padding: 4px 8px;
            position: relative;
            top: -2px;
        }

        &.current {

            &:first-child:before,
            &:last-child:before {
                color: $c-gray-150;
            }
        }

        &:first-child {
            &::before {
                transform: rotate(180deg);
            }
        }

        &.pages-item-next {
            @include max-screen($screen__xxs) {
                margin-right: 3px;
            }
        }
    }

    strong.page,
    .item {
        font-size: $font-size__base--desktop;
        font-weight: $font-weight__semibold;
        line-height: normal;
    }

    a,
    strong {
        &.page {
            padding: 4px 16px;
            border-bottom: 2px solid $c-gray-150;
            line-height: normal;
            font-size: inherit;

            &:hover {
                border-bottom: 2px solid $c-gray-150;
            }
        }
    }

    strong.page {
        border-color: $c-brown;
        color: $c-brown;
    }

    a.page,
    a.action.next span,
    a.action.previous span {
        @include hover {
            color: $pages__active__color;
            border-color: $c-gray-150;
        }
    }

    &-items {
        .item {
            &.current:last-child {
                &::before {
                    display: none !important;
                }
            }

            &:first-child::before {
                display: none !important;
            }
        }

        &:has(.pages-item-previous) {
            .item:first-child::before {
                display: inline-block !important;
            }
        }
    }
}

.pages .action {

    &.previous,
    &.next {
        &::before {
            vertical-align: middle;
        }

        &::before,
        &:visited:not(:hover)::before {
            color: $pages__arrow__color;
        }

        span {
            @include button--tertiary();
            font-size: $font-size__base--desktop;
            font-weight: $font-weight__semibold;
            font-family: $font-family__secondary;
            letter-spacing: normal;
            display: none;

            &::after {
                content: none;
            }

            &:not(.label) {
                display: unset !important; //To override main css
                background: transparent;
                position: absolute;
                top: 7px;
                left: 0;
                font-size: 0;
                width: 16px;
                height: 14px;
            }
        }

        @include min-screen($screen__m) {
            > span {
                // these nested <span>s are automatically excluded as screen-reader content
                // by theme-blank-sass, but it's where "Next"/"Previous" reside
                @include lib-visually-hidden-reset();
            }

            &::before,
            .label {
                display: none;
            }
        }
    }

    &.next::before {
        display: none;
    }
}