.form.wishlist.items {
    .actions-toolbar {
        margin-left: 0;
    }
}

.form-wishlist-items .label {
    color: $text__color;
}

.product-options-bottom,
.block-bundle-summary {
    .action.towishlist {
        @include button--inline-link();
        margin-bottom: 10px;
        vertical-align: top;
        text-transform: uppercase;
        font-family: $heading__font-family__base;
    }
}

.products.list.items,
.table-comparison {
    .action {
        &.towishlist {
            @extend .abs-actions-addto-gridlist;
            @include lib-icon-font-symbol($icon-wishlist-empty);
        }
    }
}

.cms-index-index .bluefoot-product-list {
    .products.list.items,
    .table-comparison {
        .action {
            &.towishlist::before {
                content: none;
            }
        }
    }
}

.account .table-wrapper .data.table.wishlist {
    @include lib-table-bordered($_table_type: horizontal);

    thead > tr > th {
        border-bottom: 0;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $table__border-color;
    }

    .product.name {
        display: inline-block;
        margin-bottom: $indent__s;
    }

    .box-tocart {
        margin: $indent__s 0;

        .qty {
            @extend .abs-input-qty;
            vertical-align: middle;
        }
    }

    .col {
        &.item {
            width: 50%;
        }

        &.photo {
            max-width: 150px;
        }

        &.selector {
            max-width: 15px;
        }
    }

    textarea {
        margin: $indent__s 0;
    }

    .input-text.qty {
        margin-bottom: $indent__s;
    }

    .action.primary {
        vertical-align: top;
    }

    .price {
        font-weight: $font-weight__bold;
    }
}

.block-wishlist {
    .block-title {
        @extend .abs-block-title;
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .product-item-name {
        margin-right: $indent__m;
    }
}

.products-grid.wishlist {
    .product {
        &-item {
            &-photo {
                display: block;
                margin-bottom: $indent__s;
            }

            &-name {
                margin: 0;
                display: inline-block;
                vertical-align: middle;
            }

            .price-box {
                margin: 0;
            }

            .comment-box {
                .label {
                    @include lib-visually-hidden();
                }
            }

            &-comment {
                display: block;
                height: 42px;
                margin: $indent__s 0;
            }

            &-actions > *:last-child {
                margin-right: 0;
            }

            .field.qty,
            .product-item-actions {
                padding-right: $indent__s;
                display: inline-block;
                vertical-align: bottom;
                margin-bottom: $indent__s;
            }

            .box-tocart input.qty {
                @extend .abs-input-qty;
            }
        }
    }
}

.wishlist.window.popup {
    .field {
        &.choice {
            > .label {
                @include min-screen($screen__m) {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-grid.wishlist .product-item {
        border-bottom: 1px solid $c-border;

        &:first-child {
            border-top: 1px solid $c-border;
        }
    }
}

@include max-screen($screen__m) {
    .products-grid.wishlist {
        margin-bottom: $indent__l;
        margin-right: -$indent__s;
        .product {
            &-item {
                padding: $indent__base $indent__s $indent__base $indent__base;
                position: relative;

                &-photo {
                    float: left;
                    margin-right: $indent__base;
                }

                &-name {
                    font-size: 16px;
                }

                &-actions {
                    display: inline-block;
                    vertical-align: middle;

                    .action {
                        &:last-child {
                            margin-right: 0;
                        }

                        &.edit {
                            @extend .abs-account-actions-mobile;
                        }
                    }
                }

                .box-tocart {
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: 0;
                    margin-right: $indent__base;

                    .stock {
                        margin-top: 7px;
                    }
                }

                .giftregisty-dropdown {
                    display: none;
                }
            }

            &-image-container {
                max-width: 80px;
            }
        }
    }

    //
    //  Grid view for wishlist
    //  -----------------------------------------

    .wishlist-index-index {
        .product {
            &-item {
                width: 100%;
                &-info {
                    width: auto;
                }
            }
        }
    }
}

@include min-screen($screen__s) {
    .wishlist-index-index {
        .products-grid {
            .product-item {
                margin-bottom: $indent__base;
            }

            .product-item-actions {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .products-grid.wishlist {
        .product {
            &-item {
                &-tooltip {
                    display: inline-block;
                }

                &-actions {
                    margin: $indent__s 0 0;
                }

                .fieldset .field.qty .label {
                    width: auto;
                }

                .box-tocart {
                    .actions-primary {
                        margin: 0;
                    }

                    .stock {
                        margin: $indent__base 0 0;
                    }
                }
            }
        }
    }

    .wishlist-index-index {
        .product {
            &-item {
                &-info {
                    width: 240px;
                }
            }
        }

        .main {
            .form-wishlist-items {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;

                    .action {
                        margin-right: $indent__s;
                    }
                }
            }
        }
    }
    .products-grid.wishlist .product-item-actions > * {
        display: block;
    }
}

@include min-screen($screen__l) {
    .wishlist-index-index {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                width: percentage(1/3);
                box-sizing: border-box;

                &:nth-child(3n + 1) {
                    padding-left: 0;
                }
            }
        }
    }
}

.wishlist.split.button {
    .product-addto-links &,
    .actions-toolbar & {
        @include button--split;
    }
}
