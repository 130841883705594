//
//  Common
//  _____________________________________________

.block-search {
    margin-bottom: 0;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include icon-svg($icon : search,
            $fill : $header-icons-color,
            $fill-hover : $header-icons-color-hover,
            $width : 23px);
        display: flex;
        align-items: center;
        min-height: $header__icon-height;
        margin-top: 2px;
        margin-right: 70px;
        float: right;
        cursor: pointer;

        &.active {
            @include icon-svg($icon : search,
                $fill : $header-icons-color-hover,
                $width : 23px);

            + .control {
                .actions {
                    display: block;
                }

                input {
                    position: static;
                }
            }
        }

        @include max-screen($mobile-nav__breakpoint) {
            border: 1px solid $c-gray-150;
            border-radius: $header-icons-border-radius-mobile;
            padding: 3px 4px;
            margin-right: 10px;
            margin-top: 0;
            min-height: 27px;

            &::before {
                width: 19px;
                height: 19px;
                padding: 3px;
            }
        }
    }

    .action.search {
        display: none;
    }

    .control {
        position: relative;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0 $layout__width-xs-indent;
        clear: both;

        @include min-screen($screen__s) {
            max-width: 100%;
        }

        .actions {
            @include max-screen($mobile-nav__breakpoint) {
                display: none;
            }
        }
    }

    .tooltip-start {
        text-decoration: none;
    }

    .image-search {
        border: 1px solid $c-gray-150;
        border-radius: $header-icons-border-radius;
        padding: 5px 13px;
        height: 18px;
        width: 18px;
        position: absolute;
        top: 0px;
        right: 0;

        @include max-screen($mobile-nav__breakpoint) {
            border-radius: $header-icons-border-radius-mobile;
            padding: 7.5px;
        }

        &.tooltip-button {
            right: auto;
            margin: 0 auto;
            text-decoration-line: none;

            &.primary {
                margin: 0;
                position: relative;
                width: auto;
            }
        }

        &:hover,
        &:focus {
            background: none;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }

    input {
        left: -300%;
        margin: 15px 0;
        position: absolute;
    }

    .nested {
        display: none;
    }

    .action.search {
        @include icon-svg($icon : search,
            $fill : $header-icons-color,
            $fill-hover : $header-icons-color-hover,
            $width : 23px);
        @include lib-button-reset();
        @include vertically-center($position: absolute);
        display: inline-block;
        padding: $indent__xs 0;
        right: auto;
        left: -29px;
        z-index: 1;
        opacity: 1 !important;
        width: 23px;

        @include max-screen($mobile-nav__breakpoint) {
            right: 20px;
            width: auto;
        }

        &:focus {
            &:before {
                color: $color-gray20;
            }
        }

        &::before {
            height: 23px;
            width: 23px;
            margin-right: 0;

            @include max-screen($mobile-nav__breakpoint) {
                display: none;
            }
        }
    }

    .field.search {
        @include lib-tooltip(bottom);

        .tooltip-content {
            left: 0;
            text-align: center;
            width: 200px;

            &::before,
            &::after {
                left: auto;
                right: 33px;
            }

            &::after {
                right: 34px;
            }

            .tooltip-header {
                font-size: 28px;
                font-weight: $font-weight__regular;
                margin: 0;
            }

            .tooltip-title {
                font-size: 16px;
                color: #000;
                margin: 10px 0 0 0;
            }

            .tooltip-text {
                font-size: 14px;
                color: #20201f;
                margin: 0 30px 10px;
            }

            .primary.button.tooltip-button {
                border: none;
                color: white;
                background: $c-brown;
                padding: 10px 30px;
                margin: 0 auto;
                text-decoration: none;
            }

            .tooltip-close {
                @include _lib-popup-button-close-icon(true,
                    $icon-remove,
                    $icon-font,
                    $popup-icon-font__size,
                    $popup-icon-font__line-height,
                    $popup-icon-font__color,
                    $popup-icon-font__color-hover,
                    $popup-icon-font__color-active,
                    inherit,
                    top,
                    $button-icon__position,
                    true);

                position: absolute;
                top: 5px;
                right: 20px;
                text-decoration: none;
            }
        }
    }


    @include max-screen($mobile-nav__breakpoint) {
        .image-search {
            margin: 0;
            right: 58px;
            top: 0;
        }

        .image-search.tooltip-button.primary {
            position: relative;
            width: auto;
            right: auto;
            top: auto;
        }

        .field.search .control .tooltip-content {
            max-width: 150px;
            right: 50px;
            left: unset;

            &::before {
                left: unset;
                right: 45px;
            }

            &::after {
                left: unset;
                right: 46px;
            }
        }
    }
}

.search-autocomplete {
    box-sizing: border-box;
    display: none;
    margin-top: -15px;
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    z-index: $dropdown-list__z-index;

    ul {
        @include lib-list-reset-styles();

        li {
            border-top: $autocomplete-item__border;
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            @include hover {
                background: $autocomplete-item__hover__color;
            }

            &:not(:empty) {
                border-top: 0;
                border: $autocomplete__border;
                background: $autocomplete__background-color;
            }

            &:first-child {
                border-top: none;
            }

            &.selected {
                background: $autocomplete-item__hover__color;
            }

            .amount {
                color: $autocomplete-item-amount__color;
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($mobile-nav__breakpoint) {
    .block-search {
        .block-content {
            margin-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($mobile-nav__breakpoint) {
    .block-search {
        float: right;
        width: 230px;
        padding-left: 0;
        margin-top: 15px;
        z-index: 101; // Ensure that search appears over mega menu nav items

        @include min-screen($screen__l) {
            width: 250px;
            padding-left: 15px;
        }

        .label {
            @include lib-visually-hidden();
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder() {
                color: $form-element-input-placeholder__color;
            }

            margin: 0;
            padding-right: 35px;
            position: static;
        }

        form input {
            height: 35px;
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}

.catalogsearch-result-index .columns .sidebar-main {
    order: 0;
}