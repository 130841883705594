$heading-border-breakpoint: 370px;
$letter-font-size: 34px;

.cleaning-blocks {
    @include max-screen($screen__m) {
        padding-left: 0 !important; // override PageBuilder
    }

    @include min-screen($screen__m) {
        flex-direction: row !important; //override pagebuilder inline styles
    }

    .cleaning-left,
    .cleaning-right {
        width: 100% !important; //override pagebuilder inline styles

        @include min-screen($screen__m) {
            width: 50% !important; //override pagebuilder inline styles
        }
    }
}

.cleaning-banner {
    h1 {
        font-size: $font-size__banner;

        @include min-screen($screen__m) {
            font-size: $font-size__banner--desktop;
        }
    }

    .pagebuilder-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important; // override PageBuilder
        min-height: 80px !important; // override PageBuilder

        @include min-screen($screen__m) {
            min-height: 335px !important; // override PageBuilder
        }
    }
}
    
.care-and-cleaning {
    .pagebuilder-column-group {
        flex-direction: column;

        @include min-screen($screen__l) {
            flex-direction: row;
        }
    }

    .letter {
        @include max-screen($screen__l) {
            width: 100% !important; //override pagebuilder inline styles
        }

        p {
            height: 50px;
            width: 50px;
            text-align: center;
            border-radius: 50%;
            background: $c-ikat;
            font-family: $font-family__secondary;
            font-style: italic;
            color: $color-white;
            font-size: $letter-font-size;
            line-height: 1.2;
            margin: 0;
        }
    }
        

    .cleaning-methods p {
        margin: 0;

        @include min-screen($screen__l) {
            margin-left: $indent__l;
        }
    }

    .cleaning-methods p:nth-of-type(2n) {
        margin-bottom: $indent__s;

        @include min-screen($screen__l) {
            margin-left: $indent__l;
        }
    }
}

.fiber-cleaning {
    @include max-screen($screen__m) {
        .pagebuilder-column {
            width: 100% !important; //override pagebuilder inline styles
        }
    }

    .fibers {
        padding-bottom: $indent__base;

        @include min-screen($screen__m) {
            border-right: 1px solid $c-border;
        }
    }

    .pagebuilder-column-group {
        flex-direction: column;

        @include min-screen($screen__m) {
            flex-direction: row;
        }
    }

    hr {
        margin: 0;
    }

    .cleaning-recommendations {
        margin-left: 0;

        @include min-screen($screen__m) {
            margin-left: 15px;
        }

        ul {
            padding-left: 15px;
        }
    }
}

.heading-border {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 0;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1; //push pseudo element behind title

        @include min-screen($heading-border-breakpoint) {
            border-top: 1px solid $c-border;
        }
    }

    h4 {
        background: $color-white;
        display: inline;
        color: $c-medium-grey;

        @include min-screen($heading-border-breakpoint) {
            padding-left: $indent__xl;
            padding-right: $indent__xl;
        }
    }
}
