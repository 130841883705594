$absolutely-postioned-spacing: 30px !default;

@mixin hide-for-small() {
    @include max-screen($screen__s) {
        display: none;
    }
}

@mixin hide-for-desktop() {
    @include min-screen($screen__m) {
        display: none;
    }
}

// vertically aligns any element
@mixin vertically-center($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}

/**
 * Adds intrinsic ratio to a video container, allowing it
 * to be responsive on a ratio of either 16:9 (widescreen)
 * or 4:3 (standard)
 *
 * See: https://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
@mixin responsive-video($is-ratio-standard: null) {
    $ratio-percentage: 56.25%; // 16:9

    @if ($is-ratio-standard == true) {
        $ratio-percentage: 75%; // 4:3
    }
    position: relative;
    padding-bottom: $ratio-percentage;
    height: 0;

    iframe,
    object {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// offsets negative bluefoot margin
@mixin bluefoot-reset() {
    margin-left: -$bluefoot-row__margin;
    margin-right: -$bluefoot-row__margin;
}

// Positioning -- Desktop only
@mixin left-top() {
    position: absolute;
    top: $absolutely-postioned-spacing;
    right: auto;  // force reset
    bottom: auto;  // force reset
    left: $absolutely-postioned-spacing;
    transform: none; // force reset
}

@mixin left-center() {
    @include vertically-center($position: absolute);
    right: auto;  // force reset
    bottom: auto; // force reset
    left: $absolutely-postioned-spacing;
}

@mixin left-bottom() {
    position: absolute;
    top: auto; // force reset
    right: auto;  // force reset
    bottom: $absolutely-postioned-spacing;
    left: $absolutely-postioned-spacing;
    transform: none; // force reset
}

@mixin center-top() {
    position: absolute;
    top: $absolutely-postioned-spacing;
    right: auto;  // force reset
    bottom: auto;  // force reset
    left: 50%;
    transform: translateX(-50%);
}

@mixin center-center() {
    position: absolute;
    top: 50%;
    right: auto;  // force reset
    bottom: auto; // force reset
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin center-bottom() {
    position: absolute;
    top: auto; // force reset
    right: auto;  // force reset
    bottom: $absolutely-postioned-spacing;
    left: 50%;
    transform: translateX(-50%);
}

@mixin right-top() {
    position: absolute;
    top: $absolutely-postioned-spacing;
    right: $absolutely-postioned-spacing;
    bottom: auto;  // force reset
    left: auto;  // force reset
    transform: none; // force reset
}

@mixin right-center() {
@include vertically-center($position: absolute);
    right: $absolutely-postioned-spacing;
    bottom: auto; // force reset
    left: auto;  // force reset
}

@mixin right-bottom() {
    position: absolute;
    top: auto; // force reset
    right: $absolutely-postioned-spacing;
    bottom: $absolutely-postioned-spacing;
    left: auto;  // force reset
    transform: none; // force reset
}

@mixin bottom-offset($offset) {
    position: absolute;
    right: auto;
    left: 50%;
    transform: translate(-50%, $offset);
}

@include min-screen($screen__m) {
    .left-top {
        @include left-top();
    }

    .left-center {
        @include left-center();
    }

    .left-bottom {
        @include left-bottom();
    }

    .center-top {
        @include center-top();
    }

    .center-center {
        @include center-center();
    }

    .center-bottom {
        @include center-bottom();
    }

    .right-top {
        @include right-top();
    }

    .right-center {
        @include right-center();
    }

    .right-bottom {
        @include right-bottom();
    }
}

.visuallyhidden {
    @include lib-visually-hidden();

    /**
     * Extends the .visuallyhidden class to allow the element to be focusable
     * when navigated to via the keyboard
     */
    &.focusable {
        &:active,
        &:focus {
            @include lib-visually-hidden-reset();
        }
    }
}