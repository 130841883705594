$screen__top-content-wrap: 580px;

.klevu-top-content__wrapper {
    background-color: $c-gray-250;
    padding: 12px $indent__base;
    margin: 0 (-$indent__base);

    @media (max-width: $screen__xs) {
        padding: 12px $indent__s;
        margin: 0 (-$indent__s);
    }

    a,
    .kuFilterSelect a.kuFilterLbl,
    .kuFilterSelect a.kuFilterLbl > .count {
        text-transform: uppercase;
        font-size: $font-size__base;
        font-weight: $font-weight__regular;
        line-height: 1;

        @include hover {
            background-color: $c-brown;
            color: $c-white;
        }
    }

    .kuFilterSelect a.kuFilterLbl:hover > .count {
        background-color: $c-brown;
        color: $c-white;
    }

    a,
    .kuFilterSelect a.kuFilterLbl {
        display: inline-block;
        text-decoration: none;
        padding: 8px 16px;
        margin-right: 6px;
        border-radius: 12px;

        &.contentSelect {
            background-color: $c-brown;
            color: $c-white;
        }
    }

    .page-title {
        display: inline-block;
    }

    .col-left-first {
        display: inline-block;

        @media (max-width: $screen__top-content-wrap) {
            margin-top: 8px;
        }

        button {
            @include lib-button-reset();
        }

        #narrow-by-list {
            margin: 0;

            dt {
                display: none;
            }

            dd {
                margin: 0;
            }

            ol {
                display: flex;
                flex-direction: row;
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    border: 0;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}
