.cms-brands .columns {
    @include max-screen($screen__m) {
        .pagebuilder-column-group {
            flex-direction: column;

            .pagebuilder-column {
                width: 100% !important; // override PageBuilder
            }
        }
    }

    [data-content-type="row"],
    [data-content-type="divider"] {
        @include min-screen($screen__l) {
            max-width: $layout__max-width;
            width: 100%;
            margin: 0 auto;
        }
    }

    .details-column {
        @include min-screen($screen__m) {
            padding-left: $indent__l;
        }

        img {
            max-width: 95px !important; // overrides PageBuilder
            width: 100%;

            @include min-screen($screen__m) {
                max-width: 150px !important; // overrides PageBuilder;
            }

            @include min-screen($screen__l) {
                max-width: 240px !important; // overrides PageBuilder;
            }
        }

        [data-content-type="button-item"] {
            @include max-screen($screen__s) {
                width: 100%;
            }
        }

        .pagebuilder-button-primary {
            margin-top: $indent__m;
        }
    }

    .image-column {
        align-items: center;
        margin-top: $indent__l;

        @include min-screen($screen__m) {
            margin-top: 0;
        }

        img {
            width: 100%;

            @include min-screen($screen__m) {
                max-width: 400px !important; // overrides PageBuilder
            }
        }
    }
}
