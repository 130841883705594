$related-products__item__margin-bottom: $indent__s !default;
$related-products__price-box__margin-bottom: $indent__s !default;

.block.related {
    @include min-screen($screen__l) {
        margin-left: $indent__base;
    }

    .action.select {
        @include button--inline-link();
        vertical-align: baseline;
    }

    .block-title {
        > * {
            @include max-screen($screen__m) {
                margin-bottom: $indent__s;
            }

            @include min-screen($screen__l) {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    .block-title.title h3 {
        font-size: 14px;
        font-weight: $font-weight__bold;
        color: $c-vignette;
        line-height: 1.425;
        letter-spacing: 1.9px;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;

        @include min-screen($screen__m) {
            font-size: 16px;
        }
    }

    .block-actions {
        padding-bottom: 15px;

        @include min-screen($screen__l) {
            border-bottom: $border-width__base solid $c-border;
        }
    }

    .product.photo {
        text-align: center;
    }
}

:root .products-related > .products {
    margin-left: 0;
}

.products-related {
    margin-top: 0;

    .products {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;

        @include min-screen($screen__l) {
            flex-direction: column;
        }

        .product-item {
            width: 50%;
            margin-bottom: $related-products__item__margin-bottom;
            padding-left: $indent__s;

            @include min-screen($screen__m) {
                width: 25%;
                margin-bottom: 0;
                padding-top: $indent__s;
                padding-left: 0;
                padding-bottom: $indent__s;
            }

            @include min-screen($screen__l) {
                width: 100%;

                &:not(:last-child) {
                    border-bottom: $border-width__base solid $c-border;
                }
            }

            .product-item-info {
                width: 100%;
            }

            .product-item-actions {
                margin: 0;
                margin-top: $indent__s;
            }

            .price-box {
                margin-top: 0;
                margin-bottom: $related-products__price-box__margin-bottom;
            }
        }
    }
}
