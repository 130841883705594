//
//  Variables
//  _____________________________________________

$checkout-payment-method-title__border        : $checkout-shipping-method__border !default;
$checkout-payment-method-title__padding       : $checkout-shipping-method__padding !default;
$checkout-payment-method-title-mobile__padding: 15px !default;

$checkout-payment-method-content__padding__xl : 0 !default;

$checkout-billing-address-details__line-height: 27px !default;
$checkout-billing-address-details__padding    : 0 0 0 23px !default;
$checkout-billing-address-form__max-width     : $checkout-shipping-address__max-width !default;

//
//  Common
//  _____________________________________________

.payment .field.choice {
    @include min-screen($screen__m) {
        max-width: $form-field__width__desktop;
    }
}

.checkout-payment-method {
    .step-title {
        margin-bottom: 0;
        border-bottom: $checkout-step-title__border;
    }

    .payment-method {
        &:first-child {
            .payment-method-title {
                border-top: 0;
            }
        }

        &._active {
            .payment-method-content {
                display: block;

                @include max-screen($screen__m) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .payment-method-content {
        margin-left: $indent__m;

        & > .actions-toolbar {
            & > .primary {
                float: none;

                .action {
                    &.primary {
                        @include lib-button-primary();

                        @include max-screen($screen__xs) {
                            width: 100%;
                        }
                    }
                }

                .place-order-message {
                    max-width: 460px;
                    margin-bottom: 0;
                }
            }

            .place-order-message {
                max-width: 460px;
                margin-bottom: 0;
            }
        }

        .payment-method-content { 
            margin: 0;
        }
    }

    .payment-method-title {
        padding: $checkout-payment-method-title__padding 0;
        margin: 0;

        .payment-icon {
            display: inline-block;
            margin-right: $indent__xs;
            vertical-align: top;
            transform: translateY(-10px); // positions image with the radio button
        }

        .action-help {
            display: inline-block;
            margin-left: $indent__xs;
        }
    }

    .payment-method-content {
        display: none;

        padding: 0 0 $indent__base $checkout-payment-method-content__padding__xl;
        .fieldset {
            &:not(:last-child) {
                margin: 0 0 $indent__base;
            }
        }
    }

    .field-select-billing,
    .billing-address-form {
        max-width: $checkout-billing-address-form__max-width;
    }

    .billing-address-same-as-shipping-block {
        margin: 0 0 $indent__s;
    }

    .payment-method-billing-address {
        margin: 0 0 $indent__base;

        .primary {
            display: flex;
            align-items: baseline;
            margin-top: $indent__base;

            .action-update {
                @include lib-button();
                margin-right: 0;
            }
        }

        @include max-screen($screen__xs) {
            .primary {
                display: block;

                .action-update {
                    width: 100%;
                }

                .action-cancel {
                    margin-left: 0;
                }
            }
        }

        .action-cancel {
            @include button--inline-link();
            margin-left: $indent__s;
        }

        .billing-address-details {
            line-height: $checkout-billing-address-details__line-height;
            padding: $checkout-billing-address-details__padding;

            .action-edit-address {
                @include button--inline-link();
            }
        }
    }

    .payment-method-note {
        & + .payment-method-billing-address {
            margin-top: $indent__base;
        }
    }

    .field-select-billing {
        & > .label {
            @include lib-visually-hidden();
        }
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .no-payments-block {
        margin: $indent__base 0;
    }

    .payments {
        .legend {
            @include lib-visually-hidden();
        }
    }
}

.braintree-paypal-fieldset {
    border: none;
}

.field-tooltip .field-tooltip-action:before {
    color: $c-vignette;

    @include hover {
        color: $c-dhurrie;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-payment-method {
        .payment-method-content {
            padding: 0 $checkout-payment-method-title-mobile__padding $indent__base;
        }

        .payment-method-billing-address {
            .action-cancel {
                margin-top: $indent__s;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-method {
        .payment-methods {
            .actions-toolbar {
                .primary {
                    margin: 0;

                    .action-cancel {
                        margin-left: $indent__s;
                    }
                }
            }
        }

        .fieldset {
            & > .field-select-billing {
                & > .control {
                    float: none;
                    width: 100%;
                }
            }
        }

        .payment-method-content {
            .fieldset {
                & > .field {
                    margin: 0 0 $indent__base;

                    &.type {
                        &.no-detection {
                            .control {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }

        .action-cvv {
            margin-left: $indent__xs;
        }
    }

    .payment-method-billing-address {
        .action-update {
            float: right;
        }

        .actions-toolbar {
            .action-cancel {
                margin: 6px $indent__base 0 0;
            }
        }
    }
}
