$pb_circle-color: #c8ccd4;
$pb_path-color: #20150B;

// All options start hidden
#html-body [data-content-type="row"] div[class*='pb-option-'],
div[class*='pb-option-']{
    display: none;

    .pagebuilder-button-primary {
        text-decoration: none; // override .cms-page-view underline
    }
}

.pb-open-account-page {
    max-width: 733px; // Page width accomodating padding
    margin: auto;
}

.pb-revamp-header h1 {
    font-size: 18px !important; // override page builder styles

    @include min-screen($screen__m) {
        font-size: 22px !important; // override page builder styles
    }
}

.pb-revamp-subtext h1 {
    font-size: 16px !important; // override page builder styles

    @include min-screen($screen__m) {
        font-size: 20px !important; // override page builder styles
    }
}

.pb-account-radio {
    @include min-screen($screen__m) {
        padding-left: $indent__s;
    }

    .pagebuilder-column-group {
        @include max-screen($screen__m) {
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    .pb-radio-col {
        width: 40px !important; //override page builder styles

        @include max-screen($screen__m) {
            flex-basis: 40px !important; //override page builder styles
        }
    }

    .pagebuilder-column:not(.pb-radio-col) {
        @include max-screen ($screen__m) {
            flex-basis: auto;
        }
    }

    .btn-radio {
        cursor: pointer;
        display: inline-block;
        float: left;
        user-select: none;

        &:not(:first-child) {
            margin-left: $indent__base;

            @include max-screen($screen__xs) {
                display: block;
                float: none;

                &:not(:first-child) {
                    margin-left: 0;
                    margin-top: 15px;
                }
            }
        }
    }

    .btn-radio-text {
        cursor: pointer;

        & span {
            font-size: 14px !important; //override page builder styles

            @include min-screen($screen__m) {
                font-size: 15px !important; //override page builder styles
            }
        }
    }

    // animation from client codepen: https://codepen.io/avstorm/pen/NwyQEX

    svg {
        fill: none;
        vertical-align: middle;

        circle {
            stroke-width: 2;
            stroke: $pb_circle-color;
        }

        path {
            stroke: $pb_path-color;

            &.inner {
                stroke-width: 6;
                stroke-dasharray: 19;
                stroke-dashoffset: 19;
            }

            &.outer {
                stroke-width: 2;
                stroke-dasharray: 57;
                stroke-dashoffset: 57;
            }
        }
    }

    input {
        display: none;

        &:checked+svg {
            path {
                transition: all .4s ease;

                &.inner {
                    stroke-dashoffset: 38;
                    transition-delay: .3s;
                }

                &.outer {
                    stroke-dashoffset: 0;
                }
            }
        }
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }

    p {
        margin: $indent__s 0;
    }
}

.pb-form-create-account {
    padding-right: $indent__s;
    padding-left: $indent__s;

    .account-create-instructions {
        display: none;
    }

    form.form.create.account {
        @include min-screen($screen__m) {
            width: 100%;
        }

        fieldset {
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background-position: left top;
            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: scroll;

            &::before {
                margin-left: auto;
                flex-basis: 100%; // Right align the pseudoelement on Safari
            }
        }

        .field {
            width: 100%;

            @include min-screen($screen__m) {
                width: calc(50% - #{$indent__l});
                flex-basis: calc(50% - #{$indent__l}); // Fixes safari flex issues 
            }
        }

        fieldset.create.account {
            .field:first-of-type {
                width: 100%;
                flex-basis: 100%;
            }
        }
    }

    .control,
    .field.password .control:last-child,
    .control input,
    .control select {
        @include min-screen($screen__s) {
            max-width: 100%;
        }
    }
}

.pb-sign-in-btn, .pb-dealer-locator {
    [data-content-type="button-item"] {
        @include max-screen($screen__xs) {
            width: 100%;

            .pagebuilder-button-primary {
                width: 100%;
            }
        }
    }
}
