.product.data.items {
    @include bryant-park-lib-data-accordion();
    margin-bottom: $indent__base;
    border: $tab-control__border-width solid $c-border;
    border-top: none;

    ul {
        padding-left: $indent__s;
    }

    > .item.title > .switch > span {
        @include lib-visually-hidden-reset();
    }

    .data.item .counter {
        &::before {
            content: '(';
        }

        &::after {
            content: ')';
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product.data.items {
        @include lib-data-tabs(
            $_tab-control-padding-top       : 12px,
            $_tab-control-padding-bottom    : 12px,
            $_tab-control-padding-left      : 35px,
            $_tab-control-padding-right     : 35px,
            $_tab-content-margin-top        : 65px,
            $_tab-control-margin-right      : -1px,
            $_tab-control-font-size         : $tab-control__font-size,
            $_tab-control-background-color  : $c-berber,
            $_tab-content-border-top-status : false
        );
        border: none;

        a {
            @include bryant-park-headings-h6();
            letter-spacing: $button__letter-spacing;
        }

        > .item.title {
            &:not(.disabled).active > .switch {
                padding-bottom: 13px;

                @include hover {
                    padding-bottom: 13px;
                    color: $text__color;
                }
            }

            &:not(.disabled) > .switch {
                @include hover {
                    color: $link__hover__color;
                }
            }

            > .switch::after {
                display: none;
            }
        }

        & > .item.content {
            border-color: #dcdcdc;
        }

        .description {
            max-width: 900px;
        }
    }
}

@include max-screen($screen__m) {
    .product.data.items {
        @include bryant-park-lib-data-accordion(
            $_accordion-control-height: 45px,
            $_accordion-control-padding-top: $indent__xs,
            $_accordion-control-padding-left: 0,
            $_accordion-control-padding-right: 0,
            $_accordion-control-font-weight: $font-weight__bold
        );
        border-bottom: 1px solid $c-border;

        > .item.title > .switch::after {
            position: absolute;
            top: 20px;
            right: 0;
        }

        > .item.content {
            padding-bottom: $indent__base;
        }

        .product.data.items .data.item:last-child {
          border: $tab-control__border-width solid $c-border;
        }
        .data.item {
            display: block;
            position: relative;

            &.content div {
              border: $tab-control__border-width solid $c-border;
            }

            p {
                margin-top: 0;
            }
        }
    }
}
