.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.border-right {
    @include min-screen($screen__m) {
        border-right: 1px solid $secondary__color;
    }
}

@include min-screen($screen__m) {
    .left-top {
        @include left-top();
    }

    .left-center {
        @include left-center();
    }

    .left-bottom {
        @include left-bottom();
    }

    .center-top {
        @include center-top();
    }

    .center-center {
        @include center-center();
    }

    .center-bottom {
        @include center-bottom();
    }

    .right-top {
        @include right-top();
    }

    .right-center {
        @include right-center();
    }

    .right-bottom {
        @include right-bottom();
    }
}

.cms-page-view .page-main .custom-buttons a {
    text-decoration: none;
}

.custom-buttons .button {
    width: 100%;

    @include min-screen($screen__s) {
      width: auto;
    }
}

.button-with-arrow {
    @include button--tertiary();
    @include button-with-arrow();

    &::after {
        background-color: transparent;
        position: relative;
        top: 0;
    }
}

.info-tout {
    margin-bottom: $indent__s;

    @include min-screen($screen__s) {
        margin-top: $indent__l * 2;
    }

    .bluefoot-column {
        padding-bottom: $indent__s !important; // Override Bluefoot
        padding-left: $indent__s !important; // Override Bluefoot
        padding-right: $indent__s !important; // Override Bluefoot
        border-bottom: 1px solid $secondary__color;

        @include min-screen($screen__s) {
            padding-left: $indent__base !important; // Overrides Bluefoot
            padding-right: $indent__base !important; // Overrides Bluefoot
            border-bottom: none;
        }

        @include min-screen($screen__m) {
            padding-left: $indent__l !important; // Overrides Bluefoot
            padding-right: $indent__l !important; // Overrides Bluefoot
            border-bottom: none;
        }

        @include min-screen($screen__l) {
            padding-left: 60px !important; // Overrides Bluefoot
            padding-right: 60px !important; // Overrides Bluefoot
            border-bottom: none;
        }

        &:last-child {
            border-bottom: none
        }
    }

    &__title {
        margin-top: $indent__m !important; // Override Bluefoot
        margin-bottom: 15px !important; // Override Bluefoot

        @include min-screen($screen__s) {
            margin-top: 0 !important; // Override Bluefoot
        }

        @include min-screen($screen__m) {
            margin-bottom: $indent__m !important; // Override Bluefoot
        }
    }
}

.image-touts {
    margin-bottom: 15px !important; // Overrides Bluefoot

    @include min-screen($screen__m) {
        margin-top: 60px !important; // Overrides Bluefoot        
        margin-bottom: 35px !important; // Overrides Bluefoot
    }

    .cms-index-index & {
        margin-bottom: 0 !important; // Overrides Bluefoot

        @include min-screen($screen__m) {
            margin-bottom: 60px !important; // Overrides Bluefoot
        }
    }

    .bluefoot-column {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.media-tout {
    /* Overrides padding in bluefoot block admin. User input would negatively
    /* affect styling causing styling to defer from design
    */
    padding-top: 35px !important; 
    padding-bottom: 35px !important; 

    .bluefoot-wrapper div:first-child {
         @include min-screen($screen__s) {
            margin-bottom: 0;
            padding-right: $indent__base;
         }
    }

    .pagebuilder-column-group {
        @include max-screen($screen__m) {
            flex-direction: column;
        }

        & > div:first-child {
            margin-bottom: $indent__base !important; // Overrides PageBuilder;
        }
    }

    &__text {
        box-sizing: border-box;

        @include max-screen($screen__m) {
            width: 100% !important; // Overrides PageBuilder
        }

        @include min-screen($screen__m) {
            padding: 100px 60px !important; //Overrides Bluefoot  
            flex-basis: 40%;
        }

        @include min-screen($screen__l) {
            padding-left: 50px !important; //Overrides Bluefoot  
            padding-right: 50px !important; //Overrides Bluefoot  
            padding-top: 70px !important; //Overrides Bluefoot 
            padding-bottom: 0 !important; //Overrides Bluefoot  
            flex-basis: 33%;
        }

        @include min-screen($screen__xl) {
            padding-left: 100px !important; //Overrides Bluefoot  
            padding-right: 100px !important; //Overrides Bluefoot  
            padding-top: 100px !important; //Overrides Bluefoot 
            padding-bottom: 0 !important; //Overrides Bluefoot  
        }

        &__heading {
            margin-top: 0 !important; //Overrides Bluefoot  
        }
    }

    &__media {
        @include max-screen($screen__m) {
            width: 100% !important; //Overrides PageBuilder
        }

        @include min-screen($screen__m) {
            flex-grow: 1;
        }

        @include min-screen($screen__l) {
            flex-basis: 66%;
        }
    }

    .bluefoot-wrapper {
        @include min-screen($screen__m) {
            display: flex;
        }
    }
}

.contact-info-banner {
    padding-top: $indent__base !important; // Override Bluefoot
    padding-bottom: $indent__base !important; // Override Bluefoot

    @include min-screen($screen__m) {
        padding-top: 40px !important; //Override Bluefoot
        padding-bottom: 40px !important; //Override Bluefoot
    }

    &__heading,
    .bluefoot-textarea,
    .bluefoot-textarea p {
        margin: 0 !important; // Override Bluefoot
    }

    &__heading {
        margin-bottom: $indent__base !important; // Override Bluefoot
        color: $c-vignette;
    }

    .bluefoot-textarea p {
        margin-bottom: 15px !important; // Override Bluefoot
    }

    .bluefoot-column {
        padding-top: $indent__base !important; // Override Bluefoot
        padding-bottom: 15px !important; // Override Bluefoot

        @include min-screen($screen__m) {
            padding-top: 0 !important; //Override Bluefoot
            padding-bottom: 0 !important; //Override Bluefoot
        }
    }
}

.icon-block {
    display: flex;
    flex-direction: column;
    max-width: 150px;
    align-items: center;
    text-align: center;

    img {
        width: 24px;
        height: 24px;
    }
}

.cms-nourison-hospitality-digest,
.cms-nourison-hospitality-landing-page {
    .media-tout,
    .fullbleed-block {
        margin-top: $indent__m;
        padding-bottom: 40px !important;

        .bluefoot-wrapper {
            @include min-screen($screen__m) {
                display: flex;
                align-items: center;
            }
        }
        
        &__text {
            padding-top: 35px !important;

            @include min-screen($screen__m) {
                padding: 50px 20px 0 20px !important; //Overrides Bluefoot
                flex-basis: 50%;
            }

            @include min-screen($screen__l) {
                padding-left: 30px !important; //Overrides Bluefoot
                padding-right: 30px !important; //Overrides Bluefoot
            }

            @include min-screen($screen__xl) {
                padding-left: 70px !important; //Overrides Bluefoot
                padding-right: 70px !important; //Overrides Bluefoot
            }

            .custom-buttons,
            .button-with-arrow {
                margin-bottom: 0;
            }
        }

        &__media {
            padding-top: 35px !important; //Overrides Bluefoot

            .bluefoot-image {
                margin-bottom: 0;
            }
        }

        &.with-media-background .media-tout__text {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.bluefoot-slider {
    .slick-prev::before,
    .slick-next::before {
        display: none;
    }

    .slick-arrow span {
        color: rgba($color-white, 0.75);
        font-size: 40px;
    }
}
