$swatch-grid-column-gutter: $indent__base;
$swatch-grid-row-gutter: 35px;

.swatch-grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $indent__l !important; // Overrides Bluefoot
    margin-left: -$swatch-grid-column-gutter / 2;
    margin-right: -$swatch-grid-column-gutter / 2;
    color: $c-medium-grey;
    font-size: $product-name-link__font-size-mobile;
    font-weight: $font-weight__bold;

    @include min-screen($screen__s) {
        font-size: $product-name-link__font-size;
    }

    @include min-screen($screen__m) {
        margin-bottom: 50px !important; // Overrides Bluefoot
    }

     @supports (display: grid) {
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-gap: $swatch-grid-row-gutter $swatch-grid-column-gutter;
         margin-left: 0;
         margin-right: 0;

         @include min-screen($screen__xs) {
             grid-template-columns: 1fr 1fr 1fr;
         }

         @include min-screen($screen__m) {
             grid-template-columns: repeat(auto-fill, minmax(225px, 5fr));
         }
     }
    

    &__swatch {
        flex: 0;
        flex-basis: calc(50% - #{$swatch-grid-column-gutter});
        margin-left: $swatch-grid-column-gutter / 2;
        margin-right: $swatch-grid-column-gutter / 2;
        margin-bottom: $swatch-grid-row-gutter;

        @supports (display: grid) {
            // row and column gap will take care of this
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
        }

        @include min-screen($screen__s) {
            flex-basis: calc(20% - #{$swatch-grid-column-gutter});
        }

        .swatch-grid__text p a {
            color: $text__color;
            text-decoration: none;

            @include hover {
                color: $link__hover__color;
            }
        }
    }

    img {
        width: 100%;
    }

    p {
        margin: 0;
    }

    // Makes the whole item a link 
    &__link {
        text-decoration: none;
        color: inherit;
    }

    &__text {
        margin: $indent__s;
        margin-bottom: 0;
    }

    &__details p {
        margin-top: 0;
        margin-bottom: $indent__m;
    }
}
