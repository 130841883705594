.fullbleed-title {
    position: relative;
    height: 100%;
    background-color: $page__background-color;
}

.custom-heading-horizontal_row--wrapper {
    position: relative;
    text-align: center;

    h2 {
        background-color: $page__background-color;
    }
}

.custom-heading-horizontal_row {
    position: relative;
    display: inline-block;
    margin: 0 !important; // overrides bluefoot
    text-align: center;
}

.custom-heading-horizontal_row--wrapper::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    width: 100%;
    border-bottom: 1px solid $c-border;
}