$bp-medium: $screen__m; // pre-set _catland breakpoint
$bp-large: $screen__l; // pre-set _catland breakpoint
$max-content-width: $layout__max-width - $layout-indent__width; // probably 1260px
$loading-background-color: $secondary__color;
$bluefoot-wrapper__padding: 15px;
$bluefoot-row__margin: 7.5px;

:root .bluefoot-driver a {
    width: 100%; // auto by default and IE 11 stretches iamges past parent width bounds
}

/*
 *
 *  BLUEFOOT :: Bluefoot Styling Overrides.
 *  Bluefoot.css gets included after our CSS so we use :root to override
 *
 */
:root {
    .bluefoot-wrapper {
        @media (min-width: 1500px) {
            max-width: $max-content-width;
        }
    }

    .bluefoot-row {
        &--reverse {
            // let's reverse the columns
            > *:first-child {
                @include max-screen($screen__m) {
                    order: 2;
                }
            }
        }

        @include max-screen($screen__m) {
            // match bluefoot-wrapper mobile styling if no bluefoot-wrapper
            > .bluefoot-column {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &.catland__section {
            margin-bottom: 0; // reset catland styling given bluefoot scope
        }
    }

    .bluefoot-driver-text {
        text-transform: uppercase;
        color: $color-black;
        background-color: rgba($color-white, .75);
    }

    .bluefoot-driver {
        @include hover {
            .bluefoot-driver-text {
                background-color: rgba($color-white, .9);
            }
        }
    }

    .bluefoot-textarea ul {
        padding-left: 15px;
    }


    .bluefoot-slider {
        .slick-arrow span {
            color: rgba($color-black, 0.75);
            padding: 0;
            background: $color-white;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            opacity: 0.8;
            font-size: 60px;

            @include max-screen($screen__m) {
                display: none;
            }
        }

        .slick-dots li {
            button {
                @include hover {
                    background: $color-black;
                }
            }
        }


        .slick-dots li.slick-active button {
            background: $color-black;
        }

        .slick-next {
            right: 125px;
        }

        .slick-prev {
            left: 105px;
        }
    }

}

/*
 *
 *  BLUEFOOT :: Product List Styling
 *
 */
.bluefoot-product-list   {
    .products {
        margin-top: 0;
    }

    a.product-item-link {
        text-decoration: none;
    }

    &.products-grid .product-item {
        margin-bottom: 40px;

        @include min-screen($screen__s) {
            margin-bottom: $indent__l;
        }

        @include min-screen($screen__l) {
            width: calc(100% / 5);
        }
    }
}

/*
 *
 *  BLUEFOOT :: Category Banner Styling
 *
 */
.bluefoot__banner {
    @include max-screen($screen__m) {
        // match the bluefoot-row padding on mobile
        padding-left: 15px;
        padding-right: 15px;
    }
}

/*
 *
 *  BLUEFOOT :: Category Banner Fullbleed Styling
 *
 */
:root .catland__fullbleed {
    margin-left: (-($layout__width-xs-indent) + 7.5px); // offset bluefoot margin on bluefoot-row
    margin-right: (-($layout__width-xs-indent) + 7.5px); // offset bluefoot margin on bluefoot-row

    @include min-screen($screen__m) {
        margin-left: (-($layout-indent__width) + 7.5px); // offset bluefoot margin on bluefoot-row
        margin-right: (-($layout-indent__width) + 7.5px); // offset bluefoot margin on bluefoot-row
    }

    @include min-screen($layout__max-width + 20px) {
        margin-right: calc(-50vw + ((#{$max-content-width} - 4.25px) / 2)); // offset bluefoot margin on bluefoot-row
        margin-left: calc(-50vw + ((#{$max-content-width} - 4.25px) / 2)); // offset bluefoot margin on bluefoot-row
    }

    .bluefoot__banner {
        padding-left: 0;
        padding-right: 0;

        @include max-screen($screen__m) {
            max-height: none !important; // override potential inline max-height from bluefoot block
        }

        @include min-screen($screen__m) {
            max-height: 650px; // can be overriden with inline styles via bluefoot attribute

            img {
                max-height: 650px; // can be overriden with inline styles via bluefoot attribute
                object-fit: cover;
            }
        }
    }
}

.fullbleed__wrapper {
    position: relative;
    max-width: $max-content-width;
    margin: 0 auto;

    @include min-screen($layout__max-width + 20px) {
        .fullbleed__caption.left-top,
        .fullbleed__caption.left-center,
        .fullbleed__caption.left-bottom {
            left: 0; // push to left
        }
    }
}

// Fullbleed Bluefoot Block Mixin

@mixin fullbleed() {
    margin-left: (-($layout__width-xs-indent + $bluefoot-wrapper__padding)  + $bluefoot-row__margin * 2); // offset bluefoot margin on bluefoot-row
    margin-right: (-($layout__width-xs-indent + $bluefoot-wrapper__padding)  + $bluefoot-row__margin * 2); // offset bluefoot margin on bluefoot-row

    @include min-screen($screen__xs) {
        margin-left: (-($layout-indent__width + $bluefoot-wrapper__padding)  + $bluefoot-row__margin * 2); // offset bluefoot margin on bluefoot-row
        margin-right: (-($layout-indent__width + $bluefoot-wrapper__padding)  + $bluefoot-row__margin * 2); // offset bluefoot margin on bluefoot-row
    }

    @include min-screen($layout__max-width) {
        margin-right: calc(-50vw + ((#{$max-content-width} - 17.5px) / 2));  // offset bluefoot margin on bluefoot-row
        margin-left: calc(-50vw + ((#{$max-content-width} - 17.5px) / 2)); // offset bluefoot margin on bluefoot-row
    }
}

.fullbleed,
.fullbleed-block, {
    @include fullbleed();

    &.bluefoot-row.with-media-background,
    &.bluefoot-row.bluefoot-wrapper,
    &.bluefoot-row {
        @include fullbleed();
    }

    img {
        width: 100%;
    }
}

:root {
    /*
    *  add's padding equal to the page margin back to fullbleed blocks
    */
    .fullbleed-block .bluefoot-wrapper {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;

        @include min-screen($screen__xs) {
            padding-left: $layout-indent__width;
            padding-right: $layout-indent__width;
        }

        @include min-screen($layout__max-width) {
            padding-left: $layout__width-xs-indent;
            padding-right: $layout__width-xs-indent;
        }
    }

    .fullbleed-block .bluefoot-column {
        @media (min-width: 770px) {
            padding: 0;
        }
    }
}

.bluefoot-reset {
    @include bluefoot-reset()
}

/*
 *
 *  BLUEFOOT :: Helper class to vertically center bluefoot content
 *
 */
.bluefoot--vertical-center {
    display: flex;
    flex-direction: column;

    @include min-screen($screen__m) {
        align-items: center; // align contents vertically centered
        flex-direction: row;
    }

    > .bluefoot-wrapper {
        @include min-screen($screen__m) {
            display: flex;
            align-items: center;
        }
    }
}

/*
 *
 *  BLUEFOOT :: Helper class to remove max-width on bluefoot-wrapper container
 *
 */
:root .bluefoot--full-width {
    margin-left: 0;
    margin-right: 0;

    .bluefoot-wrapper {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

:root .bluefoot-video {
    @include responsive-video();
}

// All bluefoot columns in 3 column rows will stack until 640px and then inline
:root .bluefoot-column-4 {
    @media screen and (min-width: 640px) {
        width: 33.333%;
    }

    &.circular-tout__wrapper {
        @media screen and (max-width: 640px) {
            width: 100%;
        }
    }
}

// All bluefoot columns in 2 column rows will stack until 640px and then inline
:root .bluefoot-column-6 {
    @media screen and (min-width: 640px) {
        width: 50%;
    }

    &.media-tout__text,
    &.media-tout__media {
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
}


/*
 *
 *  BLUEFOOT :: Improve perceived performance when loading images
 *  by setting a background color that matches the size of the image
 *
 */
%intrinsic-background {
    position: relative;
    height: 0;
    background-color: $loading-background-color;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: opacity .2s ease-in;

        &.lazyload {
            opacity: 0;
        }

        &.lazyloaded {
            opacity: 1;
        }
    }
}

:root {
    .bluefoot-image,
    .bluefoot-driver a {
        @extend %intrinsic-background;
    }

    // In some cases (e.g., circular images) we don't want the background color.
    .bluefoot-image.no-background,
    .bluefoot-driver.no-background a {
        background-color: transparent;
    }
}

// In some cases we may not want the instrinsic background & padding at all.
// Apply to `.bluefoot-image` or `.bluefoot-driver a` as needed.
@mixin reset-intrinsic {
    height: auto !important;
    padding-top: 0 !important;
    background-color: transparent !important;

    img {
        position: static !important;
    }
}

.reset-intrinsic,
.circular-tout {
    @include reset-intrinsic;
}

.circular-tout {
    width: 114px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $indent__l;

    &__wrapper {
        .bluefoot-driver a {
            @include reset-intrinsic;

            img {
                max-width: 33%;
            }
        }
        .bluefoot-textarea {
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;

            @include min-screen($screen__m) {
                max-width: 100%;
                padding-left: $indent__base;
                padding-right: $indent__base;
            }

            @include min-screen($screen__l) {
                padding-left: 35px;
                padding-right: 35px;
            }

            p {
                @include min-screen($screen__m) {
                    text-align: left !important; // Override Bluefoot
                }

                a {
                    @include hover {
                        color: $link__hover__color;
                    }
                }
            }
        }
    }
}

.icon-heading-block {
    padding-top: 15px !important; // Overrides Bluefoot
    padding-bottom: 15px !important; // Overrides Bluefoot

    @include min-screen($screen__s) {
        display: inline-block;
        float: none !important; // Overrides Bluefoot
        max-width: 130px;
        padding-left: $indent__s !important; // Overrides Bluefoot
        padding-right: $indent__s !important; // Overrides Bluefoot
        vertical-align: top;
    }

    @include min-screen($screen__m) {
        max-width: 150px;
    }

    @include min-screen($screen__l) {
        max-width: 195px;
        padding-left: $indent__base !important; // Overrides Bluefoot
        padding-right: $indent__base !important; // Overrides Bluefoot
    }

    figure {
        width: 24px;
        margin-right: 15px !important; // Overrides Bluefoot
        margin-bottom: 0 !important; // Overrides Bluefoot

        @include min-screen($screen__s) {
            width: 45px;
            margin-left: auto !important; // Overrides Bluefoot
            margin-right: auto !important; // Overrides Bluefoot
            margin-bottom: 85px !important; // Overrides Bluefoot
        }

        img {
            height: 30px;

            @include min-screen($screen__s) {
                height: 60px;
            }
        }
    }

    &.weave figure {
        width: 30px;

        @include min-screen($screen__s) {
            width: 55px;
        }
    }

    &__title {
        margin: 0 !important; // Overrides Bluefoot
        padding-left: 40px;
        font-size: 18px;
        color: $text__color;

        @include min-screen($screen__s) {
            padding-left: 0;
            text-align: center;
        }

        @include min-screen($screen__l) {
            font-size: 20px;
        }
    }

    &__wrapper {
        margin-top: $indent__base !important; // Overrides Bluefoot
        margin-bottom: $indent__l !important; // Overrides Bluefoot

        @include min-screen($screen__s) {
            margin-bottom: 40px !important; // Overrides Bluefoot
            text-align: center;
        }

        @include min-screen($screen__m) {
            max-width: 750px;
        }

        @include min-screen($screen__m) {
            max-width: 100%;
        }
    }
}

.textarea--max-width {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.juicer-embed {
    margin-left: 0;
    margin-right: 0;
}

@mixin image-overlay($overlay-background: $secondary__color) {
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $overlay-background;
        pointer-events: none; // make this layer not clickable
        opacity: .15;
        z-index: 1;
        transition: opacity .2s ease;
    }
}

.price-list {
    &__form {
        max-width: 600px;
        padding: $indent__l;

        @include min-screen($screen__s) {
            padding: $indent__l 80px;
        }

        h2 {
            text-align: center;
        }
    }

    &__products {
        fieldset {
            border: 0;
            padding-left: 0;
            margin-top: $indent__m;

            legend {
                color: $c-vignette;
                font-weight: $font-weight__regular;
                margin-bottom: 20px;
            }

            label {
                color: $color-black;
                padding-bottom: $indent__l;
            }
        }
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;

        input {
            display: none;
        }

        @include min-screen($screen__m) {
            flex-direction: row;

            .price-list__input {
                padding-right: 30px;
                flex-grow: 1;
            }
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: $indent__xl;

        @include min-screen($screen__s) {
            flex-direction: row;
            justify-content: flex-end;
        }
    }
}
