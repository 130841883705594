.pagebuilder-slider.slick-slider {
    @include slick-brown-arrow;

    @include max-screen($screen__m) {

        .slick-next,
        .slick-prev {
            display: none !important; // override pagebuilder
        }

        &.instagram-slider {
            .slick-arrow {
                height: 45px;
                width: 45px;

                @include icon-svg($icon : arrow,
                    $fill : $color-black,
                    $width : 15px,
                    $height : 16px,
                    $pseudo : before);

                @include min-screen($screen__m) {
                    width: 55px;
                    height: 55px;
                }

                &::before {
                    padding: 0;
                }

                &.slick-prev {
                    left: 30px;

                    &::before {
                        transform: rotate(180deg) translate(0, -40%);
                    }
                }

                &.slick-next {
                    right: 30px;

                    &::before {
                        transform: translate(30%, -30%);
                    }
                }
            }
        }
    }

    .cms-nourison-hospitality-installations {
        @include max-screen($screen__xs) {

            .pagebuilder-slider,
            .pagebuilder-slide-wrapper,
            .pagebuilder-overlay {
                min-height: 274px !important; // override pagebuilder
            }
        }

        @include max-screen($screen__s) {

            .pagebuilder-slider,
            .pagebuilder-slide-wrapper,
            .pagebuilder-overlay {
                min-height: 320px !important; // override pagebuilder
            }
        }

        @include max-screen($screen__m) {
            .column.main {
                width: 100%;
            }

            .pagebuilder-slide-wrapper {
                background-position: center !important; // override pagebuilder
            }
        }
    }
}