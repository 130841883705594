$color-hex: #d5e9dc;
$color-gray80: #b7b7b7;
$color-dark-charcoal: #2d2d2d;
$color-light-pink: #dfbcbc;
$color-dark-gray: #b3aeae;
$pdp-point-color : #ff5555;

.reward-point {
  width: 75%;
  float: left;
  box-sizing: border-box;
  padding-right: 4%;
  margin-top: 2%;
}

.redeem-reward-selector {
  float: left;
  margin-top: 10px;
  width: 40%;
}

.redeem {
  background: $color-hex;
  border: 1px solid $color-gray80;
  box-sizing: border-box;
  color: $color-dark-charcoal;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  margin-top: 10px;
  padding: 7px 15px;
  vertical-align: middle;

  &:hover {
    background-color: $color-gray80;
    border-color: $color-dark-gray;
  }
}

.cancel-redeem {
  background: $color-light-pink;
  border: 1px solid $color-gray80;
  box-sizing: border-box;
  color: $color-dark-charcoal;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  margin-top: 10px;
  padding: 7px 15px;
  vertical-align: middle;

  &:hover {
    background-color: $color-gray80;
    border-color: $color-dark-gray;
  }
}

.zinrelo-earn-product-points > div {
  display: inline-block;
  color: $pdp-point-color;
  font-weight: bold;
}

.redeem:hover:disabled{
  cursor: wait;
}
.redeem:active:disabled{
  cursor: wait;
}
.cancel-redeem:hover:disabled{
  cursor: wait;
}
.cancel-redeem:active:disabled{
  cursor: wait;
}

