//.cms-nourison-home,
//remove before pushing
.cms-reserve-lp,
.cms-reserve {
    .page-main-full-width {
        background-color: $c-brown;
    }

    .reserve-block {
        .pagebuilder-banner-wrapper {
            border-radius: 0;
        }

        .hp-hero-slider,
        .reserve-banner-block {
            .pagebuilder-overlay {
                background-color: transparent !important; //overwriting page builder inline styles

                @media (max-width: $screen__m) {
                    width: 100%;
                }

                * {
                    color: $c-white;
                }

                .pagebuilder-collage-content {
                    background-color: $c-brown;
                    max-width: 100%;
                    text-align: center;

                    @media (min-width: $screen__m) {
                        background-color: rgba($color: $c-brown, $alpha: 0.8);
                        backdrop-filter: blur(4px);
                        max-width: 430px;
                        text-align: left;
                    }
                }

                h2 {
                    font-family: $font-family__tertiary;
                    font-weight: $font-weight__regular;
                }

                button {
                    @include lib-button-reset;
                    font-weight: $font-weight__bold;
                    border-bottom: 2px solid $c-white;
                    border-radius: 0;
                }

            }

        }
    }

    .reserve-featured-header {
        color: $c-white;
        text-align: center;
        font-family: $font-family__tertiary;
        font-weight: $font-weight__regular;
        @include add-spacing;
        @extend .pt-big;

        h2 {
            @include darkroom-eyebrow;
            padding-bottom: 24px;
        }

        p {
            font-size: 24px;

            @include min-screen($screen__s) {
                font-size: 32px;
            }
        }

        [data-content-type='text'],
        h2 {
            color: $c-white;
            max-width: 660px;
            margin: auto;
        }

        [data-content-type$='block'] .block p:last-child {
            margin-bottom: 0
        }

    }

    .reserve-featured {
        &.pagebuilder-column-group {
            @extend .pt-big;
            @extend .pb-big;

            .pagebuilder-button-primary {
                @include lib-button-reset;
                font-weight: $font-weight__bold;
                border-bottom: 2px solid $c-white;
                border-radius: 0;
            }

            [data-content-type='buttons'] {
                text-align: center;
                padding: 24px 0 0;
            }

            .pagebuilder-column {
                padding: 0 12px;

                @include max-screen($screen__l) {
                    width: 50% !important; //overwrite pagebuilder inline styles
                }
            }

            .pagebuilder-column-line {
                @include max-screen($screen__m) {
                    flex-wrap: wrap;
                }

            }
        }
    }

    .reserve-banner-block {
        .pagebuilder-wrapper {
            padding: 0;
            @extend .pb-big;
        }
    }

    .reserve-block {
        .hp-press-reviews {
            .pagebuilder-poster-content {
                color: $c-white;
            }

            figure[data-content-type=image] {
                margin-bottom: 30px;
            }

            div[data-content-type=text] {
                font-family: $font-family__tertiary;
                color: $c-white;
            }

            .slick-dots li {
                button {
                    background-color: $c-vignette;
                }

                &.slick-active button {
                    background-color: $c-white;
                }
            }
        }

        .pagebuilder-slider.slick-slider {
            @include slick-brown-arrow;

            &.slick-prev,
            &.slick-next {
                top: 50%;
            }
        }


    }

    @include darkroom-slick-dots;

    .slick-dots {

        li {
            button {
                background-color: rgba($color: $c-white, $alpha: 0.3);
                width: 24px;
                height: 8px;
                border: none;
            }

            &.slick-active button {
                background-color: $c-white;
            }
        }
    }

}