//
//  Variables
//  _____________________________________________

$checkout-modal-popup__width: 480px !default;

//
//  Common
//  _____________________________________________

.checkout-index-index {
    .modal-popup {
        .field-tooltip {
            .field-tooltip-content {
                @extend .abs-checkout-tooltip-content-position-top;
            }
        }
    }

    .modal-footer {
        width: 100%;

        @include min-screen($screen__xs) {
            display: flex;
            align-items: baseline;
        }

        .action.secondary {
            @include button--inline-link();
        }

        .action-save-address {
            @include lib-button();
            width: 100%;
            margin-top: $indent__m;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-index-index {
        .modal-popup {
            .form-shipping-address {
                max-width: $checkout-shipping-address__max-width;
            }
        }
    }
}

@include min-screen($screen__l) {
    .checkout-index-index {
        .modal-popup {
            .modal-inner-wrap {
                width: $checkout-modal-popup__width;
                left: 50%;   
                right: auto;
                transform: translateX(-50%);
            }
        }
    }
}
