//
//  Variables
//  ---------------------------------------------

$product-name-link__color                   : $text__color !default;
$product-name-link__color__active           : $text__color !default;
$product-name-link__color__hover            : $text__color !default;
$product-name-link__color__visited          : $text__color !default;

$product-name-link__text-decoration         : none !default;
$product-name-link__text-decoration__active : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover  : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited: $link__hover__text-decoration !default;

//
//  Product Lists
//  _____________________________________________
.products {
    margin: $indent__l 0;
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        box-sizing: border-box;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
            width: 50%;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
        }

        &-info {
            max-width: 100%;
        }

        &-actions {
            .actions-secondary {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @include lib-visually-hidden();
                    }
                }

                .button.wishlist {
                    &::after,
                    .action::after {
                        content: none;
                    }
                }

                .label.action.split {
                    @include max-screen($screen__m) {
                        @include lib-icon-font-symbol($icon-wishlist-empty);

                        span {
                            @include lib-visually-hidden();
                        }
                    }
                }

                .action.toggle.change::after {
                    position: relative;
                    transform: none;
                    background: transparent;
                    border: 0;
                    height: 24px;
                }

                .cms-index-index .bluefoot-product-list & {
                    & > .action {
                        @extend .abs-actions-addto-gridlist;
                        &:before {
                            margin: 0;
                        }

                        &.towishlist span {
                            @include lib-visually-hidden-reset();
                        }
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;

            @include min-screen($screen__m) {
                float: none;
                width: 100%;
                max-width: 710px;
            }

            .more {
                display: none;
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0;

                @include min-screen($screen__m) {
                     margin-right: 4px;
                }
            }

            .reviews-actions {
                margin-top: 0;
                line-height: $line-height__base;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: 0;

            .price {
                font-size: 14px;
                font-weight: $font-weight__light;
                white-space: nowrap;
            }

            .price-label {
                letter-spacing: 1.4px;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            margin-bottom: 0;

            .price {
                font-size: 14px;
                font-weight: $font-weight__regular;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            margin-left: $indent__xs;
            color: $c-red;
        }

        .old-price {
            .price {
                font-weight: $font-weight__light;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__light;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            display: inline-block;
            margin: 0;
            color: $c-vignette;
        }

        .price-from {
            .old-price {
                float: left;
                margin-right: $indent__xs;
            }
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

// product labels
.products.wrapper.products-grid:not(.products-related) .aw-onsale__label-wrap.top-left .picture {
    position: absolute;
    z-index: 1; // layer above product image
    top: 40px;
    width: 100%;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    color: transparent;
}

.column.main {
    .product {
        &-items {
            margin-left: -$indent__base;
        }

        &-item {
            padding-left: $indent__base;
        }
    }

}

.price-container {
    .price {
        font-size: 12px;
        font-weight: $font-weight__bold;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            width: 100%;

            &-info {
                display: flex;
            }

            &-photo {
                display: block;
                flex-shrink: 0;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 240px;
            }

            &-details {
                display: block;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

.product-item .product-item-details {
    margin-top: 15px;
    text-align: left;

    .price,
    .actions-secondary {
        font-size: $font-size__base;

        @include min-screen($screen__m) {
            font-size: $font-size__base--desktop;
        }
    }

    .product-item-name {
        margin-bottom: $indent__s;
    }
        
    .product-item-actions,
    .minimal-price {
        margin-top: 0;

        .price-container {
            display: inline-block;
        }
    }

    .product-item-actions .actions-secondary {
        line-height: 1;
        margin-top: $indent__xs;

        .towishlist,
        .requisition-list-button {
            line-height: 1;
        }

        .towishlist span {
            margin-right: $indent__xs;
        }
    }

    .tocompare {
        display: none;
    }
}

// When product image is hovered, pretend like the product title is hovered
.product-item-photo:hover + div .product-item-link {
    color: $product-name-link__color__hover;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    .products-grid .product-item {
        margin-bottom: $indent__xl;
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: block;

                .actions-primary {
                    display: table-cell;

                    + .actions-secondary {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}
@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 20%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 20%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 23.233%) / 3);
                padding: 0;
                width: 23.233%;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.roomvo-container {
    text-align: center;
    padding-top: 15px;
    padding-bottom: $indent__m;
}

