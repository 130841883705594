.image-text-tout {
    display: flex;
    flex-direction: column;
    position: relative;

    @include min-screen($screen__s) {
        flex-direction: row;
    }

    img {
        display: block;
        width: 100vw;
        font-family: "object-fit:cover";
        object-fit: cover;
    }

    &.align-top,
    &.align-bottom {
        @include min-screen($screen__s) {
            flex-direction: column;
        }
    }

    &.align-top .image-video {
        order: 0;
    }

    &.align-bottom .image-video {
        order: 1;
    }

    &.align-left .image-video {
        @include min-screen($screen__s) {
            order: 0;
        }
    }

    &.align-right .image-video {
        @include min-screen($screen__s) {
            order: 1;
        }
    }
}

.tout-content {
    padding: 15px;

    h2 {
        margin-top: 0;
    }

    &.absolute-positioned {
        position: absolute;
        top: 80%;
        margin: 15px;

        @include min-screen($screen__s) {
            left: 0;
            right: 0;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}