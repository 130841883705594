// Dealer Main Page
.dealer-tabs div[data-content-type='tabs'] {
    * {
        box-sizing: border-box;
    }

    .ui-tabs-panel {
        padding: 0 !important;

        h3 {
            background-color: $c-white;
            border-radius: 8px 8px 0 0;
            text-transform: uppercase;
            padding: 16px 16px 0 16px;
            margin-bottom: 0;
            font-size: $font-size__base--l;
        }
    }

    .tabs-navigation {
        li.tab-header {
            background-color: transparent;
            margin: 0 12px 10px 0;

            a.tab-title {
                @include lib-button();

            }

            &.ui-state-active {
                a.tab-title {
                    @include lib-button-primary();
                }
            }
        }
    }

    .tabs-content {
        margin-top: 2px;
    }

    .pagebuilder-column-group {
        background-color: $c-white;
        border-radius: 0 0 8px 8px;
        padding: 16px;

        .pagebuilder-column-line {
            background-color: $c-gray-50;
            padding: 16px;
            border-radius: 8px;
        }
    }
}


.cms-dealer,
.cms-dealer-support,
.cms-dealer-locator {
    p {
        color: $c-gray-350;
    }

    background-color: $c-gray-50;

    h1 {
        margin-bottom: 0;

        + h2 {
            margin-top: 0;
        }
    }


    .columns {
        max-width: $max-content-width;
        margin: 0 auto;

        h4:not(.heading-with-border__row) {
            margin-top: $indent__l !important; // override PageBuilder
        }

        .price-list__actions {
            @include min-screen($screen__m) {
                justify-content: flex-start;
                margin-top: 0;
            }
        }

        .shop-online [data-element="main"] {
            display: flex;
            flex-wrap: wrap;
            margin-top: $indent__xl !important; // override PageBuilder

            div {
                flex-basis: 100%;
                display: flex;
                justify-content: center;
                margin: $indent__s auto;

                @include min-screen($screen__m) {
                    flex-basis: 25%;

                    img {
                        max-width: 100px;
                    }
                }
            }
        }

        .featured-categories .pagebuilder-column-group {
            @include max-screen($screen__m) {
                flex-direction: column;

                .pagebuilder-column {
                    width: 100% !important; // override PageBuilder
                    margin-bottom: 24px !important; // override PageBuilder

                    h2,
                    [data-content-type="text"] {
                        max-width: 300px;
                        margin: $indent__s auto !important; // override PageBuilder
                    }
                }
            }

            .pagebuilder-button-link {
                @include button-with-arrow();
                color: $button-tertiary__color;
                text-decoration: none;
            }
        }


    }


}

.dealer-block-links {
    .pagebuilder-column-line {
        column-gap: 24px;

        @include min-screen($mobile-nav__breakpoint) {}
    }

    .pagebuilder-column {
        padding: 24px;
        background-color: $c-gray-100;
        border-radius: 8px;

        @include max-screen($mobile-nav__breakpoint) {
            width: calc(50% - 12px) !important;

            &.dealer-store {
                width: 100% !important;
                margin-bottom: 24px;
            }

            &.dealer-online {
                margin-right: 12px;
            }

            &.dealer-globe {
                margin-left: 12pxz;
            }
        }

        @include max-screen($screen__s) {
            width: 100% !important;

            &.dealer-store,
            &.dealer-online,
            &.dealer-globe {
                margin: 0 0 24px 0 !important;

            }

        }

        @include icon-svg($icon : dealer-store,
            $pseudo : before,
            $hideLabel : false);

        &.dealer-online {
            @include icon-svg($icon : dealer-online)
        }

        &.dealer-globe {
            @include icon-svg($icon : dealer-globe)
        }
    }
}

.dealer-logos {
    .pagebuilder-column-line {
        column-gap: 24px;
        row-gap: 24px;
    }

    .pagebuilder-column {
        background-color: $c-white;
        border-radius: 8px !important;

        [data-content-type='button-item'] {
            display: block !important;

            a.pagebuilder-button-link {
                text-decoration: none;
                text-align: left !important;
                display: block;
                margin-bottom: 0;
                position: relative;
                font-size: $font-size__base;

                &:hover {
                    text-decoration: underline;
                }

                @include icon-svg($icon : hp-arrow,
                    $fill : $c-brown,
                    $width : 13px,
                    $height : auto,
                    $pseudo : after,
                    $hideLabel : false);

                &::after {
                    transform: rotate(-45deg);
                    position: absolute;
                    right: 0;
                    top: 16px;

                }
            }
        }
    }
}

// Dealer Support Page
.cms-dealer-support .columns {
    @include max-screen($screen__m) {
        .pagebuilder-column-group {
            flex-direction: column;

            .pagebuilder-column {
                width: auto !important; // override PageBuilder
            }
        }
    }

    [data-content-type="row"] {
        margin-bottom: $indent__l;

        img {
            margin-top: $indent__xl;
            max-width: 175px !important; // override PageBuilder
        }

        [data-content-type="text"] {
            margin: 0 auto !important; // override PageBuilder
            width: 100%;

            @include min-screen($screen__m) {
                max-width: 300px;
            }
        }
    }

    .hero .pagebuilder-column {
        @include min-screen($screen__m) {
            padding: $indent__xl $indent__m;
        }

        h2 {
            @include max-screen($screen__m) {
                padding: 0 15px;
            }
        }

        img {
            max-width: 100% !important; // override PageBuilder
            margin: 0;
        }

        [data-content-type="text"] {
            margin: 0 !important; // override PageBuilder
            max-width: none;
            padding: 0 15px;
        }
    }

    h4 {
        font-size: 28px;
        font-weight: $font-weight__bold;

        @include max-screen($screen__m) {
            padding-left: $indent__m;
            padding-right: $indent__m;
        }
    }
}