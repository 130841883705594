.fifty-to-infinity {
    &__main-banner {
        & > * {
            padding-top: 56.490384615385%;
            position: relative;
            display: block;

            @include max-screen($screen__m) {
                padding-top: 107.29166666667%;
            }

            &::before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #2d2d2d;
                pointer-events: none;
                opacity: .15;
                z-index: 1;
                transition: opacity .2s ease;
            }
        }

        .pagebuilder-banner-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        .pagebuilder-poster-overlay {
            position: absolute;
            right: auto;
            bottom: auto;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 1;
            width: 100%;
            max-width: 650px;

            .banner--heading {
                color: $color-white;
                margin: 0 auto;
            }

            button {
                margin-top: 20px;

                @include max-screen($screen__m) {
                    width: auto;
                }
            }
        }
    }

    &__welcome-text {
        max-width: 600px;
        margin: 0 auto !important; // Override PageBuilder

        h2 {
            text-align: center;
        }
    }

}

[data-content-type="video"] {
    .pagebuilder-video-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

[data-appearance="full-width"] {
    margin-left: -$indent__s !important; // Override PageBuilder inline styles
    margin-right: -$indent__s !important; // Override PageBuilder inline styles
    padding-left: $indent__s !important; // Override PageBuilder inline styles
    padding-right: $indent__s !important; // Override PageBuilder inline styles

    @include min-screen($screen__xs) {
        margin-left: -$indent__base !important; // Override PageBuilder inline styles
        margin-right: -$indent__base !important; // Override PageBuilder inline styles
        padding-left: $indent__base !important; // Override PageBuilder inline styles
        padding-right: $indent__base !important; // Override PageBuilder inline styles
    }

    @include min-screen($screen__xl) {
        margin-left: calc(-1 * (100vw - #{$layout__max-width} + (#{$layout-indent__width} * 2)) / 2) !important; // Override PageBuilder inline styles
        margin-right: calc(-1 * (100vw - #{$layout__max-width} + (#{$layout-indent__width} * 2)) / 2) !important; // Override PageBuilder inline styles
        padding-left: calc((100vw - #{$layout__max-width} + (#{$layout-indent__width} * 2)) / 2) !important; // Override PageBuilder inline styles
        padding-right: calc((100vw - #{$layout__max-width} + (#{$layout-indent__width} * 2)) / 2) !important; // Override PageBuilder inline styles
    }
}

[data-content-type="buttons"] {
    div.button-with-arrow {
        &::after {
            content: none !important; // Override old styles
        }

        a {
            color: inherit;
            text-decoration: none;

            span {
                padding-right: 5px;
            }

            @include icon-svg(
                $icon       : hp-arrow,
                $fill       : $c-ikat,
                $width      : 13px,
                $height     : auto,
                $pseudo     : after,
                $hideLabel  : false
            );

            @include hover {
                @include icon-svg(
                    $icon       : hp-arrow,
                    $fill       : $secondary__color,
                    $width      : 13px,
                    $height     : auto,
                    $pseudo     : after,
                    $hideLabel  : false
                );
            }
        }
    }

    .action.button.secondary {
        a {
            color: inherit;

            @include hover {
                color: inherit;
            }
        }
    }
}

.pagebuilder-mobile-hidden {
    @include max-screen($screen__m) {
        display: none;
    }
}

.pagebuilder-mobile-only {
    @include min-screen($screen__m) {
        display: none;
    }
}

.heading-with-border__wrapper {
    position: relative;
    text-align: center;
    margin: $indent__l auto;

    @include min-screen($screen__m) {
        margin: 50px auto;
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        width: 100%;
        border-bottom: 1px solid $c-border;
    }

    .heading-with-border__row {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: $font-weight__bold;
    }
}

.cms-press-releases,
.cms-marketing,
.cms-springmarket2020 {
    .pagebuilder-column-group {
        @include max-screen($screen__m) {
            flex-direction: column;

            .pagebuilder-column {
                width: 100% !important; // override PageBuilder
                margin-bottom: $indent__m;
                box-sizing: border-box;
            }
        }
    }
}

.cms-marketing {
    [data-content-type="button-item"] {
        width: 100%;
    }

    .pagebuilder-column {
        padding: 0 !important; // override PageBuilder

        @include min-screen($screen__m) {
            &:not(:last-of-type) {
                margin-right: $indent__s !important; // override PageBuilder
            }
        }
    }

    .pagebuilder-button-primary {
        padding: $indent__l 15px !important; // override PageBuilder
        
        @include min-screen($screen__xs) {
            width: 100%;
            max-width: 440px;
        }

        @include min-screen($screen__m) {
            padding: 15px !important; // override PageBuilder
        }
    }
}

.column-group-mobile-stack {
    .pagebuilder-column-group {
        @include max-screen($screen__m) {
            flex-direction: column;

            .pagebuilder-column {
                width: 100% !important; // override PageBuilder
                margin-bottom: $indent__m;
                box-sizing: border-box;
            }
        }
    }
}

.column-group-mobile-stack-reverse {
    .pagebuilder-column-group,
    .pagebuilder-column-line {
        @include max-screen($screen__m) {
            flex-direction: column-reverse;

            .pagebuilder-column {
                width: 100% !important; // override PageBuilder
                margin-bottom: $indent__m;
                box-sizing: border-box;
            }
        }
    }
}

.even-padding-mobile {
    @include max-screen($screen__m) {
        .pagebuilder-column {
            padding: 10px !important; // override pagebuilder 
        }
    }
}

.align-center-mobile {
    @include max-screen($screen__m) {
        .pagebuilder-column {
            align-items: center; // override pagebuilder 

            [data-content-type="text"] {
                text-align: center;
            }
        }
    }
}

.no-margins-mobile.pagebuilder-column {
    @include max-screen($screen__m) {
        margin: 0px !important; // override pagebuilder
    }
}


.cms-page-view.page-layout-sticky-nav-layout[class*="catalog"] .page-main {	 
    padding: 0;
    margin: 0;
    max-width: none;
}

figure[data-content-type="image"][data-appearance="full-width"] {
    @include min-screen($screen__xl) {
        padding: 0 !important; // override PageBuilder
        margin: 0 !important; // override PageBuilder
    }
}

.cms-hospitality .jarallax {
    @include max-screen($screen__xs) {
        min-height: 350px !important; // override PageBuilder
    }

    @include max-screen($screen__m) {
        min-height: 500px !important; // override PageBuilder
    } 
}
