/*doc
---
title: Breadcrumbs
name: block_breadcrumbs
category: Blocks - Breadcrumbs
---


```html_example
<div class="breadcrumbs">
    <ul class="items">
        <li class="item home"><a href="#" title="Go to Home Page">Home</a></li>
        <li class="item product"><strong>Desiree Fitness Tee</strong></li>
    </ul>
</div>
```
*/

.breadcrumbs {
    @include lib-breadcrumbs(
        $_breadcrumbs-link-color: $text__color,
        $_breadcrumbs-link-text-decoration: underline,
        $_breadcrumbs-current-color: $text__color,
        $_breadcrumbs-link-color-visited: $text__color,
        $_breadcrumbs-current-font-weight: $font-weight__light,
        $_breadcrumbs-font-size: 11px,
        $_icon-font-line-height: 1,
        $_breadcrumbs-icon-use: false,
        $_breadcrumbs-container-margin: 0 auto,
        $_breadcrumbs-separator-symbol: '/'
    );
    @include layout-container;
    margin-bottom: $indent__s;

    .items {
        line-height: 1.6;
        letter-spacing: 1px;
        font-weight: $font-weight__light;

        @include min-screen($screen__m) {
            font-size: 12px;
            line-height: 1.8;
            letter-spacing: .7px;
        }

        a {
            padding-right: $indent__xs;
        }
    }
}
