.footer-form {
    * {
        box-sizing: border-box;
    }

    background-color: $c-white;
    padding: 24px;
    border-radius: $custom-border-radius;
    margin: 30px 0;

    &[data-content-type="row"][data-appearance="contained"] {
        max-width: 1142px;
    }

    h2 {
        @include darkroom-headings-h2-secondary;
    }

    > div {
        background-color: $c-gray-250;
        border-radius: $custom-border-radius;
        padding: 24px;
        width: 100%;
    }

    .footer-form-browse {
        margin: 0 0 24px;

        a {
            display: block;
            text-align: center;
        }
    }

    @include min-screen($screen__m) {
        display: flex;

        > div {
            width: 50%;
            margin-left: 12px;
        }

        .footer-form-browse {
            margin: 0 12px 0 0;
        }
    }
}

.footer-community-wrap {
    background-color: $c-white;
}

.footer-form-trade-account {
    .pagebuilder-button-secondary {
        width: 45%;

        @include min-screen($screen__l) {
            padding: 12px 4px;
        }

        @include max-screen($screen__l) {
            display: block;
            width: 100%;
        }
    }
}

.footer-form-wrapper {
    @include max-screen($screen__l2) {
        padding: 0 24px;
    }
}

.footer-community {
    @extend .pt-big, .pb-big;
    padding-left: 32px;
    padding-right: 32px;

    @include min-screen($screen__s) {
        padding-left: 16px;
        padding-right: 16px;
    }

    * {
        box-sizing: border-box;
    }

    padding: 0 20px;

    @include min-screen($screen__l) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .footer-community-heading {
            width: 30%;
        }
    }

    h2 {
        margin: 0;
    }

    fieldset {
        display: flex;
        align-items: self-start;
        flex-direction: row;
        border: 0;
        margin: 0;
        padding: 20px 0 0;
        gap: 20px;
        flex-wrap: wrap;

        @include min-screen($screen__l) {
            padding: 0;
        }

        .darkroom-headings-h2-secondary {
            width: 100%;
        }

        @include min-screen($screen__m) {
            .darkroom-headings-h2-secondary {
                width: auto;
            }
        }
    }

    button {
        padding: 12px 32px;
        margin-left: 24px;
        width: 250px;

        @include max-screen($screen__l) {
            width: auto;
        }

        @include max-screen($screen__m) {
            width: 100%;
            display: block;
        }

        @include hover {
            color: $c-houndstooth;
            background-color: $c-black-15;
        }
    }

    .radio-wrap {
        display: flex;
        align-items: center;
        border: 1px solid $c-brown;
        padding: 8px 12px;
        border-radius: 6px;
        font-size: 14px;
        margin: 0;

        @include min-screen($screen__m) {
            margin: 0 12px;

        }

        &.checked {
            background-color: $c-gray-50;
        }
    }

    form {
        input[type="radio"] {
            border: 1px solid $c-brown;
        }

        label {
            font-style: normal;
            color: $c-brown;
            font-weight: normal;
            text-transform: uppercase;
            padding-right: 24px;
        }
    }

    .footer-community-email {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        padding-top: 32px;
        gap: 20px;

        @include min-screen($screen__m) {
            flex-direction: row;
            gap: 0;
        }

        .footer-community-email-wrap {
            width: 400px;
            width: 100%;

            @include min-screen($screen__m) {
                width: 52%;
            }

            input {
                margin-right: 24px;
                width: 100%;

                @include max-screen($screen__m) {
                    margin: 0;
                    max-width: 100%;
                }
            }

        }

        label {
            text-transform: none;
        }

    }
}