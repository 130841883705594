.form.send.friend {
    @extend .abs-add-fields;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .form.send.friend {
        @extend .abs-add-fields-desktop;
    }
}

.link-sharing {
    display: flex;
    list-style: none;
    margin-bottom: $indent__base;
    padding-left: 0;

    li {
        margin: 0;

        &:not(:last-child) {
            margin-right: 15px;

            @include min-screen($screen__m) {
                margin-right: $indent__base;
            }
        }
    }

    svg {
        height: 20px;
        width: 20px;

        * {
            fill: $link__color;
        }
    }

    a {
        display: block;

        @include hover {
            svg * {
                fill: $link__hover__color;
            }
        }
    }
}
