.social-links {
    position: relative;
    display: none;
    float: right;
    clear: right;
    flex-wrap: wrap;
    margin-top: 12px; // vertically align with menu items
    padding-left: 0;
    z-index: 4;

    @include min-screen($mobile-nav__breakpoint) {
        display: flex;
    }

    li {
        margin-top: 0;
        margin-bottom: 0;
        list-style: none;

        &:not(:last-child) {
            margin-right: 15px;
        }
    }

    svg {
        display: block;
        width: 12px;
        height: 15px;

        .icon--twitter & {
            width: 16px;
            height: 17px;
        }

        .icon--instagram & {
            width: 15px;
            height: 15px;
        }

        * {
           fill: currentColor;
        }
    }

    a {
        display: block;
        color: $secondary__color;

        @include hover {
            color: $primary__color;
        }

        &[data-network="facebook"] svg {
            width: 9px;
        }

        &[data-network="twitter"] svg {
            width: 14px;
        }

        &[data-network="pinterest"] svg {
            height: 14px;
        }

        &[data-network="instagram"] svg {
            height: 14px;
            width: 14px;
        }

        &[data-network="tumblr"] svg {
            height: 14px;
            width: 9px;
        }
    }
}

.footer.content .social-links {
    display: flex;
    float: none;
    margin-bottom: 40px;

    svg {
        height: 20px;
    }

    a[data-network="facebook"] svg {
        width: 11px;
    }

    a[data-network="twitter"] svg {
        width: 20px;
    }

    a[data-network="instagram"] svg {
        height: 18px;
        width: 18px;
    }

    a[data-network="youtube"] svg {
        width: 20px;
    }

    a[data-network="tiktok"] svg {
        width: 15px;
    }

    li:not(:last-child) {
        margin-right: $indent__l;
    }
}
