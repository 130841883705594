//
//  Variables
//  _____________________________________________

$collapsible-nav-background: $flyout-background-color !default;
$collapsible-nav-color: $link__color !default;
$collapsible-nav-current-border: 2px solid transparent !default;
$collapsible-nav-current-border-color: $link__hover__color !default;
$collapsible-nav-current-color: $c-ikat !default;
$collapsible-nav-current-font-weight: $font-weight__bold !default;
$collapsible-nav-delimiter__border-color: $color-gray82 !default;
$collapsible-nav-item-hover: transparent !default;
$collapsible-nav-box-shadow: 0 4px 12px -4px rgba(0, 0, 0, 0.35) !default;

//
//  Common
//  _____________________________________________

//
//  Collapsible navigation
//  -----------------------------------------

.block-collapsible-nav {
    box-sizing: border-box;

    .heading {
        @include bryant-park-headings-h5();
        margin-top: 0;
        margin-bottom: 0;
        background-color: $c-houndstooth;
        color: $color-white;
    }
}

.block-collapsible-nav-content {
    padding: 15px 0;
    background-color: $collapsible-nav-background;

    @include max-screen($screen__m) {
        box-shadow: $collapsible-nav-box-shadow;
    }

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            border-left: 3px solid transparent;
            display: block;
            padding: 7px 0 7px 20px;
            color: $text__color;
            font-size: 16px;
            font-family: $font-family__secondary;
            font-style: italic;
            line-height: 1.5;
            letter-spacing: .2px;
        }

        a {
            text-decoration: none;

            @include hover {
              color: $link__hover__color;
            }
        }

        &.current {
            a,
            > strong {
              border-color: $collapsible-nav-current-border-color;
              font-weight: $collapsible-nav-current-font-weight;
              color: $collapsible-nav-current-color;
            }

            a {
              border-color: $collapsible-nav-current-border-color;
            }
        }

        .delimiter {
            display: none;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-collapsible-nav {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;

        .heading {
            padding: 0 $indent__base;
            border-bottom: $border-width__base solid $border-color__base;
        }
    }

    .block.block-collapsible-nav .block-collapsible-nav-title {
        position: relative;
        width: 100%;
        padding: 0;
        font-family: $font-family__base;
        text-align: left;
        text-transform: none;
        color: inherit;
        background-color: transparent;
        padding: $layout__width-xs-indent 0;
        border: 0; // reset border
        margin-bottom: 0;
        font-size: 16px;
        display: block;
        @include icon-svg(
            $icon     : expand,
            $fill     : $color-white,
            $width    : 14px,
            $height   : auto,
            $pseudo   : after
        );

        &::after {
            @include vertically-center($position: absolute);
            right: 0;
            left: auto;
            border: 0;
            background-color: none;
        }

        &[aria-expanded="true"] {
            @include icon-svg(
                $icon     : collapse,
                $fill     : $color-white,
                $width    : 14px,
                $height   : auto,
                $pseudo   : after
            );
        }
    }

    .block-collapsible-nav-content {
        border-bottom: $border-width__base solid $border-color__base;

        hidden {
            display: none;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-collapsible-nav {
        .title {
            @include lib-visually-hidden;
        }
    }
}
