$trade-show__border: 1px solid #e3e3e3;

.cms-trade-shows {
    .event-location p {
        &:first-of-type {
            color: $c-ikat;
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-top: 0;
        }
    }

    [data-content-type="divider"] {
        padding: 0;
        height: 5px;
    }

    [data-content-type="row"] {
        margin-bottom: $indent__s;
    }

    [data-content-type="block"] {
        margin-bottom: $indent__xl;
    }

    .event-details {
        h1,
        h3 {
            margin: 0;
            align-self: center;
        }

        h1 {
            @include min-screen($screen__m) {
                font-size: 50px !important; // override PageBuilder
            }
        }

        .pagebuilder-column-group {
            height: 100px;
            border-top: $trade-show__border;
            border-bottom: $trade-show__border;
            justify-content: center;
            max-width: 800px;
            width: 100%;
            margin: 0 auto;

            .pagebuilder-column {
                align-self: center !important; // override PageBuilder
                max-width: 150px;
                width: 100% !important; // override PageBuilder
                flex-direction: row !important; // override PageBuilder
                justify-content: center !important; // override PageBuilder

                &:first-of-type {
                    justify-content: flex-end !important; // override PageBuilder
                }

                &:last-of-type {
                    justify-content: flex-start !important; // override PageBuilder
                }

                &.separator {
                    max-width: 15px;
                }
            }
        }
    }
}
