//
//  Bryant Park settings
//  ---------------------------------------------
//
//  Table of Contents:
//
//      Colors
//      Layout grid
//      Typography
//      Buttons
//      Navigation
//      Icons
//      Messages
//      Loaders
//      Popups
//      Variables
//      Forms
//      Accordion and Tabs
//      Pager
//      Modals
//      Popups
//      Progress Bar
//      Pages
//      Sidebar
//      Cart
//      Variables missing in extend.scss
//      Magento CMS Widgets
//      Account pages

//
// Colors
// ---------------------------------------------

// Theme-specific colors
$c-mercury: #e7e7e7;
$c-dusty-grey: #9a9a9a;
$c-dove-grey: #676767;

// Typography
$c-feng-shui: #271706;

// Icons, Mobile Accordions,Secondary Buttons
$c-houndstooth: #271706;

// Borders, Form labels
$c-vignette: #808080;

// Dark Border
$c-dark-border: #0F0E0D;

// Hovers, Tertiary Buttons
$c-ikat: #3e7aa3;

// Primary Buttons
$c-dhurrie: #20150B;

// Ancillary Brand Color
$c-armoire: #8a6d3b;

// Accounts Nav Background
$c-berber: #f5f5f5;

// Success Alerts
$c-kilim: #006400;

// Errors and Validaton
$c-chinoiserie: #e02b27;

$c-grey: #eeeeee;
$c-widget-border-grey: #dbdbdb;
$c-heavy-metal: #2f302d;
$c-scorpion: #5d5d5d;

// Standard colors
$c-white: #ffffff;
$c-pink: #fae5e5;
$c-brown: #20150B;
$c-light-brown: #75685a;
$c-yellow: #fdf0d5;
$c-green: $c-kilim;
$c-light-green: #e5efe5;
$c-red: $c-chinoiserie;
$c-orange: #ff5501;
$c-blue: $c-dhurrie;
$c-light-grey: $c-berber;
$c-medium-grey: $c-vignette;
$c-dark-grey: $c-heavy-metal;
$c-pdp-caption-bcd: #ececec;
$c-pdp-caption: #202020;
$c-pdp-grouped-name: #171816;
$c-checkout-msg-border: #d9d9d9;
$c-pdp-description-title: $c-dark-border;

$c-gray-600: #262626;
$c-gray-500: #424242;
$c-gray-400: #606060;
$c-gray-300: #838383;
$c-gray-250: #f3f3f3;
$c-gray-200: #cacaca;
$c-gray-100: #efefef;
$c-gray-150: #e2e2e2;
$c-gray-50: #F6F6F6;
$c-gray-350: #757575;
$c-brown-400: #5b4f42;
$c-brown-500: #31200E;
$c-brown-100: #8B8279;
$c-bct-black: #231F20;
$c-black-15: rgba($color: $color-black, $alpha: .15);

// Main site colors
$primary__color: $c-dhurrie;
$secondary__color: $c-houndstooth;

$active__color: $primary__color; // standard color for designating an element as being "active"

$c-border: $c-gray-200; // most borders used throughout site
$page__background-color: $c-white;

//
//  Layout grid
//  ---------------------------------------------

$total-columns: 12;
$grid-width: (
  $column-width * $total-columns) + ($gutter-width * ($total-columns - 1)
);

// Be sure to update js/utils/breakpoints.js as well
$screen__xxs: 320px;
$screen__xs: 480px;
$screen__s: 640px;
$screen__m: 768px;
$screen__l: 1024px;
$screen__l2: 1440px;
$screen__xl: 1600px;
$mobile-nav__breakpoint: 880px; // Update mediaCheck variable in js/menu.js too

//widths
$layout__max-width: $screen__l2;

// Variables for layout columns
$layout-column__sidebar-width: 5;

// Checkout columns width
$layout-column-checkout__width-left: 3;

// Layout Widths
$layout__width-xs-indent: $indent__s;

// Infinity PDP media width
$infinity-media__width-s: 460px;
$infinity-media__width-l: 625px;

//
//  Typography
//  ---------------------------------------------


// Font families
$font-family__serif: Georgia, "Times New Roman", Serif;
$font-family__sans-serif: Helvetica, Arial, sans-serif;
$font-family__base: 'Larken', $font-family__sans-serif;
$font-family__secondary: 'TT-Hoves', $font-family__sans-serif;
$font-family__tertiary: 'Cardo', $font-family__serif;

$font-weight__light: 300;
$font-weight__regular: 400;
$font-weight__medium: 500;
$font-weight__semibold: 600;
$font-weight__bold: 700;

// Body Typography
$font-weight__base: $font-weight__regular;
$font-size__base: 14px;
$font-size__base--xs: 12px;
$font-size__base--desktop: 16px;
$font-size__base--m: 15px;
$font-size__base--l: 18px;
$font-size__base--xl: 20px;
$font-size__pdp-sku: 24px;
$font-size__banner: 28px;
$font-size__pdp-name: 32px;
$font-size__banner--desktop: 52px;
$font-size__navigation: 13px;

$line-height__base: 1.575;
$line-height__base--desktop: $line-height__base;

$letter-spacing__pdp-caption: .13px;
$letter-spacing__base: normal;
$letter-spacing__pdp-tab: .64px;
$letter-spacing__pdp-sku: .71px;
$letter-spacing__pdp-sku--desktop: .86px;
$letter-spacing__base--desktop: 0;
$letter-spacing__pdp-name: 1px;
$letter-spacing__pdp-name--desktop: 1.21px;
$letter-spacing__pdp-description-title: 1.2;
$letter-spacing__pdp-description: 1.3;
$letter-spacing__pdp-description-button: 1;
$letter-spacing__navigation: 1.3px;

$text__color: $c-gray-600;
$text__color-alt: $secondary__color;

$focus__box-shadow: none;

// Link styles
$link__color: $text__color;
$link__hover__color: $link__color;
$link__visited__color: $link__color;
$link__active__color: $link__color;
$link__text-decoration: underline;
$link__visited__text-decoration: $link__text-decoration;
$link__hover__text-decoration: none;
$link__active__text-decoration: none;
$paragraph-link__text-decoration: $link__text-decoration;

$product-name-link__color: $text__color;
$product-name-link__color__active: $link__active__color;
$product-name-link__color__hover: $link__hover__color;
$product-name-link__color__visited: $text__color;
$product-name-link__font-size: 16px;
$product-name-link__font-size-mobile: 14px;

// Headings
$heading__font-family__base: $font-family__base;
$heading__font-family__secondary: $font-family__base;
$heading__font-weight__base: $font-weight__regular;
$heading__text-transform: none;

$h1__font-size-desktop: 40px;
$h2__font-size-desktop: 40px;
$headings__font-size-small: 32px;
$h3__font-size-desktop: 20px;
$h4__font-size-desktop: 20px;
$h5__font-size-desktop: 16px;
$h6__font-size-desktop: 12px;

$h1__line-height-desktop: 1.2;
$h2__line-height-desktop: 1.2;
$h3__line-height-desktop: 1.5;
$h4__line-height-desktop: 1.5;
$h5__line-height-desktop: 1.5;
$h6__line-height-desktop: 1.5;

$heading-h1: (
  font-family: $heading__font-family__base,
  font-weight: $font-weight__bold,
  font-size: $h1__font-size-desktop,
  font-size-desktop: $h1__font-size-desktop,
  line-height: $h1__line-height-desktop,
  line-height-desktop: $h1__line-height-desktop
);

$heading-h2: (
  font-family: $heading__font-family__base,
  font-weight: $font-weight__bold,
  font-size: $h1__font-size-desktop,
  font-size-desktop: $h2__font-size-desktop,
  line-height: $h2__line-height-desktop,
  line-height-desktop: $h2__line-height-desktop,
  letter-spacing: normal,
  text-transform: none
);

$heading-h3: (
  font-family: $font-family__secondary,
  font-weight: $font-weight__semibold,
  font-size: $h3__font-size-desktop,
  font-size-desktop: $h3__font-size-desktop,
  line-height: $h3__line-height-desktop,
  line-height-desktop: $h3__line-height-desktop
);

$heading-h4: (
  font-family: $font-family__secondary,
  font-weight: $font-weight__semibold,
  font-size: $h4__font-size-desktop,
  font-size-desktop: $h4__font-size-desktop,
  line-height: $h4__line-height-desktop,
  line-height-desktop: $h4__line-height-desktop,
  text-transform: none
);

$heading-h5: (
  font-family: $font-family__secondary,
  font-size: $h5__font-size-desktop,
  font-size-desktop: $h5__font-size-desktop,
  line-height: $h5__line-height-desktop,
  line-height-desktop: $h5__line-height-desktop,
  font-weight: $font-weight__semibold,
  text-transform: none
);

$heading-h6: (
  font-family: $font-family__secondary,
  font-size: $h6__line-height-desktop,
  font-size-desktop: $h6__line-height-desktop,
  line-height: $h6__line-height-desktop,
  line-height-desktop: $h6__line-height-desktop,
  font-weight: $font-weight__semibold,
  text-transform: none
);


//
//  Buttons
//  ---------------------------------------------

// Default (Secondary Button)
$button__font-family: $font-family__secondary;
$button__font-size: 14px;
$button__padding: 12px 32px;
$button__color: $c-gray-600;
$button__background: transparent;
$button__hover__background: $c-gray-300;
$button__hover__color: $c-houndstooth;
$button__active__color: $c-houndstooth;
$button__border: 1px solid $c-gray-200;
$button__hover__border: $button__border;
$button__active__border: $button__border;
$button__border-radius: 100px;
$button--letter-spacing: normal;

// Primary Button
$button-primary__padding: $button__padding;
$button-primary__hover__border: 1;
$button-primary__active__border: 1;
$button-primary__color: $color-white;
$button-primary__background: $c-brown;
$button-primary__border: 1px solid $c-brown;
$button-primary__hover__color: $color-white;
$button-primary__hover__background: $c-light-brown;
$button-primary__active__background: $c-light-brown;

// Tertiary Button
$button-tertiary__color: $c-gray-600;
$button-tertiary__hover__color: $c-gray-600;

$button__font-size__s: $font-size__base; // Small Buttons (PDP Add to Wishlist / PDP Compare)
$button__padding__s: $button__padding; // use same as normal button

// Large button
$button__font-size__l: $button__font-size__s;
$button__padding__l: 10px 17px;

//
//  Navigation
//  ---------------------------------------------

$navigation__background: transparent;

$navigation-level0-item__color: $secondary__color;
$navigation-level0-item__text-decoration: none;

//  Desktop navigation
$navigation-desktop-level0-item__line-height: 47px;

$submenu-desktop__font-weight: $font-weight__regular;
$submenu-desktop-item__active__color: $primary__color;

$header__icon-height: 30px;
$header-icons-color: $text__color;
$header-icons-color-hover: $link__hover__color;

$active-nav-indent: 54px;

// Mobile navigation flyout
$flyout-active-color: $color-white;
$flyout-background-color: $c-berber;
$flyout-box-shadow: 0 0 12px 2px rgba(#000, 0.35);
$flyout-tab-height: 57px;

//
//  Icons
//  ---------------------------------------------

// Fonts
$icons__font-path: '../fonts/Blank-Theme-Icons/Blank-Theme-Icons';
$icons__font-name: 'icons-blank-theme';

$icon-success: '\e60e';
$icon-error: '\e61f';
$icon-edit: '\e606';
$icon-print: '\e624';
$icon-star-empty: '\e625';
$icon-download: '\e626';
$icon-private: '\e629';
$icon-present: '\e62a';
$icon-gift-registry: '\e62b';

$icon-calendar__font-size: 45px;
$icon-wishlist-empty: inherit;
$icon-comment-reflected: inherit;
$icon-compare-empty: '\e61f';
$icon-arrow-up-thin: inherit;
$icon-arrow-right-thin: inherit;
$icon-arrow-left-thin: inherit;
$icon-arrow-down-thin: inherit;
$icon-wishlist-empty: '\e601';

$icon-facebook: '\e903';
$icon-googleplus: '\e900';
$icon-instagram: '\e904';
$icon-pinterest: '\e902';
$icon-twitter: '\e901';
$icon-collapse: '\e60f';
$icon-arrow-down: '\e614';
$icon-expand: '\e60f';
$icon-collapse: '\e61c';

$toggling-icon-width: 14px;
$toggling-icon-color: $secondary__color;

//
//  Messages
//  ---------------------------------------------
$success__color: $c-green;
$success__background-color: $c-light-green;
$warning__color: $c-brown;
$warning__background-color: $c-yellow;
$error__color: $c-red;
$error__background-color: $c-pink;

$message__padding: 12px $indent__base 12px $indent__m;
$message__line-height: inherit;
$message-icon__font-size: 28px;
$message-icon__inner-padding-left: 45px;
$message-icon__top: 22px;
$message__color: inherit;
$message-error-icon: $icon-error;
$message-success-icon: $icon-success;
$message-link__color-hover: $text__color;

$pdp-login-border__color: #efefef;
$pdp-login-background__color: #f6f6f6;

//
//  Loaders
//  ---------------------------------------------

$loader-overlay__background-color: rgba(#fff, 0.7);
$loader-overlay__z-index: 9999;

//
//  Popups
//  _____________________________________________

$popup-icon-font__size: 30px;
$popup-icon-font__line-height: 1;
$popup-icon-font__color: $link__color;
$popup-icon-font__color-hover: $link__hover__color;
$popup-icon-font__color-active: $link__active__color;

//
//  Forms
//  ---------------------------------------------

// Input text must be (at least) 16px to avoid the dreaded iOS zoom bug/feature

$form-element-input__border-color: $color-gray91;
$form-element-input__border-radius: 8px;
$form-element-input__height: 48px;
$form-element-input__padding: 13px 16px;

$form-element-input__font-size: 16px; // from lib-form-element-input mixin
$form-element-input__font-size--mobile: $form-element-input__font-size;
$select__font-size: $form-element-input__font-size; // from lib-form-element-input mixin
$textarea__font-size: $form-element-input__font-size; // from lib-form-element-input mixin
$form-element-input__border-color: $c-border;

$form-element-input__border: 1px solid $form-element-input__border-color;
$form-element-input__focus__border: $form-element-input__border;
$form-element-input__height: 51px;
$form-element-input-placeholder__color: $c-vignette;
$form-element-input__font-family: $font-family__secondary;
$form-element-input__color: $text__color;
$form-element-input__font-weight: $font-weight__regular;
$select__height: $form-element-input__height;

// Form fieldset
$form-fieldset__margin: 0;
$form-fieldset-legend__font-size: 18px;
$form-fieldset-legend__font-weight: $font-weight__light;
$form-fieldset-legend__margin: 0 0 $indent__base;
$form-fieldset-legend__padding: 0 0 $indent__s;
$form-fieldset-legend__width: 100%;

// Form field
$form-field-type-revert: inherit;
$form-field__additional-vertical-indent: $indent__s;
$form-field__width__desktop: 460px;
$form-field__height: 40px;
$form-field--vertical-indent: $indent__base;
$form-field__vertical-indent__desktop: $form-field--vertical-indent;

// Required field
$form-hasrequired__color: $c-red;
$form-field-label-asterisk__color: $c-red;
$form-hasrequired__font-size: 11px;
$form-field-label-asterisk__font-size: $form-hasrequired__font-size;
$form-hasrequired__font-family: $font-family__base;
$form-field-label-asterisk__font-family: $form-hasrequired__font-family;
// Form Validation
$form-validation-note__font-weight: $font-weight__bold;

// Form field label
$form-field-label__font-weight: $font-weight__regular;
$form-field-type-label-block__margin: 0 0 5px;
$form-field-type-label-inline__margin: $form-field-type-label-block__margin;
$form-field-type-label-inline__width: 1%;
$form-field-label__font-size: 14px;
$form-field-label__font-weight: normal;

// Form field note icon
$form-field-note-icon-font__content: inherit;
$form-field-note__color: inherit;
$form-calendar-icon__color: $primary__color;


// Select
$select__background: $color-white;
$select__background: $form-element-input__background;
$select__border: $form-element-input__border;
$select__border-radius: $form-element-input__border-radius;
$select__height: $form-element-input__height;
$select__width: $form-element-input__width;
$select__margin: $form-element-input__margin;
$select__padding: $indent__xs $indent__s 4px;
$select__font-size: $form-element-input__font-size;
$select__color: $form-element-input__color;
$select__font-family: $form-element-input__font-family;
$select__font-weight: $form-element-input__font-weight;
$select__font-style: $form-element-input__font-style;
$select__line-height: $form-element-input__line-height;


// Search dropdown

$autocomplete-item__hover__color: $c-berber;
//
// Accordion and Tabs
// ------------------------------

$tab-content__padding-top: 0;
$tab-content__padding-right: 10px;
$tab-content__padding-bottom: 10px;
$tab-content__padding-left: 10px;
$tab-content__border: 1px solid #dcdcdc;


$tab-control__padding-top: 0;
$tab-control__padding-left: 10px;
$tab-control__padding-bottom: 0;
$tab-control__border-width: 0;


$tab-control__color: $text__color;
$tab-control__height: 40px;
$tab-control__font-size: 14px;

//
//  Pager
//  ---------------------------------------------

//
//  Tables
//  ---------------------------------------------

$panel__background-color: $c-light-grey;


$pager-reset-spaces: true; // Reset spaces between inline-block elements
$pager-item__display: inline-block;

//  Rating
//  ---------------------------------------------

$rating-icon__font-size: 28px;
$rating-icon__letter-spacing: -9px;
$rating-icon__active__color: $active__color;

// Split dropdown
$dropdown-list__z-index: 100;

$_dropdown-toggle-icon-content: $icon-collapse;
$_dropdown-toggle-active-icon-content: $icon-expand;
$_dropdown-split-toggle-icon-content: $icon-collapse;
$_dropdown-split-toggle-active-icon-content: $icon-expand;

$_dropdown-split-list-item-hover-background: transparent;

// Actions-toolbar
$actions-toolbar__margin: 0;
$actions-toolbar__padding: 0;
$actions-toolbar-actions__position: left;
$actions-toolbar-actions__reverse: false;
$actions-toolbar-actions-primary__margin: 0 15px 0 0;

//
//  Modals
//  ---------------------------------------------

$modal-accent-color: #133127;
$modal-slide__first__indent-left: 44px;
$modal-slide-mobile__background-color: $flyout-active-color; // mobile nav flyout and modal are similar enough to warrant the same background
$modal-overlay__background-color: rgba($modal-accent-color, .7);
$modal-title__border: $c-border;

$modal__box-shadow: $flyout-box-shadow;


//
//  Popups
//  ---------------------------------------------
$modal-popup__padding: 0;
// Catalog
$tocart-min-width: 200px;

$width__product-related: 200px;
$width__product-info: 375px;

$checkout-summary-items__max-height: 100%;
$checkout-summary-mark-value__color: $c-medium-grey;
// Checkout tooltip
$checkout-tooltip-icon-arrow__font-size: 10px;
$checkout-tooltip-icon__font-size: 21px;

$checkout-tooltip-content-mobile__right: -($indent__s);
$checkout-tooltip-content-mobile__top: 30px + $checkout-tooltip-icon-arrow__font-size;
$checkout-tooltip-icon__color: $text__color;
$checkout-tooltip-content__background-color: $c-light-grey;
$checkout-tooltip-content__padding: 12px;
$checkout-tooltip-content__border-width: 1px;

$checkout-tooltip-content__border-color: $c-medium-grey;
$checkout-tooltip-content__font-size: $font-size__base;
$checkout-tooltip-content__width: 270px;
$checkout-tooltip-content__active__border-color: $c-dark-grey;
$checkout-tooltip-icon-arrow__left: -($checkout-tooltip-content__padding + $checkout-tooltip-icon-arrow__font-size - $checkout-tooltip-content__border-width);
$checkout-billing-address-details__line-height: 27px;
$checkout-billing-address-details__padding: 0 0 0 $indent__l;

// Checkout Sidebar Shipping Information
$checkout-sidebar-shipping-information__padding: 0;
$checkout-sidebar-shipping-information-edit-icon__content: $icon-edit;
$checkout-sidebar-shipping-information-edit-icon__font-size: 18px;
$checkout-sidebar-shipping-information-edit-icon__line-height: 20px;
$checkout-sidebar-shipping-information-edit-icon__top: $indent__s;

// Checkout
$checkout-step-title__padding: $indent__s;
$checkout-step-title__border: $border-width__base solid $c-border;
$checkout-step-title__font-size: 26px;
$checkout-step-title__font-weight: $font-weight__light;
$checkout-sidebar__columns: 8;
$checkout-shipping-address__max-width: 500px;

// Swatch
$swatch__selected-border-color: $color-black;
$swatch-color: $c-light-grey;

//
//  Progress Bar
//  _____________________________________________

$checkout-progress-bar__font-size: 14px;
$checkout-progress-bar__font-weight: $font-weight__light;
$checkout-progress-bar__margin: $indent__base;

$checkout-progress-bar-item__background-color: $c-medium-grey;
$checkout-progress-bar-item__border-radius: 6px;
$checkout-progress-bar-item__color: $primary__color;
$checkout-progress-bar-item__margin: $indent__s;
$checkout-progress-bar-item__width: 185px;
$checkout-progress-bar-item__active__background-color: $primary__color;
$checkout-progress-bar-item__complete__color: $link__color;

$checkout-progress-bar-item-element__width: 38px;
$checkout-progress-bar-item-element__height: $checkout-progress-bar-item-element__width;

$checkout-progress-bar-item-element-outer-radius__width: 6px;
$checkout-progress-bar-item-element-inner__background-color: $page__background-color;
$checkout-progress-bar-item-element-inner__color: $checkout-progress-bar-item__color;
$checkout-progress-bar-item-element-inner__width: $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2);
$checkout-progress-bar-item-element-inner__height: $checkout-progress-bar-item-element-inner__width;
$checkout-progress-bar-item-element-inner__active__content: $icon-checkmark;
$checkout-progress-bar-item-element-inner__active__font-size: 28px;
$checkout-progress-bar-item-element-inner__active__line-height: 1;

//
// Pages
//  ---------------------------------------------

$pages__color: $c-gray-600;
$pages__arrow__color: $c-gray-600;
$pages__active__color: $c-gray-300;
$pages__pagination__fontsize: 13px;

//
//  Sidebar
//  ---------------------------------------------

// Used in cart sidebar, Checkout sidebar, Tier Prices, My account navigation, Rating block background
$sidebar__background-color: $color-white;

//
//  Cart
//  --------------------------------------------

$mobile-cart-padding: 15px;
$cart-item-cell-padding-top: 20px;

$gift-wrapping__border-color: $c-border;
$gift-item-block__border-color: $c-border;

//
//  Variables missing in extend.scss
//  ---------------------------------------------

$addto-color: $link__color;
$addto-hover-color: $link__hover__color;

//
// Magento CMS Widgets
// ---------------------------------------------

$widgets-indent__bottom: 0; // overwrite !default in Magento_Cms/styles/_widgets.scss

//
//  Account pages
//  _____________________________________________

$account-nav-background: $sidebar__background-color;
$account-nav-color: false;

$account-nav-current-border: 3px solid transparent;
$account-nav-current-border-color: $primary__color;
$account-nav-current-color: false;
$account-nav-current-font-weight: $font-weight__semibold;

$account-nav-item-hover: $c-light-grey;

$account-title-border-color: $c-border;
$account-table-border-bottom-color: $c-border;

//
// Company Pages
// ______________________________________________

$company-button__color: $primary__color;
$company-button__color: $primary__color;
$company-button__hover__color: $primary__color__dark;
$tree-leaf-clicked__background-color: $primary__color;

$quote-button__color: $primary__color;
$quote-button__active__color: $link__hover__color;
$quote-title__border-color: $c-medium-grey;

//
// BlueFoot
// ______________________________________________

$bluefoot-banner__text-shadow: 0 0 85px $secondary__color;


// Custom Darkroom page
$custom-border-radius: 12px;
$custom-border-radius-sm: 8px; //4 column slick sliders
$arrow-fill-color: $c-white;
$arrow-background-color: $c-gray-600;
$header-icons-border-radius: 40px;
$header-icons-border-radius-mobile: 50%;
$header__background-color: $c-white;

$_breadcrumbs-separator-color: $c-gray-150;

