$button-tertiary__color: $primary__color !default;
$button-tertiary__hover__color: $secondary__color !default;

/*doc
---
title: Buttons
name: Buttons
category: Style-Guide - Buttons-Links
---

```html_example
  <button type="submit"><span>Buy Me</span></button>
  <p> AKA secondary button </p>
```

```html_example
  <button class="action primary" type="submit"><span>Buy Me</span></button>
```

```html_example
  <a  href="#""><span>Buy Me</span></a>
```

```html_example
  <a class="abs-action-link-button" href="#"><span>Buy Me</span></a>
```
*/

 // Secondary Button (main button styling)
button,
.button,
.pdp-cta-box__button-grey,
.pagebuilder-button-secondary,
.file-upload {
    @include lib-button();
    appearance: none;
    text-transform: uppercase;
    font-size: $button__font-size;
}

.pdp-cta-box__button-grey {
    @include hover {
        color: $c-houndstooth;
        border: 1px solid $c-gray-300;
        background-color: $c-gray-200;
    }
}

.pagebuilder-button-secondary {
    background-color: $c-white;
    text-align: center;
    @include hover{
        color: $c-houndstooth;
        background-color: $c-black-15;
    }
}
.pagebuilder-button-long {
    @include pagebuilder-button-long;
}
.filter-options-title,
.swatch-option-link-layered,
.slick-arrow,
.image-search,
.search,
.action-close {
    @include lib-button-reset();
}

a.action.secondary,
.cms-page-view .page-main a.pagebuilder-button-secondary {
    color: $c-houndstooth;
    text-decoration: none;    
}

// Primary Button (mostly CTA)
.pdp-cta-box__button-primary,
.pagebuilder-button-primary,
.action.primary {
    @include lib-button-primary();
    @include hover{
        background-color: $c-light-brown;
        border-color: $c-light-brown;
    }
}

// Treat these anchor links as buttons
a.action.primary {
    @include lib-link-as-button();
    @include headingFont;
}

.action.tertiary,
a.action.tertiary {
  @include button--tertiary();
}

button,
.action.primary,
.pagebuilder-button-primary,
a.action.primary {
    width: 100%;
    font-size: $button__font-size;
    line-height: $button__line-height;
    letter-spacing: $button__letter-spacing;
    transition: background-color .15s ease, color .15s ease;
    text-decoration: none;

    &:visited {
        color: $button-primary__color;
    }

    @include min-screen($screen__xs) {
        width: auto;
    }
}

.button--transparent {
    @include button--transparent;
}

.button--inline-link {
    @include button--inline-link;
}
