.block-category-link,
.block-product-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}
.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}
.block.widget {
    .product-item-info {
        width: auto;
    }
    .pager {
        padding: 0;
        .toolbar-amount {
            float: none;
            font-size: 12px;
        }
        .pages-item-previous {
            padding-left: 0;
        }
        .pages-item-next {
            position: relative;
        }
        .items {
            white-space: nowrap;
        }
    }

    &.block-viewed-products-grid {
        .block-title strong {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: $font-weight__bold;
            color: $c-vignette;
            line-height: 1.425;
            letter-spacing: 1.9px;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include min-screen($screen__m) {
                font-size: 16px;
            }
        }

        .products-grid .product-items {
            display: flex;
            flex-direction: column;
        }

        .products-grid .product-item {
            width: 100%;
        }

        .product-item {
            margin-bottom: 0;
            padding-top: $indent__l;
            padding-bottom: $indent__l;
            border-bottom: 1px solid $c-border;
        }

        .product-item-info {
            display: flex;
        }

        .product-item-photo {
            position: static;
        }

        .product-item-details {
            margin-top: 0;
            margin-left: 15px;
            
            .price-label {
                display: none;
            }

            .special-price {
                margin-left: 0;

                .price-wrapper .price {
                    color: $c-red;
                }
            }

            .old-price .price-including-tax {
                display: inline-block;
            }
        }

        .product-item-description,
        .product-item-actions {
            display: none;
        }

        .product-item-name {
            margin-top: 0;
            margin-bottom: $indent__s;
        }
    }
}

.recently-viewed-product--wrapper {
    order: 2;
    width: 100%;

    .price-box {
        display: flex;

        .special-price,
        .old-price {
            .price-label {
                display: none;
            }
        }

        .special-price .price {
            color: $c-red;
            margin-left: $indent__xs;
        }
    }

    &.desktop {
        display: none;
    }
}

//
//    Mobile
//  _____________________________________________

@include min-screen($screen__s) {
    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 33.3333%;
    }
}
//
//    Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.widget .products-grid .product-item {
        width: 33.3333%;
    }
    .page-layout-1column .block.widget .products-grid .product-item {
        width: 25%;
    }
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 50%;
    }

    .block.widget.block-viewed-products-grid .product-item-photo {
        margin: 0;
    }

    .recently-viewed-product--wrapper {
        display: none;
        
        &.desktop {
            display: block;
            float: left;
            clear: left;
            width: 234px; // accounts for sidebar width sans sidebar padding
        }
    }
}

@include min-screen($screen__l) {
    .block.widget .products-grid .product-item {
        width: 20%;
    }
    .page-layout-1column .block.widget .products-grid .product-item {
        width: 16.6666%;
        margin-left: calc((100% - 5 * (100%/6)) / 4);
        &:nth-child(4n+1) {
            margin-left: calc((100% - 5 * (100%/6)) / 4);
        }
        &:nth-child(5n+1) {
            margin-left: 0;
        }
    }
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 25%;
    }
    .block.widget .products-grid .product-items {
        margin: 0;
    }
    .block.widget .products-grid .product-item {
        width: 100%;
        padding: 0;

        &:nth-child(4n+1) {
            margin-left: 0;
        }
    }

    .block.widget.block-viewed-products-grid .products-grid .product-item {
        padding-top: $indent__l;
        padding-bottom: $indent__l;
    }
}

@include min-screen($screen__m) {
    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            display: none;
        }

        .pages-item-next {
            padding: 0;

            .action {
                margin: 0;
            }
        }
    }
}


// Upsells
.block.upsell .block-title h3 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: $font-weight__bold;
    color: $c-vignette;
    line-height: 1.425;
    letter-spacing: 1.9px;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include min-screen($screen__m) {
        font-size: 16px;
    }
}
