.giftcard-account {
    .please-wait {
        display: none;
    }

    .form-giftcard-redeem {
        @include min-screen($screen__s) {
            display: flex;
            flex-wrap: wrap;
        }

        .giftcard.info {
            margin-bottom: $indent__l;
        }

        .fieldset {
            margin-bottom: 10px;

            @include min-screen($screen__xs) {
                flex-grow: 1;
                max-width: 400px;
            }
        }

        .actions-toolbar {
            margin-bottom: 10px;

            @include min-screen($screen__xs) {
                float: left;
            }

            @include min-screen($screen__s) {
                margin-top: 32px;
            }

            &:last-of-type {
                @include min-screen($screen__xs) {
                    float: none;
                    clear: none;
                    margin-top: 0;
                }

                @include min-screen($screen__s) {
                    flex: 0 0 100%;
                }

                @include min-screen($screen__m) {
                    flex: 0 0 100%;
                }
            }
        }

        .balance {
            width: 100%;
        }
    }
}

.opc-wrapper {
    .form-giftcard-account {
        max-width: 500px;

        .field {
            margin: 0 0 $indent__base;

            .label {
                margin-top: 23px;
            }
        }

        .action-check {
            width: auto;
        }

        .secondary {
            @include min-screen($screen__m) {
                display: block;
                position: absolute;
                top: 85px;
                left: 0;
            }
        }
    }

    .secondary {
        margin-top: $indent__s;

        @include min-screen($screen__s) {
            width: 100%;
        }

        button {
            @include button--transparent;
            @include button--tertiary();
            width: auto;
        }
    }

    .giftcard-account-info {
        margin: 0 0 $indent__base;

        .giftcard-account-number {
            margin: 0 0 $indent__s;
        }
    }
}
