//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color;
$account-nav-color: false;

$account-nav-current-border: 3px solid transparent;
$account-nav-current-border-color: $color-orange-red1;
$account-nav-current-color: false;
$account-nav-current-font-weight: $font-weight__semibold;

$account-nav-item-hover: $color-gray91;

$_password-default: $c-berber !default;
$_password-weak: #ffafae !default;
$_password-medium: #ffd6b3 !default;
$_password-strong: #c5eeac !default;
$_password-very-strong: #81b562 !default;

//
//  Common
//  _____________________________________________

.login-container {
    .block {
        &-new-customer {
            .actions-toolbar {
                margin-top: 25px;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            font-size: 18px;
        }
    }

    .fieldset {
        @include lib-form-hasrequired(top);

        &:before {
            @include min-screen($screen__m) {
                max-width: $form-field__width__desktop;
            }
        }

        &:after {
            margin-top: 35px;
        }

        .actions-toolbar {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .primary {
                width: 100%;
            }

            @include min-screen($screen__xs) {
                flex-direction: row;
                align-items: center;

                .primary {
                    width: auto;
                }

                .action.remind {
                    margin-top: 0;
                    margin-left: $indent__s;
                }
            }
        }
    }
}

.forget {
    .fieldset:before {
        margin-bottom: 10px;

        @include min-screen($screen__s) {
            max-width: $form-field__width__desktop;
        }
    }
}

.block-addresses-list {
    .items.addresses {
        & > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .item.actions {
            margin-top: $indent__xs;

            .action {
                @extend .abs-account-actions;
            }
        }
    }
}

.form-address-edit {
    .region select {
        display: none;
    }

    .actions-toolbar .action.primary {
        @include lib-button-l();
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
        clear: both;
        float: none;

        @include min-screen($screen__m) {
            clear: none;
            width: 47%;
        }
    }
}

.account-create-instructions {
    max-width: 465px;
}

.form-create-account fieldset {
    max-width: $form-field__width__desktop;
}

// override [type="checkbox"] label styling in styles/blocks/_custom-input.scss, 
// needed to prevent extra visible checkbox showing up under recaptcha form 
.field .control [type="checkbox"].required-captcha.checkbox + label {
    display: none !important;
}

.form.form-login .actions-toolbar {
    margin-top: $indent__base;
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 26px;
    }
}

.box-address-shipping {
    @include max-screen($screen__m) {
        margin-top: 20px;
    }
}

//
//  My account
//  ---------------------------------------------

.account {
    &.sales-order-view {
        .block-requisition-list {
            display: none;
        }
    }
    .column.main {
        @include min-screen($screen__m) {
            width: 66.66666667%
        }

        @include min-screen($screen__l) {
            width: 76.66666667%
        }

        h2 {
            margin-top: 0;
        }

        .toolbar {
            text-align: center;
            .limiter-options {
                width: auto;
            }
        }

        .order-title {
            display: flex;
            flex-wrap: wrap;

            h3 {
                flex-basis: 100%;

                @include min-screen($screen__l) {
                    flex-basis: initial;
                    margin-right: $indent__s;
                }
            }

            a {
                margin-right: $indent__s;

                @include min-screen($screen__l) {
                    margin-top: $indent__base;
                }
            }
        }

        .order-options-links {
            margin-bottom: $indent__m;

            a {
                display: block;
                margin-bottom: $indent__xs;

                @include min-screen($screen__xs) {
                   display: inline-block;
                    margin-right: $indent__l;
                    margin-bottom: 0;
                }
            }
        }

        .limiter {
            >.label {
                @include lib-visually-hidden();
            }
        }

        .block:not(.widget) {
            @extend %abs-account-blocks;
        }
    }

    .sidebar {
        @include min-screen($screen__m) {
            width: 33.3333333%;
        }

        @include min-screen($screen__l) {
           width: 23.3333333%;
        }

       &-additional {
            margin-top: 40px;
       }
    }

    .block-title + .block-content > .table-wrapper {
        margin-top: -25px;
    }

    .block-title strong {
        @include bryant-park-headings-h4;
        color: $c-vignette;
        text-transform: uppercase;
    }

    .block-content .box .box-title {
        margin-top: 15px;

        h4 {
            @include bryant-park-headings-h5;
        }
    }

    .table-wrapper {
        margin-bottom : $indent__base;

        &:last-child {
            margin-bottom: 0;
        }

        .action:last-child {
            margin-right: 0;
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }

    .legend {
        @extend %abs-account-title;
        width: 100%;
    }

    .actions-toolbar {
        clear: left;
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @include lib-visually-hidden();
        }
    }
}

// Extra Order Search 


.extra-order-search {
    @include min-screen($screen__l) {
        display: flex;
        align-items: flex-start;
    }

    .actions-toolbar {

        @include max-screen($screen__l) {
            .primary { 
                width: 100%;
            }
        }

        .action.primary {
            padding: 8px;
            min-height: $form-field__height;
            font-size: 10px;

            @include max-screen($screen__l) {
                width: 100%;
            }
        }
    }
}

.extra-order-search-box1 {
    display: flex;
    flex: 1;
    margin-right: 8px;

    @include screen($screen__l, $screen__l2) {
        flex: none;
    }

    .field {
        display: flex;
        justify-content: space-around;
        flex-basis: 100%;
        margin-right: 8px;

        .control {

            @include max-screen($screen__l) {
                width: 100%;
                margin-right: 8px;
            }

            @include screen($screen__l, $screen__l2) {
                input[type="text"] {
                    width: 100px;
                }
            }

            input[type=text] {
                font-size: 11px;
                height: $form-field__height;
            }

            .order-statuses {
                height: $form-field__height;
                font-size: 11px;
            }
        }
    }

}

.extra-order-search-box-2 {
    display: flex;
    flex: 1;
    justify-content: space-around;

    @include screen($screen__l, $screen__l2) {
        justify-content: normal;
    }

    @include max-screen($screen__l) {
        display: block;
        margin: 12px 0;
    }

    .field-date {
        display: inline-block;

        .control {
            display: flex;
            align-items: center;

            @include max-screen($screen__l) {
                max-width: 100%;
            }

            .sub-label {
                font-size: 11px;
                margin-right: 4px;
            }

            .ui-datepicker-trigger:before {
                font-size: 32px;
                line-height: 34px;
            }
        }
    }

    input[type="text"] {
        font-size: 11px;
        height: $form-field__height;
    }
}


//
//  Account navigation
//  ---------------------------------------------

.account-nav {
    .title {
        @include lib-visually-hidden();
    }

    .content {
        background: $account-nav-background;
        padding: 15px 0;
    }

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        & > strong {
            color: $account-nav-color;
            border-left: 3px solid transparent;
            display: block;
            padding: $indent__xs 18px $indent__xs 15px;
        }

        a {
            text-decoration: none;

            @include hover {
                background: $account-nav-item-hover;
            }
        }

        &.current {
            a,
            strong {
                border-color: $account-nav-current-border-color;
                color: $account-nav-current-color;
                font-weight: $account-nav-current-font-weight;
            }

            a {
                border-color: $account-nav-current-border-color;
            }
        }
    }
}


//
//  Table Actions
//  ---------------------------------------------

.account,
[class^='sales-guest-'],
.sales-guest-view {
    .data.table {
        .col.actions {
            .action {
                @extend .abs-account-actions;
            }
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;

    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__s;
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: $indent__xs;

        .action {
            @extend .abs-account-actions;
        }
    }

    .box {
        &-newsletter,
        &-shipping-address {
            @include max-screen($screen__m) {
                margin-top: 20px;
            }
        }
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        display: flex;
        flex-direction: column;

        .mage-error {
            order: 2;
        }

        .input-text {
            order: 0;
        }

        &:last-child {
            @include min-screen($screen__s) {
                max-width: $form-field__width__desktop;
            }
        }
    }
}

.password-strength-meter {
    position: relative;
    padding: $form-element-input__padding;
    min-height: $form-element-input__height;
    line-height: $form-element-input__height;
    z-index: 1;
    background-color: $_password-default;
    font-size: $font-size__base;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    span {
        display: inline-block;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control.captcha-image {
    margin-top: $indent__s;

    .captcha-img {
        vertical-align: middle;
    }
}

.block-reviews-dashboard .item .rating-summary  {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }
}

@include max-screen($screen__m) {
    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
    }

    .account {
        .page.messages .message {
            margin-bottom: $indent__s; // same amount as .block .title, which resides above the messages display
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
    }

    .control.captcha-image {
        .captcha-img {
            margin-bottom: $indent__s;
            display: block;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;
            &.login {
                .actions-toolbar {
                    & > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    & > .secondary {
                        float: left;
                    }
                }
            }

            &.block-customer-redirect {
                float: right;
                clear: right;
            }
        }

        .fieldset {
            &:after {
                @extend .abs-margin-for-forms-desktop;
            }
        }
    }

    .form-create-account {
        .fieldset-fullname {
            .fields {
                .field {
                    float: left;
                    margin: 0 $indent__s $indent__s 0;
                }
            }

            .field-name-prefix,
            .field-name-suffix {
                width: 50px;
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    //
    //  My account
    //  ---------------------------------------------

    .account {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                    }
                }
            }
        }

        .data.table {
            margin-bottom: 0;

            .col {
                &.actions {
                    white-space: nowrap;
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .form-edit-account {
        .fieldset {
            @extend .abs-blocks-2columns;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;

            & > .item {
                @extend .abs-blocks-2columns;
                margin-bottom: $indent__base;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}
