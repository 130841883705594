.cart-summary .block.giftcard .secondary {
    .action.check {
        @include button--inline-link();
        text-align: left;
        white-space: normal;
        font-size: $font-size__base;
        font-weight: $font-weight__base;
        line-height: $line-height__base;
        letter-spacing: $letter-spacing__base;
        color: $c-feng-shui;

        @include min-screen($screen__m) {
            font-size: $font-size__base--desktop;
            line-height: $line-height__base--desktop;
            letter-spacing: $letter-spacing__base--desktop;
        }
    }
}

.page-product-giftcard {
    .giftcard-amount {
        text-align: left;
    }

    .product-options-wrapper .field:first-of-type {
        margin-top: $form-field__vertical-indent;
    }

    .fieldset.giftcard {
        & > .legend {
            @include lib-visually-hidden();
            & + br {
                display: none;
            }
        }
    }
}
