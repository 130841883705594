// ----------------------------
// Bryant Park heading mixins
// ----------------------------
@mixin bryant-park-headings-h1 {
    @include lib-heading(h1);
    letter-spacing: .9px;

    @include min-screen($screen__m) {
        letter-spacing: 1px;
    }
}

@mixin bryant-park-headings-h2 {
    @include lib-heading(h2);
    letter-spacing: .7px;

    @include min-screen($screen__m) {
        letter-spacing: .9px;
    }
}

@mixin bryant-park-headings-h3 {
    @include lib-heading(h3);
    letter-spacing: .6px;

    @include min-screen($screen__m) {
        line-height: $h3__line-height-desktop;
    }
}

@mixin bryant-park-headings-h4 {
    @include lib-heading(h4);
    letter-spacing: 1.9px;

    @include min-screen($screen__m) {
        letter-spacing: 2.4px;
    }
}

@mixin bryant-park-headings-h5 {
    @include lib-heading(h5);
    letter-spacing: .4px;

    @include min-screen($screen__m) {
        letter-spacing: .5px;
    }
}

@mixin bryant-park-headings-h6 {
    @include lib-heading(h6);
    letter-spacing: 1.5px;

    @include min-screen($screen__m) {
        letter-spacing: 1.5px;
    }
}

@mixin bryant-park-typography-all() {
    @include lib-typography__base();
    @include lib-typography-links();
    @include lib-typography-lists();
    @include lib-typography-code();
    @include lib-typography-blockquote();
}

@mixin darkroom-headings-h1 {
    font-size: $headings__font-size-small;

    @include min-screen($screen__m) {
        font-size: $h2__font-size-desktop;
    }
}

@mixin darkroom-headings-h2 {
    @include darkroom-headings-h1;
}

@mixin darkroom-headings-h2-secondary {
    font-size: $headings__font-size-small;
    font-family: $heading__font-family__base;
    font-weight: $font-weight__bold;
    font-weight: normal;
}

@mixin darkroom-headings-h3 {
    font-family: $font-family__secondary;
    font-size: $h3__font-size-desktop;
}

@mixin darkroom-eyebrow {
    font-size: $font-size__base--l;
    text-transform: uppercase;
    color: $c-light-brown;
    margin: 15px 0;
    letter-spacing: 5%;
    font-family: $font-family__secondary;
    font-weight: $font-weight__bold;
}

@mixin darkroom-heading-sentence($color) {
    color: $color;
    font-family: $font-family__base;
    font-weight: $font-weight__light;
    font-size: $headings__font-size-small;

    @include min-screen($screen__m) {
        font-size: $h2__font-size-desktop;
    }

    @include max-screen($screen__m) {
        font-size: $h3__font-size-desktop;
    }
}

@mixin darkroom-headings-h4 {
    font-family: $font-family__secondary;
}

@mixin darkroom-headings-h5 {
    font-family: $font-family__secondary;
}

@mixin darkroom-headings-h6 {
    font-family: $font-family__secondary;
}