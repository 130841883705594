.image-over-content {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .circle-image img {
        border-radius: 50%;
    }

    img {
        width: 150px;
        height: 150px;
    }

    .title {
        text-align: center;
    }

    .image-over-content__hyperlink a {
        color: $primary__color;
        font-size: 18px;
        font-family: $font-family__secondary;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: .2px;
        text-decoration: none;

        @include hover {
            color: $secondary__color;
        }

        &::after {
            content: " \2192";
        }
    }
}

.circular-tout__wrapper h2,
.banner--offset h2,
.media-tout h2,
.bluefoot-align-center span {
    font-size: 22px;

    @include min-screen($screen__l) {
        font-size: $h2__font-size-desktop;
    }
}
