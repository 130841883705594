#zrl_embed_div {
  height: 850px;
  width: 800px;
}

@media only screen and (max-width: 768px) {
    #zrl_embed_div {
        height: 625px;
        width: 370px;
    }
}
