.contact.form {
    max-width: $form-field__width__desktop;
}

.contact-index-index {
    .contact-description {
        max-width: 850px;
    }

    .legend,
    .contact.form {
        margin-top: $indent__xl;
    }

    .input-text {
        border: 1px solid $c-border;
    }
}
